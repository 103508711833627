import { Box, Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import FormPlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Formulario/formPlanoAplicacao';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { serviceGet, servicePatch } from 'src/services';
import CustomModal from 'src/components/Modal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { tratandoDataInput } from 'src/config/utils';
import { IPlanoAplicacaoData, schemaPlanoAplicacao } from '../Formulario/type';

export default function AtualizarPlano({
  open,
  handleClose,
  id,
  RefetchPlano,
}: {
  open: boolean;
  handleClose: () => void;
  id: string;
  RefetchPlano: (arg: boolean) => void;
}) {
  const { state } = useContext(AuthContext);
  const include = ['eixoFinanciado'];
  const params = { include };

  const { data: rows, isPending } = useQuery({
    queryKey: ['getPlanoAplicacao', id, include],
    queryFn: () => serviceGet('planoAplicacaoId', { include }, id),
  });
  const methods = useForm({
    resolver: yupResolver(schemaPlanoAplicacao),
    defaultValues: rows,
  });
  // SALVAR ATUALIZAÇÃO - RAQUISIÇÃO
  const { mutate } = useMutation({
    mutationFn: (data: IPlanoAplicacaoData) => servicePatch('planoAplicacaoId', { ...data }, id),
    onSuccess: (data) => {
      ToastFun('Plano Atualizado com sucesso', 'success');
      RefetchPlano(true);
      handleClose();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  useEffect(() => {
    if (rows) {
      rows.prazoExecucao = tratandoDataInput(rows.prazoExecucao);
      rows.eixosFinanciados.forEach((item: any) => {
        item.valorInicialCusteio /= 100;
        item.valorInicialInvestimento /= 100;
      });
      methods.reset(rows);
    }
  }, [methods, rows]);

  const { watch, setValue, unregister } = methods;

  // ENVIADO DADOS PARA A
  const onSubmit = async (data: IPlanoAplicacaoData) => {
    data.usuarioUpdateId = state.usuario?.id;
    data.atualizadoEm = new Date();

    mutate(data);
  };

  return (
    <>
      {rows && (
        <CustomModal
          open={open}
          onClose={handleClose}
          title="Atualizar - Plano de Aplicação"
          icon={<EditNoteIcon color="warning" sx={{ fontSize: 30 }} />}
        >
          <Grid md={12} xs={12} sx={{ my: 1 }}>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <FormPlanoAplicacao unregister={unregister} setValue={setValue} />
            </FormsProvider>
          </Grid>
        </CustomModal>
      )}
    </>
  );
}
