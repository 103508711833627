import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { getGrupoPermissoes } from 'src/services/Admin/get';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import CadastrarGrupo from './Cadastro';
import AtualizarGrupo from './Atualizar';

export default function Permissoes() {
  const [open, setOpen] = useState(false);
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  // BUSCAR DADOS DOS GRUPOS
  const [idGP, setIdGP] = useState<string>('');
  const [typeAction, setType] = useState<string>('');

  const handleOpen = (type: 'Criar' | 'Editar', id?: string) => {
    if (id) {
      setIdGP(id);
    }
    setType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setType('');
    setIdGP('');
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'NOME',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'right' }}>
          <IconButton color="warning" onClick={() => handleOpen('Editar', params.row.id)}>
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Usuários"
          links={[
            { name: 'Administrador', href: '/administrador' },
            { name: 'Permissões', href: '/administrador/usuarios' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid xs={12} md={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ mt: -5 }}
              onClick={() => handleOpen('Criar')}
            >
              Grupo
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Grupo de Permissões">
              <TableContainer
                columns={columns}
                endpoint="grupoPermissoes"
                setRefetch={tableRefetch}
                params={{}}
                setTableRefetch={setTableRefetch}
              />
            </CustomContainerList>
          </Grid>
        </Grid>
        {typeAction === 'Criar' && (
          <CadastrarGrupo open={open} handleClose={handleClose} refetchGrupo={setTableRefetch} />
        )}
        {typeAction === 'Editar' && idGP && (
          <AtualizarGrupo
            open={open}
            handleClose={handleClose}
            refetchGrupo={setTableRefetch}
            id={idGP}
          />
        )}
      </Stack>
    </Box>
  );
}
