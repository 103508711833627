import { convertToCentavos } from 'src/config/utils';
import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import * as yup from 'yup';
import { IMetaFisicaData, IMetaInstituicaoData } from '../../../Metas/Formulario/types';
import {
  IEixosFinanciadosData,
  IPlanoAplicacaoData,
} from '../../../PlanoAplicacao/Formulario/type';

export interface IRemanejamentoData {
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  natureza: string;
  metaFisicaRetiradaId: string;
  metaFisicaRecebidaId: string;
  instituicaoRetiradaId: string;
  instituicaoRecebidaId: string;
  processoSei: string;
  valorRemanejado: number;
  criadoEm: string;
}
export interface IRemanejamentoDataResponse extends IRemanejamentoData {
  status?: string;
  acao?: string;
  metaFisicaAcao?: string;
  planoAplicacao?: IPlanoAplicacaoData;
  eixoFinanciado?: IEixosFinanciadosData;
  metaFisicaRetirada?: IMetaFisicaData;
  metaFisicaRecebida?: IMetaFisicaData;
  metaInstituicaoRetirada?: IMetaInstituicaoData & { sigla?: string };
  metaInstituicaoRecebida?: IMetaInstituicaoData & { sigla?: string };
  usuarioCreate?: IUsuarioData;
  usuarioAuthorization?: IUsuarioData;
}
export const schemaRemanejamento = yup.object({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  natureza: yup.string().required('Natureza é Obrigatório'),
  metaFisicaRetiradaId: yup.string().required('é Obrigatório'),
  metaFisicaRecebidaId: yup.string().required('é Obrigatório'),
  instituicaoRetiradaId: yup.string().required('é Obrigatório'),
  instituicaoRecebidaId: yup.string().required('é Obrigatório'),
  processoSei: yup
    .string()
    .required('é Obrigatório')
    .test('maxLength', 'Número SEI inválido', (value) => value.length === 25)
    .test('validYear', 'Número SEI inválido', (value) => {
      const year = parseInt(value.split('/')[1]?.split('-')[0], 10);
      const currentYear = new Date().getFullYear();
      return year > 2015 && year <= currentYear;
    }),
  valorRemanejado: yup
    .number()
    .positive('Valor não pode ser negativo')
    .min(0, 'Valor não pode ser negativo')
    .required('é Obrigatório')
    .transform((value: any) => (value ? convertToCentavos(value) : 0)),
});
