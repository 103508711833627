import { useState } from 'react';
import { Box, Grid, IconButton, Chip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuery } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData } from 'src/config/utils';
import { useNavigate } from 'react-router-dom';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';

export default function MetasGeraisTabelaAutorizacao() {
  const navigate = useNavigate();

  const [id, setId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('Pendente');
  const [checked, setChecked] = useState<boolean>(false);
  const include = ['metaFisicaCount', 'user', 'exercicioOrcamentario'];
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const dataParams = {
    filters: { search },
    include,
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'PLANO DE APLICAÇÃO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'nome',
      headerName: 'META GERAL',
      flex: 4,
      minWidth: 250,
    },
    {
      field: '_count',
      headerName: 'METAS FÍSICAS',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row.dadosMetasFisicasCount}</>,
    },

    {
      field: 'criadoEm',
      headerName: 'CADASTRADO EM',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioCreate',
      headerName: 'CADASTRADO POR',
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.usuarioCreate.nome,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="primary" onClick={() => navigate(`meta/${params.row.id}`)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto', mt: 1 }}>
      <TableContainer
        columns={columns}
        endpoint="metaGeral"
        setRefetch={tableRefetch}
        params={dataParams}
        setTableRefetch={setTableRefetch}
      />
    </Box>
  );
}
