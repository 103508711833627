import { Alert, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { serviceGet } from 'src/services';
import { ApiEmpenho } from 'src/services/Api';
import { UseFormReturn } from 'react-hook-form';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import { tratandoData, valorMonetario } from 'src/config/utils';
import { DadosVinculadosEmpenho, IEmpenhoDataAPI } from './type';

export default function BuscarApiEmpenho({
  methods,
  fetch,
  setFetch,
  setEmp,
}: {
  methods: UseFormReturn<DadosVinculadosEmpenho>;
  fetch: boolean;
  setFetch: (arg: boolean) => void;
  setEmp: (arg: IEmpenhoDataAPI) => void;
}) {
  const numeroEmpenho = methods.watch(`numeroEmpenho`);
  const queryClient = useQueryClient();
  const tabelaRef = useRef<HTMLDivElement>(null);

  const { data: rows, error } = useQuery({
    queryKey: ['getEmpenhos', numeroEmpenho],
    queryFn: () => serviceGet('empenhoApi', {}, numeroEmpenho, ApiEmpenho),
    enabled: !!fetch,
    retry: false,
  });

  useEffect(() => {
    if (rows) {
      setEmp(rows);
      tabelaRef.current?.scrollIntoView({ behavior: 'smooth' });
      setFetch(false);
    }
    if (!rows && fetch) {
      setFetch(false);
    }
  }, [fetch, queryClient, rows, setEmp, setFetch]);
  useEffect(() => {
    if (rows) {
      methods.setValue(`valorEmpenhado`, rows.valorEmpenhado);
      methods.setValue(`valorAnulado`, rows.totalAnulado);
      methods.setValue(`valorPago`, rows.totalPago);
      methods.setValue(`valorComplemento`, rows.totalComplemento);
      methods.setValue(`dataEmpenho`, rows.dataEmpenho);
      methods.setValue(`anoEmpenho`, rows.anoEmpenho);
    } else {
      methods.unregister(`valorEmpenhado`);
      methods.unregister(`valorAnulado`);
      methods.unregister(`valorPago`);
      methods.unregister(`valorComplemento`);
      methods.unregister(`dataEmpenho`);
      methods.unregister(`anoEmpenho`);
    }
  }, [methods, rows]);

  return (
    <Grid item md={12} ref={tabelaRef}>
      {rows && numeroEmpenho && (
        <Table sx={{ mt: 5, border: '2px solid #D0E7FF' }}>
          <TableHead>
            <TableRow>
              <TableCellHead
                sx={{
                  background: '#D0E7FF',
                  color: '#084d6e',
                  fontWeight: '800',
                  textAlign: 'center',
                }}
                colSpan={12}
              >
                Empenho
              </TableCellHead>
            </TableRow>
          </TableHead>
          <TableHead sx={{ background: '#FAFAFA' }}>
            <TableRow>
              <TableCellHead align="center">Número Empenho</TableCellHead>
              <TableCellHead align="center">Valor Empenhado</TableCellHead>
              <TableCellHead align="center">Valor Pago</TableCellHead>
              <TableCellHead align="center">Valor Anulado</TableCellHead>
              <TableCellHead align="center">Valor Complementar</TableCellHead>
              <TableCellHead align="center">Data do Empenho</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellBody align="center">{numeroEmpenho}</TableCellBody>
              <TableCellBody align="center">{valorMonetario(rows.valorEmpenhado)}</TableCellBody>
              <TableCellBody align="center"> {valorMonetario(rows.totalPago)}</TableCellBody>
              <TableCellBody align="center"> {valorMonetario(rows.totalAnulado)}</TableCellBody>
              <TableCellBody align="center">{valorMonetario(rows.totalComplemento)}</TableCellBody>
              <TableCellBody align="center">{tratandoData(rows.dataEmpenho)}</TableCellBody>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {error && (
        <Grid md={12} sx={{ px: 1 }}>
          <Alert severity="error">Empenho não encontrado</Alert>
        </Grid>
      )}
    </Grid>
  );
}
