import * as yup from 'yup';
import { IMetaFisicaData, IMetaInstituicaoData } from '../../Formulario/types';
import {
  IEixosFinanciadosData,
  IPlanoAplicacaoData,
} from '../../../PlanoAplicacao/Formulario/type';

export interface IMetaGeralData {
  id?: string;
  nome: string;
  status: string;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  metaGeralId: string;
  usuarioCreateId: string;
  usuarioUpdateId: string;
  atualizadoEm?: Date;
  usuarioAuthorizationId: string;
  metasFisicas?: IMetaFisicaData[];
}
export interface IMetaGeralIdData extends IMetaGeralData {
  eixoFinanciado?: IEixosFinanciadosData & { eixoNome?: string };
  planoAplicacao?: IPlanoAplicacaoData;
  usuarioAuthorization?: { nome: string };

  metasFisicas?: Array<
    IMetaFisicaData & {
      nomeDespesa?: string;
      metasInstituicoes?: Array<
        IMetaInstituicaoData & { nome?: string; sigla?: string; unidade?: string }
      >;
    }
  >;
}
export const schemaMetaGeral = yup.object({
  nome: yup.string().required('Meta Geral é Obrigatório'),
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  atualizadoEm: yup.date().optional(),
});
export const defaultValuesMetaGeral = {
  nome: '',
  planoAplicacaoId: '',
  eixoFinanciadoId: '',
  usuarioUpdateId: '',
  atualizadoEm: new Date(),
};
