import { useState } from 'react';
import { Box, Grid, Stack, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData } from 'src/config/utils';
import { getUnidades } from 'src/services/Admin/get';
import CadastrarUnidade from './Cadastro';

export default function Unidades() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  // BUSCAR DADOS DA UNIDADE
  const [idUser, setIdUser] = useState<string>('');
  const [modalType, setmodalType] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = (id: any) => {
    setIdUser(id);
    setIsModalOpen(true);
  };

  const { data: rows, refetch } = useQuery({
    queryKey: ['getUnidades', skip, take],
    queryFn: getUnidades,
  });

  const handleClose = () => {
    setIsModalOpen(false);
    refetch();
  };
  const handleButtonClick = (idPlano: string, type: string) => {
    setIsModalOpen(true);
    setmodalType(type);
  };
  const columns: GridColDef[] = [
    {
      field: 'instituicao',
      headerName: 'Instituição',
      flex: 1,
      renderCell: (param) => <>{param.row.instituicao.nome}</>,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
    },
    {
      field: 'sigla',
      headerName: 'Sigla',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'criadoEm',
      headerName: 'Criando Em',
      flex: 1,
      renderCell: (param) => <>{tratandoData(param.row.criadoEm)}</>,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Unidades"
          links={[
            { name: 'Administrador', href: '/administrador' },
            { name: 'Unidades', href: '/administrador/unidades' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid xs={12} md={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick('', 'Cadastro')}
              color="primary"
              startIcon={<AddIcon />}
            >
              Nova Unidade
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            {/* <CustomContainerList title="Unidades">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList> */}
          </Grid>
        </Grid>
        {isModalOpen && modalType === 'Cadastro' && (
          <CadastrarUnidade open={isModalOpen} handleClose={handleClose} />
        )}
      </Stack>
    </Box>
  );
}
