import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { convertToCentavosCentavos } from 'src/config/utils';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import SearchIcon from '@mui/icons-material/Search';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { CardValue } from 'src/components/CustomCard';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import ModalConfirmar from 'src/components/Modal/Confirmar';
import { DadosVinculadosEmpenho, IEmpenhoDataAPI } from './type';
import BuscarApiEmpenho from './empenhos';
import VerificarEmpenhos from './verificarEmpenhos';

export default function EmpenhoForms({
  onSubmit,
  methods,
}: {
  methods: UseFormReturn<DadosVinculadosEmpenho>;
  onSubmit: any;
}) {
  const { trigger } = useFormContext();
  const [fetch, setFetch] = useState<boolean>(false);
  const [emp, setEmp] = useState<IEmpenhoDataAPI>();
  const planoAplicacaoId = methods.watch('planoAplicacaoId');
  const eixoFinanciadoId = methods.watch('eixoFinanciadoId');
  const natureza = methods.watch('natureza');
  const metaInstituicaoId = methods.watch('metaInstituicaoId');
  const instituicaoBeneficiadaId = methods.watch(`instituicaoBeneficiadaId`);
  const [utilizarAprov, setUtilizarAprov] = useState<string>();
  const [entrega, setEntrega] = useState<string>();
  const aprovisionamentoId = methods.watch(`aprovisionamentoId`);
  const metaFisicaId = methods.watch('metaFisicaId');
  const responsavelId = methods.watch(`responsavelId`);
  const valorEmpenhoTipo = methods.watch(`valorEmpenhoTipo`);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { data: planoAplicacao } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: () => serviceGet('planoAplicacao'),
  });
  const { data: users } = useQuery({
    queryKey: ['getUsuario'],
    queryFn: () => serviceGet('usuario'),
    enabled: !!entrega,
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  const include = ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'];

  const buscarMetas = {
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    natureza,
  };
  // BUSCAR METAS FISICA
  const { data: metasFisicas } = useQuery({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () => serviceGet('metaFisica', { ...buscarMetas }),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });
  const { data: aprovisionamentos } = useQuery({
    queryKey: ['getAprovisionamentos', metasFisicas, metaInstituicaoId],
    queryFn: () => serviceGet('aprovisionamentoMeta', {}, metaFisicaId),
    enabled: !!metasFisicas && !!metaInstituicaoId,
  });
  const { data: rowsMetaInstituicao } = useQuery({
    queryKey: ['metaInstituicaoId', metaInstituicaoId],
    queryFn: () => serviceGet('metaInstituicaoId', {}, metaInstituicaoId),
    enabled: !!metaInstituicaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao'),
  });
  const { data: rowsUnidades } = useQuery({
    queryKey: ['getUnidadesInstituicao', instituicaoBeneficiadaId],
    queryFn: () => serviceGet('unidade', {}, instituicaoBeneficiadaId ?? undefined),
    enabled: !!instituicaoBeneficiadaId,
  });

  const { data: rowsAprovisionamento } = useQuery({
    queryKey: ['getAprovisionamento', aprovisionamentoId],
    queryFn: () => serviceGet('aprovisionamentoId', {}, aprovisionamentoId),
    enabled: !!aprovisionamentoId,
  });

  const BuscarEmpenho = () => {
    setFetch(true);
  };
  useEffect(() => {
    if (metaFisicaId) {
      methods.setValue('metaInstituicaoId', '');
      methods.setValue('numeroEmpenho', '');
      methods.setValue('valorEmpenhado', 0);
      methods.setValue('processoSeiPagamento', '');
      methods.setValue('descricao', '');
      methods.setValue('instituicaoBeneficiadaId', undefined);
      methods.setValue('aprovisionamentoId', undefined);
      methods.setValue('responsavelId', undefined);
      methods.setValue('unidadeBeneficiadaId', undefined);
      methods.setValue('valorEmpenhoTipo', '');
    }
  }, [metaFisicaId, planoAplicacaoId, eixoFinanciadoId, natureza, methods]);

  useEffect(() => {
    if (rowsMetaInstituicao && rowsMetaInstituicao.instituicaoBeneficiada?.sigla !== 'SISP') {
      methods.setValue(`instituicaoBeneficiadaId`, rowsMetaInstituicao.instituicaoId);
      methods.setValue(`unidadeBeneficiadaId`, rowsMetaInstituicao.unidadeId);
    }
  }, [rowsMetaInstituicao, methods]);

  useEffect(() => {
    if (entrega === 'nao' && responsavelId) {
      methods.setValue(`responsavelId`, undefined);
    }
  }, [rowsMetaInstituicao, methods, entrega, responsavelId]);

  useEffect(() => {
    if (utilizarAprov === 'nao') {
      methods.setValue(`aprovisionamentoId`, undefined);
    }
  }, [methods, utilizarAprov]);
  const confirmarCadastro = async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsModalOpen(true);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={3}>
        <CustomSelect
          label="Exercício Orçamentário"
          name="planoAplicacaoId"
          options={
            planoAplicacao?.results?.map((item: any, indexExercicio: number) => ({
              id: item.id,
              nome: item.exercicioOrcamentario,
              disabled: item.status === 'Pendente',
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box display="grid">
          <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
            {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>

      <Grid item xs={12} md={12} lg={6}>
        <Box display="grid">
          <CustomSelect
            label="Meta Física"
            name="metaFisicaId"
            options={
              metasFisicas?.results?.map((item: any, indexExercicio: number) => ({
                id: item.id,
                nome: item.nome,
              })) ?? []
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="metaInstituicaoId" label="Instituição">
          {metasFisicas?.results
            ?.filter((meta) => meta.id === metaFisicaId)[0]
            ?.metasInstituicoes?.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.sigla}
              </MenuItem>
            ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRGMaks
          mask="____.______._____/____-__"
          label="Processo de Contratação - SEI"
          name="processoSeiMatriz"
          maskType="number"
        />
      </Grid>
      {metaInstituicaoId && metasFisicas && aprovisionamentos && (
        <>
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={4} xs={12}>
                {metaInstituicaoId && (
                  <CardValue
                    title="Valor Disponível"
                    value={`R$ ${convertToCentavosCentavos(
                      metasFisicas?.results
                        ?.find((meta) => meta.id === metaFisicaId)
                        ?.metasInstituicoes?.find((item) => item.id === metaInstituicaoId)
                        ?.saldoEstimativo ?? 0
                    )}`}
                    color="#019362 "
                  />
                )}
              </Grid>
              {aprovisionamentos && (
                <Grid item md={4} xs={12}>
                  <CardValue
                    title="Saldo Aprovisionado"
                    value={`R$ ${convertToCentavosCentavos(
                      aprovisionamentos?.valorDisponivel ?? 0
                    )}`}
                    color="#f1c40f"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {metaInstituicaoId && (
            <>
              {' '}
              <Grid item md={12} xs={12}>
                <Box
                  px={1}
                  my={1}
                  sx={{ background: '#eef7f1', borderRadius: '5px', color: 'green' }}
                >
                  <Grid container>
                    <Grid item md={6} xs={10}>
                      <Typography variant="subtitle2" sx={{ py: 1 }}>
                        Empenho
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {natureza === 'investimento' && (
                <Grid item xs={12} md={12} lg={4}>
                  <TextField
                    onChange={(e) => setEntrega(e.target.value)}
                    select
                    fullWidth
                    sx={{ my: 2 }}
                    name="entrega"
                    label="Necessário Realizar Entrega?"
                  >
                    <MenuItem value="sim">Sim</MenuItem>
                    <MenuItem value="nao">Não</MenuItem>
                  </TextField>
                </Grid>
              )}
              {users && entrega === 'sim' && (
                <Grid item xs={12} md={12} lg={4}>
                  <CustomSelect
                    select
                    name="responsavelId"
                    label="Responsável"
                    options={
                      users?.results
                        ?.filter((user: any) => user.nome !== 'ADMIN')
                        .map((user: any) => ({
                          id: user.id,
                          nome: user.nome,
                        })) ?? []
                    }
                  />
                </Grid>
              )}
              {Number(aprovisionamentos?.valorDisponivel ?? 0) > 0 && (
                <Grid item xs={12} md={12} lg={4}>
                  <TextField
                    onChange={(e) => setUtilizarAprov(e.target.value)}
                    select
                    fullWidth
                    sx={{ my: 2 }}
                    name="utilizarSaldoAprovisionamento"
                    label="Utilizar Saldo do Aprovisionamento?"
                  >
                    <MenuItem value="sim">Sim</MenuItem>
                    <MenuItem value="nao">Não</MenuItem>
                  </TextField>
                </Grid>
              )}
              {utilizarAprov === 'sim' && (
                <Grid item xs={12} lg={4}>
                  <CustomSelect
                    label="Aprovisionamento"
                    name="aprovisionamentoId"
                    options={
                      aprovisionamentos?.results
                        ?.filter(
                          (apro) =>
                            apro.metasInstituicoesId === metaInstituicaoId &&
                            apro.status !== 'CANCELADO'
                        )
                        .map((item: any, indexExercicio: number) => ({
                          id: item.id,
                          nome: item.descricao,
                        })) ?? []
                    }
                  />
                </Grid>
              )}
              {rowsAprovisionamento !== undefined && aprovisionamentoId && (
                <Grid item xs={12} md={11} lg={4} sx={{ textAlign: 'center' }}>
                  <TextFieldRG
                    disabled
                    fullWidth
                    label="Saldo Aprovisionamento"
                    value={
                      rowsAprovisionamento
                        ? `R$ ${convertToCentavosCentavos(
                            rowsAprovisionamento.valorAprovisionado -
                              rowsAprovisionamento.valorDevolvido -
                              rowsAprovisionamento.valorEmpenhado -
                              rowsAprovisionamento.valorUtilizado
                          )}`
                        : 'Aprovisionamento Não Encontrado'
                    }
                    sx={{ my: 2 }}
                    name="valorAprovisionado"
                  />
                </Grid>
              )}
              {rowsMetaInstituicao?.instituicaoBeneficiada?.sigla === 'SISP' && (
                <Grid item xs={12} lg={4}>
                  <TextFieldRG
                    select
                    name="instituicaoBeneficiadaId"
                    label="Instituição Beneficiada"
                  >
                    {rowInstituicoes?.results.map((inst) => (
                      <MenuItem key={inst.id} value={inst.id}>
                        {inst.sigla}
                      </MenuItem>
                    ))}
                  </TextFieldRG>
                </Grid>
              )}
              {(rowsUnidades?.total ?? 0) > 0 && (
                <Grid item xs={12} lg={4}>
                  <TextFieldRG
                    select
                    disabled={rowsMetaInstituicao?.unidadeId}
                    name="unidadeBeneficiadaId"
                    label="Unidade Beneficiada"
                  >
                    {rowsUnidades?.results.map((inst: any) => (
                      <MenuItem key={inst.id} value={inst.id}>
                        {inst.sigla}
                      </MenuItem>
                    ))}
                  </TextFieldRG>
                </Grid>
              )}
              <Grid item xs={12} lg={4}>
                <TextFieldRGMaks
                  mask="____.______._____/____-__"
                  label="Processo de Pagamento - SEI"
                  name="processoSeiPagamento"
                  maskType="number"
                />
              </Grid>
              <Grid item md={12} />
              <Grid item xs={12} md={4} lg={4}>
                <CustomSelect
                  label="valor do Empenho"
                  name="valorEmpenhoTipo"
                  options={
                    ['Integral', 'Parcial'].map((item) => ({
                      id: item,
                      nome: item,
                    })) ?? []
                  }
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextFieldRGMaks
                  mask="__________/____"
                  label="Número do Empenho"
                  name="numeroEmpenho"
                />
              </Grid>
              <Grid item xs={12} md={1} lg={1} sx={{ textAlign: 'center' }}>
                <IconButton
                  color="primary"
                  size="large"
                  sx={{ mt: 2.5 }}
                  onClick={() => BuscarEmpenho()}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
              <Grid item md={12} />
              {valorEmpenhoTipo === 'Parcial' && (
                <>
                  <Grid item xs={12} md={3}>
                    <TextFieldMoney label="Valor Empenhado" name="valorEmpenhado" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFieldMoney label="Valor Pago" name="valorPago" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFieldMoney label="Valor Anulado" name="valorAnulado" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFieldMoney label="Valor Complementar" name="valorComplemento" />
                  </Grid>
                </>
              )}
              <BuscarApiEmpenho
                methods={methods}
                fetch={fetch}
                setFetch={setFetch}
                setEmp={setEmp}
              />
              {emp && <VerificarEmpenhos methods={methods} fetch={fetch} empenho={emp} />}
              <Grid item xs={12} md={12} lg={12}>
                <TextFieldRG multiline rows={3} name="descricao" label="Descrição" />
              </Grid>
            </>
          )}
        </>
      )}
      <ModalConfirmar
        isOpen={isModalOpen}
        nomeAcao="Despesa"
        handleClose={handleCloseModal}
        onConfirm={methods.handleSubmit(onSubmit)}
      />
      <Grid item xs={12} md={12} sx={{ textAlign: 'right', mb: 3 }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<SaveIcon />}
          onClick={confirmarCadastro}
        >
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
