import { useContext, useState } from 'react';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { Box, Grid, Button, IconButton, TextField, Stack, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalExcluir from 'src/components/Modal/Excluir';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { convertToCentavosCentavos, tratandoData, valorMonetario } from 'src/config/utils';
import { ErroResponse } from 'src/pages/types';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import { serviceDelete, serviceGet } from 'src/services';
import DespesaVisualizar from './Empenhos/Visualizar';
import CadastrarEmpenho from './Empenhos/Cadastrar';

export default function Empenhos() {
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [instituicaoId, setInstituicaoId] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const apagarCampos = () => {
    setEixoFinanciadoId('');
    setPlanoAplicacaoId('');
    setInstituicaoId('');
    setSearch('');
  };
  const include = ['metaFisica', 'user'];
  const parametros = {
    filters: { search },
    planoAplicacaoId,
    eixoFinanciadoId,
    instituicaoId,
    include,
  };
  const [checked, setChecked] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('');
  const queryClient = useQueryClient();

  const handleButtonClick = (despesaId: string, typeAction: string) => {
    setId(despesaId);
    setIsModalOpen(true);
    setType(typeAction);
  };

  const { mutate } = useMutation({
    mutationFn: (idEmpenho: string) => serviceDelete('despesas', {}, idEmpenho),
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const columns: GridColDef[] = [
    {
      field: 'metaFisica',
      headerName: 'META FÍSICA',
      flex: 3.5,
      cellClassName: 'cell-wrap',
      minWidth: 250,
    },
    {
      field: 'numeroEmpenho',
      headerName: 'EMPENHO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
    },

    {
      field: 'descricao',
      headerName: 'DESCRIÇÂO',
      flex: 3,
      minWidth: 250,
    },
    {
      field: 'valorEmpenhado',
      headerName: 'VALOR EMPENHADO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,

      renderCell: (params) => <> R$ {convertToCentavosCentavos(params.row.valorEmpenhado)}</>,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            sx={{ color: 'gray' }}
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
    queryClient.removeQueries({ queryKey: ['getEmpenhos'] });
  };
  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao'),
  });

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={1}>
        <CustomBreadcrumbs
          heading="Despesas"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            {
              name: 'Despesas',
              href: '/planejamento/fundonacional/Despesas',
            },
          ]}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} xl={2} sx={{ my: 2, ml: -1 }}>
            <TextField
              size="small"
              select
              name="exercicioOrcamentario"
              label="Ano"
              fullWidth
              value={planoAplicacaoId}
              onChange={(event) => setPlanoAplicacaoId(event.target.value)}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                },
              }}
            >
              {rowPlanosAplicacao &&
                rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
                  <MenuItem
                    key={indexExercicio}
                    value={item.id}
                    sx={{
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    {item.exercicioOrcamentario}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} xl={3} sx={{ my: 2 }}>
            <TextField
              size="small"
              select
              name="eixoFinanciado"
              label="Eixo Financiado"
              fullWidth
              onChange={(event) => setEixoFinanciadoId(event.target.value)}
            >
              {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
                <MenuItem key={indexEixoFinanciado} value={item.id}>
                  {item.eixoNome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2} xl={2} sx={{ my: 2 }}>
            <TextField
              size="small"
              select
              name="instituicaoId"
              label="Instituição"
              fullWidth
              value={instituicaoId}
              onChange={(event) => setInstituicaoId(event.target.value)}
            >
              {rowInstituicoes?.results.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.sigla}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                color="info"
                sx={{ background: '#D4EAF7   ', mx: 1 }}
                onClick={() => {
                  apagarCampos();
                }}
              >
                <CachedIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastrar')}
              startIcon={<AddIcon />}
            >
              Vincular Empenho
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} mt={2}>
            <TableContainer
              columns={columns}
              endpoint="despesas"
              setRefetch={tableRefetch}
              params={parametros}
              setTableRefetch={setTableRefetch}
            />
          </Grid>
        </Grid>

        {isModalOpen && type === 'Cadastrar' && (
          <CadastrarEmpenho
            open={isModalOpen}
            handleClose={handleCloseModal}
            setTableRefetch={setTableRefetch}
          />
        )}
        {id && isModalOpen && type === 'Visualizar' && (
          <DespesaVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
        )}
        {id && isModalOpen && type === 'Excluir' && (
          <ModalExcluir
            isOpen={isModalOpen}
            nomeAcao="Despesa"
            id={id}
            mutate={mutate}
            handleClose={handleCloseModal}
          />
        )}
      </Stack>
    </Box>
  );
}
