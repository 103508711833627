import { useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import MetaGeral from './MetaGeral';
import MetaFisica from './MetaFisica';

export default function MetasPlano() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={1}>
        <CustomBreadcrumbs
          heading="Metas"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Metas', href: '/planejamento/fundonacional/metas' },
          ]}
        />
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',

              '& .MuiButtonBase-root': {
                fontSize: '13px',
                height: 1,
              },
              '@media (max-width: 600px)': {
                '& .MuiButtonBase-root': {
                  minWidth: '45%',
                },
              },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tab label="Metas Físicas" {...a11yProps(0)} />
              <Tab label="Metas Gerais" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <MetaFisica />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MetaGeral />
          </CustomTabPanel>
        </Box>
      </Stack>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
