import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CommuteIcon from '@mui/icons-material/Commute';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const PivateModules = [
  {
    title: 'Administrador',
    link: '/administrador',
    icon: <AdminPanelSettingsIcon fontSize="large" />,
    permissao: 'admin',
  },

  {
    title: 'Administrativo',
    link: '/administrativo',
    icon: <WorkOutlineOutlinedIcon fontSize="large" />,
    permissao: 'ADMINISTRATIVO',
    disabled: true,
  },
  {
    title: 'Agendamento',
    link: '/agendamento',
    icon: <EventAvailableOutlinedIcon fontSize="large" />,
    permissao: 'admin',
    disabled: true,
  },
  {
    title: 'Apoio Biopsicossocial',
    link: '/ciab',
    icon: <Diversity1OutlinedIcon fontSize="large" />,
    permissao: 'CIAB',
    disabled: true,
  },
  {
    title: 'Entregas',
    link: '/entregas',
    icon: <LocalShippingIcon fontSize="large" />,
    permissao: 'ENTREGA',
  },
  {
    title: 'Operações Aéreas',
    link: '/ciopaer',
    icon: <AirplanemodeActiveIcon fontSize="large" />,
    permissao: 'CIOPAER',
  },
  {
    title: 'Operacional',
    link: '/operacional',
    icon: <CommuteIcon fontSize="large" />,
    permissao: 'OPERACIONAL',
    disabled: true,
  },
  {
    title: 'Planejamento',
    link: '/planejamento',
    icon: <FolderOpenOutlinedIcon fontSize="large" />,
    permissao: 'PLANEJAMENTO',
  },

  {
    title: 'Tecnologia',
    link: '/tecnologia',
    icon: <DevicesOutlinedIcon fontSize="large" />,
    permissao: 'TIC',
    disabled: true,
  },
];

export const PublicsModules = [
  {
    title: 'Agendamento',
    link: '/agendamento',
    icon: <EventAvailableOutlinedIcon fontSize="large" />,
    permissao: 'admin',
    disabled: true,
  },
];
