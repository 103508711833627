import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

interface TextFieldData {
  label: string;
  name: string;
  inputRef?: any;
  [key: string]: any;
}
export default function TextFieldRG({ label, name, inputRef, ...rest }: TextFieldData) {
  const { register, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={value}
          label={label}
          {...rest}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
}
