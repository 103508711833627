import { convertToCentavos } from 'src/config/utils';
import * as yup from 'yup';

export const schemaAbastecimento = yup.object({
  empresaId: yup.string().required('Empresa é obrigatório'),
  combustivelId: yup.string().required('Combustivel é obrigatório'),
  aeronaveId: yup.string().required('Aeronave é obrigatório'),
  dataAbastecimento: yup.date().required('é obrigatório'),
  litros: yup.number().required('é obrigatório'),
  valorAbastecido: yup
    .mixed()
    .transform((value: any) => (value ? convertToCentavos(value) : 0))

    .required('É obrigatório'),
  observacao: yup.string().required('É obrigatório'),
});

export interface Abastecimento {
  empresaId: string;
  combustivelId: string;
  aeronaveId: string;
  dataAbastecimento: Date;
  litros: number;
  valorAbastecido: any;
  observacao: string;
  usuarioCreatedId: string;
}
export const schemaAeronave = yup.object({
  tipoUso: yup.string().required('Tipo de uso é obrigatório'),
  status: yup.string().required('Situação é obrigatória'),
  prefixo: yup.string().required('Prefixo é obrigatório'),
  apelido: yup.string().required('Apelido é obrigatório'),
  fabricante: yup.string().required('Fabricante é obrigatório'),
  modelo: yup.string().required('Modelo é obrigatório'),
  numeroSerie: yup.string().required('Número de série é obrigatório'),
  anoFabricacao: yup.string().required('Ano de fabricação é obrigatório'),
  horasInicias: yup.number().required('Horas iniciais são obrigatórias'),
});

export const dataAbastecimento = {
  empresaId: '',
  combustivelId: '',
  aeronaveId: '',
  dataAbastecimento: '',
  litros: 0,
  valorAbastecido: 0,
  observacao: '',
};
