import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErroResponse } from 'src/pages/types';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext } from 'react';
import FormPlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Formulario/formPlanoAplicacao';
import { useNavigate } from 'react-router-dom';
import { servicePost } from 'src/services';
import { defaultValuesPlano, IPlanoAplicacaoData, schemaPlanoAplicacao } from '../Formulario/type';

export default function CadastrarPlano() {
  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const methods = useForm({
    resolver: yupResolver(schemaPlanoAplicacao),
    defaultValues: defaultValuesPlano,
  });

  const {
    reset,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const { mutate } = useMutation({
    mutationFn: (data: IPlanoAplicacaoData) => servicePost('planoAplicacao', data),
    onSuccess: (data) => {
      reset(defaultValuesPlano);
      navigate('/planejamento/fundonacional/planodeaplicacao');
      ToastFun('Plano Cadastrado com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: IPlanoAplicacaoData) => {
    if (state.usuario) {
      data.usuarioCreateId = state.usuario.id;
    } else {
      ToastFun('Usuário não encontrado', 'error');
      return;
    }

    data.eixosFinanciados = data.eixosFinanciados.filter((eixo) => eixo !== null);

    mutate(data);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Plano de Aplicação - Cadastrar"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Plano de Aplicação', href: '/planejamento/fundonacional/planodeaplicacao' },
            { name: 'Cadastro', href: '/planejamento/fundonacional/planodeaplicacao/cadastro' },
          ]}
        />
        <FormsProvider methods={methods} handleSubmit={onSubmit}>
          <FormPlanoAplicacao unregister={unregister} setValue={setValue} />
        </FormsProvider>
      </Stack>
    </Box>
  );
}
