import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { getAeronaves } from 'src/services/Operacional/Ciopaer/aeronave';
import { useQuery } from '@tanstack/react-query';
import CadastroAeronave from '../Cadastro/aeronave';
import AtualizarAeronave from '../Atualizar/aeronave';

export default function Aeronaves() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const {
    data: rows,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getMetas', skip, take, search],
    queryFn: getAeronaves,
  });

  const handleButtonClick = (idAeronave: string, type: string) => {
    setId(idAeronave);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'tipoUso',
      headerName: 'TIPO USO',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'prefixo',
      headerName: 'PREFIXO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'apelido',
      headerName: 'APELIDO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'numeroSerie',
      headerName: 'Nº SÉRIE',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'fabricante',
      headerName: 'FABRICANTE',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'modelo',
      headerName: 'MODELO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'horasVoos',
      headerName: 'HORAS DE VÔO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'anoFabricacao',
      headerName: 'ANO DE FABRICAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Inativa' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="warning" onClick={() => handleButtonClick(params.row.id, 'Editar')}>
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Aeronave
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            {/* <CustomContainerList title="Aeronaves">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList> */}
          </Grid>
        </Grid>
        {id && modalType === 'Editar' && (
          <AtualizarAeronave
            open={isModalOpen}
            handleClose={handleCloseModal}
            refetch={refetch}
            id={id}
          />
        )}
        {!id && (
          <CadastroAeronave open={isModalOpen} handleClose={handleCloseModal} refetch={refetch} />
        )}
      </Stack>
    </Box>
  );
}
