import { Box, Grid, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { convertToCentavosCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { serviceGet } from 'src/services';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaFisicaVisualizaDetalhes({ id }: { id: string }) {
  const include = ['metaFisicaCompleto', 'metasInstituicoes'];
  const params = {
    include,
  };
  const { data: rowsMeta } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => serviceGet('metaFisicalId', { ...params }, id),
  });

  return (
    <Grid container>
      <Grid md={12} xs={12}>
        <TableContainer>
          {/* DADOS DA META - ANO - EIXO -META GERAL */}
          <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
            <TableHead sx={{ background: '#f5f5f5' }}>
              <TableRow>
                <TableCellHead>Exercicío Orçamentário</TableCellHead>
                <TableCellHead>Eixo Financiado</TableCellHead>
                <TableCellHead colSpan={3}>Meta Geral</TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellBody>{rowsMeta?.planoAplicacao?.exercicioOrcamentario}</TableCellBody>
                <TableCellBody>{rowsMeta?.eixosFinanciados?.eixoNome}</TableCellBody>
                <TableCellBody colSpan={3}>{rowsMeta?.metaGeral?.nome}</TableCellBody>
              </TableRow>
              <TableRow sx={{ background: '#f5f5f5' }}>
                <TableCellHead colSpan={2}>Meta Física</TableCellHead>
                <TableCellHead>Natureza</TableCellHead>
                <TableCellHead colSpan={2}>Tipo de Despesa</TableCellHead>
              </TableRow>
              <TableRow>
                <TableCellBody colSpan={2}>{rowsMeta?.nome}</TableCellBody>
                <TableCellBody>{rowsMeta?.natureza}</TableCellBody>
                <TableCellBody colSpan={2}>{rowsMeta?.tipoDespesaData?.nome}</TableCellBody>
              </TableRow>
              <TableRow sx={{ background: '#f5f5f5' }}>
                <TableCellHead colSpan={2}>INSTITUIÇÃO BENEFICIADA</TableCellHead>
                <TableCellHead> Valor Planejado</TableCellHead>
                <TableCellHead>UNIDADE</TableCellHead>
              </TableRow>
              {rowsMeta?.metasInstituicoes?.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCellBody colSpan={2}>{item.nome}</TableCellBody>
                  <TableCellBody>R$ {convertToCentavosCentavos(item.valorInicial)}</TableCellBody>
                  <TableCellBody>
                    {item.unidade ? item.unidade : 'Unidade Não Atribuida'}
                  </TableCellBody>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* DADOS DA META FISICA -INSTITUIÇÕES */}
        </TableContainer>
      </Grid>
    </Grid>
  );
}
