import { Box, Modal, Grid, IconButton, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { atualizarAeronave, getAeronave } from 'src/services/Operacional/Ciopaer/aeronave';
import AeronaveForms from '../Formulario/aeronave';
import { schemaAeronave } from '../Cadastro/type';

export default function AtualizarAeronave({
  open,
  handleClose,
  refetch,
  id,
}: {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
  id: string;
}) {
  const { state } = useContext(AuthContext);

  const { data: rows } = useQuery({
    queryKey: ['getAeronave', id],
    queryFn: getAeronave,
  });

  const methods = useForm({
    resolver: yupResolver(schemaAeronave),
    defaultValues: {
      tipoUso: rows?.tipoUso || '',
      status: rows?.status || '',
      ...rows,
    },
  });

  const { reset } = methods;

  useEffect(() => {
    if (rows) {
      reset(rows);
    }
  }, [rows, reset]);
  const { mutate, isPending } = useMutation({
    mutationFn: atualizarAeronave,
    onSuccess: () => {
      ToastFun('Aeronave atualizada com sucesso', 'success');
      handleClose();
      reset();
      refetch();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message || 'Erro ao atualizar aeronave', 'error');
    },
  });

  const onSubmit = (data: any) => {
    data.usuarioCreateId = state.usuario?.id;
    mutate(data);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          margin: 'auto',
          mt: 3,
        }}
      >
        {rows && (
          <Grid container>
            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
              <IconButton aria-label="Fechar modal" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item md={12} xs={12}>
              <Alert
                sx={{ background: '#019362 ', color: 'white', fontWeight: '700' }}
                icon={<DrawIcon sx={{ color: 'white' }} />}
              >
                Aeronave - Atualizar
              </Alert>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <AeronaveForms id={id} />
              </FormsProvider>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
}
