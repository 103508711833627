import { useState, useContext, useEffect } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { AuthContext } from 'src/Context/ContextUser';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  MenuItem,
  Menu,
  Divider,
  Drawer,
  Typography,
  Toolbar,
  CssBaseline,
  Paper,
  Breadcrumbs,
  Link,
  IconButton,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MinvervaHorizontal from '../../assets/images/Minerva_hori_menu.png';
import MinvervaCab from '../../assets/images/minerva_cab.png';
import * as modules from '../MenuModulo/Modulos';
import ItemList from '../MenuModulo/LayoutMenu';

const drawerWidth = 255;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  height: '30vh',
  zIndex: -1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
const DrawerMD = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    '& .MuiPaper-root': {
      marginTop: '20px',
      marginLeft: '18px',
      height: '95.5vh',
      borderRadius: '15px',
      background: '#FFFFFF',
      boxShadow: '1px 1px 2px #e3e3e3',
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

interface Modulo {
  title: string;
  link: string;
  icon: JSX.Element;
  disabled?: boolean;
  permissao: string;
  children?: any;
}
type Modulos = Modulo[];

export default function LayoutSidebar() {
  const { state, openSidebar, closeSidebar, logout } = useContext(AuthContext);

  const [modulosPrivados, setModulosPrivados] = useState<Modulos>([]);

  const location = useLocation();
  const navgation = useNavigate();

  const pathSegments = location.pathname.split('/').filter(Boolean);

  const moduleAtual = pathSegments[0];
  const submoduleAtual = pathSegments[1];
  const subMod = pathSegments[2];

  const handleDrawer = () => {
    if (!state.sidebarOpen) {
      openSidebar();
    } else {
      closeSidebar();
    }
  };
  function LogoutUser() {
    navgation('/');
    logout();
    localStorage.removeItem('sessionStartTime');
  }

  const module = (modules as any)[moduleAtual];
  const modTitle = module.filter((mod: any) => mod.link === `/${moduleAtual}/${submoduleAtual}`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      state.usuario?.permissoes.includes('*') ||
      state.usuario?.permissoes.includes(`${moduleAtual.toUpperCase()}_*`)
    ) {
      setModulosPrivados(module);
    } else {
      const modulos = module.filter((mod: any) =>
        state.usuario?.permissoes.some((item) => item.includes(mod.permissao))
      );
      setModulosPrivados(modulos);
    }
  }, [module, state.usuario?.permissoes, moduleAtual]);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" sx={{ background: '#019362 ' }} />
      <DrawerMD
        variant="permanent"
        open={state.sidebarOpen}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
          },
          ...(!state.sidebarOpen && {
            display: {
              xs: 'none',
              md: 'block',
            },
          }),
          ...(state.sidebarOpen && {
            display: {
              xs: 'none',
              md: 'block',
            },
          }),
        }}
      >
        <DrawerHeader>
          <Box
            sx={{
              p: 1,
              ...(!state.sidebarOpen && {
                display: 'none',
              }),
            }}
          >
            {' '}
            <Link color="inherit" href="/menu">
              <img src={MinvervaHorizontal} alt="" width={175} />
            </Link>
            <Divider />
          </Box>
          <Box
            sx={{
              p: 1,
              ...(state.sidebarOpen && {
                display: 'none',
              }),
            }}
          >
            <Link color="inherit" href="/menu">
              <img src={MinvervaCab} alt="" />
            </Link>

            <Divider />
          </Box>
        </DrawerHeader>
        {modulosPrivados.map((item, index) => (
          <ItemList
            key={index}
            title={item.title}
            disabled={item.disabled}
            Icon={item.icon}
            link={item.link}
            select={
              submoduleAtual ===
              item.title
                .normalize('NFD')
                .replace(/[\u0300-\u036f\s]/g, '')
                .toLocaleLowerCase()
            }
            open={state.sidebarOpen}
            children={item.children}
          />
        ))}
      </DrawerMD>
      <Drawer
        sx={{
          mt: 5,
          display: {
            md: 'none',
          },
          '& .MuiDrawer-paper': {
            width: '100%',
            borderRadius: '20px',
          },
        }}
        variant="persistent"
        anchor="top"
        open={state.sidebarOpen}
      >
        <Box sx={{ textAlign: 'right', mr: 3 }}>
          <IconButton onClick={handleDrawer} edge="end">
            <MenuIcon />
          </IconButton>
        </Box>

        <Box sx={{ mt: -5, zIndex: -1 }}>
          {modulosPrivados.map((item, index) => (
            <ItemList
              key={index}
              title={item.title}
              Icon={item.icon}
              link={item.link}
              select={false}
              disabled={item.disabled}
              open={state.sidebarOpen}
              children={item.children}
            />
          ))}
        </Box>
      </Drawer>
      <Main
        open={state.sidebarOpen}
        sx={{
          pl: {
            md: 3,
          },
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'white', ml: -2 }}>
            <IconButton
              onClick={handleDrawer}
              edge="start"
              sx={{
                color: 'white',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link sx={{ display: 'flex', alignItems: 'center' }} color="inherit" href="/menu">
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Link
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              href={`/${moduleAtual}`}
            >
              {moduleAtual.charAt(0).toUpperCase() + moduleAtual.slice(1)}
            </Link>
            {modTitle.length > 0 && (
              <Link
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href={modTitle[0].link}
              >
                {modTitle[0].title}
              </Link>
            )}
          </Breadcrumbs>

          <Box sx={{ textAlign: 'right', display: 'block', mr: -3 }}>
            <IconButton
              size="large"
              onClick={handleMenu}
              sx={{
                mt: 2.5,
                color: 'white',
              }}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              sx={{ mt: 5 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => LogoutUser()}>Sair</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <Paper sx={{ p: 2, mt: 0.5, mx: 1 }}>
          <Outlet />
        </Paper>
      </Main>
    </Box>
  );
}
