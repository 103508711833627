import { Controller, useFormContext } from 'react-hook-form';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  useTheme,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useState } from 'react';

interface SelectMultipleData {
  label: string;
  name: string;
  inputRef?: any;
  options: { id: string; nome: string }[];
  [key: string]: any;
}

export default function SelectMultiple({
  label,
  name,
  inputRef,
  options,
  ...rest
}: SelectMultipleData) {
  const { control } = useFormContext();
  const theme = useTheme();
  const [optionsCheck, setOptionsCheck] = useState<string[]>([]);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <FormControl sx={{ my: 2 }} error={invalid} fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            multiple
            value={value || []}
            onChange={(e) => {
              onChange(e.target.value);
              setOptionsCheck(e.target.value);
            }}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) =>
              selected
                .map((selectedValue: string) => {
                  const selectedOption = options.find((option) => option.id === selectedValue);
                  return selectedOption ? selectedOption.nome.toUpperCase() : '';
                })
                .join(', ')
            }
            {...rest}
          >
            {options?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox checked={optionsCheck.includes(option.id)} />
                <ListItemText primary={option.nome} sx={{ textTransform: 'uppercase' }} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
