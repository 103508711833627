import { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormsProvider from 'src/components/Forms/Provider';
import { ToastFun } from 'src/config/functions';
import { useMutation } from '@tanstack/react-query';
import { AuthContext } from 'src/Context/ContextUser';
import { useNavigate } from 'react-router-dom';
import { ErroResponse } from 'src/pages/types';
import { servicePost } from 'src/services';
import { IMetaFisicaSchemaData, schemaMetasFisicas } from '../../Formulario/types';
import MetasFisicas from '../../Formulario/MetasFisicas';

export default function CadastrarMetasFisicas() {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schemaMetasFisicas),
  });
  const { state } = useContext(AuthContext);

  const {
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: (data: IMetaFisicaSchemaData) => servicePost('metaFisica', { ...data }),
    onSuccess: (data) => {
      navigate('/planejamento/fundonacional/metas');
      ToastFun('Metas Cadastradas com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.metasFisicas = data.metasFisicas
      .filter((meta: any) => meta !== null)
      .map((item: { metasInstituicoes: any[] }) => ({
        ...item,
        metasInstituicoes: item.metasInstituicoes.filter(
          (instituicao: any) => instituicao !== null
        ),
      }));

    if (state.usuario?.id) {
      data.usuarioCreateId = state.usuario.id;
    }

    mutate(data);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={1}>
        <CustomBreadcrumbs
          heading="Meta Física - Cadastrar"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Metas', href: '/planejamento/fundonacional/metas' },
            { name: 'Cadastro', href: '/planejamento/fundonacional/metas/cadastro' },
          ]}
        />
        <FormsProvider methods={methods} handleSubmit={onSubmit}>
          <MetasFisicas setValue={setValue} unregister={unregister} watch={watch} />
        </FormsProvider>
      </Stack>
    </Box>
  );
}
