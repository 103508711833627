import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { ErroResponse } from 'src/pages/types';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import OcorrenciaForms from '../Formulario';

export default function CadastrarOcorrencia() {
  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const methods = useForm({});

  const {
    reset,
    unregister,
    setValue,
    formState: { errors },
  } = methods;
  //   const { mutate, isPending } = useMutation({
  //     mutationFn: postCadastrarPlanoAplicacao,
  //     onSuccess: (data) => {
  //       navigate('/planejamento/fundonacional/planodeaplicacao');
  //       ToastFun('Plano Cadastrado com sucesso', 'success');
  //     },
  //     onError: (error: ErroResponse) => {
  //       ToastFun(error.response.data?.message, 'error');
  //     },
  //   });

  //   const onSubmit = async (data: any) => {
  //     if (state.usuario?.id) {
  //       data.planoAplicacao.usuarioCreateId = state.usuario.id;
  //     }
  //     mutate(data);
  //   };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={5}>
        <CustomBreadcrumbs
          heading="Centro Integrado de Operações Aéreas"
          links={[
            { name: 'Menu', href: '/operacional/ciopaer' },
            { name: 'Ocorrências', href: '/operacional/ciopaer/ocorrencias' },
            { name: 'Cadastro', href: '/operacional/ciopaer/ocorrencias/cadastro' },
          ]}
        />
        {/* <CustomContainerCad title="Ocorrências">
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <OcorrenciaForms />
          </FormsProvider>
        </CustomContainerCad> */}
      </Stack>
    </Box>
  );
}
