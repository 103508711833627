import { Grid, Alert, styled, TableCell, Box, Chip } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { serviceGet } from 'src/services';
import CustomModal from 'src/components/Modal';
import {
  convertToCentavos,
  convertToCentavosCentavos,
  tratandoData,
  valorMonetario,
} from 'src/config/utils';
import { getColorStatus } from '../Aprovisionamento';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function EntregaVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: () => void;
}) {
  const { data: rows, isPending } = useQuery({
    queryKey: ['getEntrega', id],
    queryFn: () => serviceGet('entregaId', {}, id),
  });
  return (
    <div>
      <CustomModal
        open={open}
        onClose={handleClose}
        title="Entrega"
        icon={<LocalShippingIcon color="primary" />}
      >
        <Grid container sx={{ mb: 3 }}>
          <Grid md={12} xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Meta Física</TableCellHead>
                    <TableCellHead align="center">Instituição</TableCellHead>
                    <TableCellHead align="center" sx={{ width: '250px' }}>
                      Processo de Pagamento
                    </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{rows?.metaFisica?.nome}</TableCellBody>
                    <TableCellBody align="center">{rows?.metaInstituicao?.sigla}</TableCellBody>
                    <TableCellBody align="center" sx={{ width: '250px' }}>
                      {rows?.despesa?.processoSeiPagamento}
                    </TableCellBody>
                  </TableRow>
                </TableBody>
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Descrição de Despesa</TableCellHead>
                    <TableCellHead align="center">Empenho</TableCellHead>
                    <TableCellHead align="center">valor </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{rows?.despesa?.descricao}</TableCellBody>
                    <TableCellBody align="center">{rows?.despesa?.numeroEmpenho}</TableCellBody>
                    <TableCellBody align="center">
                      R$ {convertToCentavosCentavos(rows?.despesa?.valorEmpenhado ?? 0)}
                    </TableCellBody>
                  </TableRow>
                </TableBody>
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead colSpan={2}>Responsável Pela Entrega</TableCellHead>
                    <TableCellHead align="center">Situação</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody colSpan={2}>{rows?.usuarioResponsavel?.nome}</TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        label={rows?.status}
                        sx={{ color: 'white' }}
                        color={rows?.status ? getColorStatus(rows?.status) : 'default'}
                      />
                    </TableCellBody>
                  </TableRow>
                </TableBody>
                {rows?.status === 'Entregue' && (
                  <>
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead>Descrição da Entrega</TableCellHead>
                        <TableCellHead align="center">Data da Entrega</TableCellHead>
                        <TableCellHead align="center">Documento SEI</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody>{rows?.descricao}</TableCellBody>
                        <TableCellBody align="center">
                          {tratandoData(rows?.dataEntrega)}
                        </TableCellBody>
                        <TableCellBody align="center">{rows?.documentoSei}</TableCellBody>
                      </TableRow>
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
}
