import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page404 from 'src/assets/images/404.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export function PageError() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Box>
        <img src={Page404} alt="" style={{ width: '60%' }} />
      </Box>
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        sx={{ p: 1.5, borderRadius: 5, width: '200px', background: '#019362 ' }}
        onClick={() => navigate('/')}
      >
        Voltar para o ínicio
      </Button>
    </Box>
  );
}
