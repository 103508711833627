import { Box, Button, Grid, Stack, Tab, Tabs } from '@mui/material';
import { Children, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import CustomTabs from 'src/components/CustomTabs';
import PlanoAplicacaoVisualizar from './PlanoAplicacao/detalhes';
import PlanoAplicacaoRendimentoVisualizar from './PlanoAplicacao/rendimento';

export default function PlanoAplicacaoVisualizarTabs() {
  const { id } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const listTabs = [
    {
      label: 'Detalhes',
      children: <PlanoAplicacaoVisualizar id={id!} />,
    },
    {
      label: 'Rendimentos',
      children: <PlanoAplicacaoRendimentoVisualizar id={id!} />,
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container sx={{ my: 3 }}>
          <Grid item md={6} xs={12}>
            <CustomBreadcrumbs
              heading={`Plano de Aplicação - ${listTabs[value].label}`}
              links={[
                { name: 'Planejamento', href: '/planejamento' },
                { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
                {
                  name: 'Plano de Aplicação',
                  href: '/planejamento/fundonacional/planodeaplicacao',
                },
                {
                  name: `${listTabs[value].label}`,
                  href: '#',
                },
              ]}
            />
          </Grid>
          <Grid item md={6} sx={{ mt: 2 }}>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                startIcon={<SubdirectoryArrowLeftIcon />}
                variant="contained"
                color="light"
                href="/planejamento/fundonacional/planodeaplicacao"
              >
                Voltar
              </Button>
            </Box>
          </Grid>
          <Grid md={12}>
            <CustomTabs listTabs={listTabs} value={value} handleChange={handleChange} />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
