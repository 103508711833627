import { Api } from 'src/services/Api';
import { AxiosInstance } from 'axios';
import Endpoints from './endpoints';
import {
  EndpointsResponseGet,
  EndpointsResponsePost,
  EndpointsResponsePatch,
  EndpointsResponseDelete,
} from './endpointsResponse';

export async function serviceGet<T extends keyof EndpointsResponseGet>(
  endpoint: T,
  params: Record<string, any> = {},
  id?: string,
  client: AxiosInstance = Api // Usa Api por padrão
): Promise<EndpointsResponseGet[T]> {
  try {
    const url = id ? `${Endpoints[endpoint]}/${id}` : (Endpoints[endpoint] as string);
    const response = await client.get<EndpointsResponseGet[T]>(url, { params });
    return response.data;
  } catch (error) {
    console.error('Erro no GET:', error);
    throw error;
  }
}

export async function servicePost<T extends keyof EndpointsResponsePost>(
  endpoint: T,
  data: any
): Promise<EndpointsResponsePost[T]> {
  try {
    const response = await Api.post<EndpointsResponsePost[T]>(Endpoints[endpoint] as string, data);
    return response.data;
  } catch (error) {
    console.error('Erro no POST:', error);
    throw error;
  }
}

export async function servicePatch<T extends keyof EndpointsResponsePatch>(
  endpoint: T,
  data: any,
  id?: string
): Promise<EndpointsResponsePatch[T]> {
  try {
    const url = id ? `${Endpoints[endpoint]}/${id}` : Endpoints[endpoint];
    const response = await Api.patch<EndpointsResponsePatch[T]>(url, data);
    return response.data;
  } catch (error) {
    console.error('Erro no PATCH:', error);
    throw error;
  }
}

export async function serviceDelete<T extends keyof EndpointsResponseDelete>(
  endpoint: T,
  params: Record<string, any> = {},
  id?: string
): Promise<EndpointsResponseDelete[T]> {
  try {
    const url = id ? `${Endpoints[endpoint]}/${id}` : Endpoints[endpoint];
    const response = await Api.delete<EndpointsResponseDelete[T]>(url, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error('Erro no DELETE:', error);
    throw error;
  }
}
