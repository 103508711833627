import { useContext, useState } from 'react';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { Box, Grid, Button, IconButton, TextField, Stack, MenuItem, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { convertToCentavosCentavos } from 'src/config/utils';
import { ErroResponse } from 'src/pages/types';
import CachedIcon from '@mui/icons-material/Cached';
import TableContainer from 'src/components/Table';
import { serviceGet, servicePatch } from 'src/services';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ModalAcao from 'src/components/Modal/CustomModalAcao';
import { AuthContext } from 'src/Context/ContextUser';
import CadastroAprovisionamento from './Cadastro';
import AprovisionamentoVisualizar from './Visualizar';

export default function Aprovisionamento() {
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [instituicaoId, setInstituicaoId] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const parametros = {
    filters: { search },
    planoAplicacaoId,
    eixoFinanciadoId,
    instituicaoId,
  };
  const [checked, setChecked] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('');
  const { state } = useContext(AuthContext);

  const handleButtonClick = (aprovisionamentoId: string, typeAction: string) => {
    setId(aprovisionamentoId);
    setIsModalOpen(true);
    setType(typeAction);
  };
  const dadosCancelar = {
    status: 'CANCELADO',
    usuarioUpdateId: state.usuario?.id,
    atualizadoEm: new Date(),
  };
  const dadosDevolver = {
    status: 'DEVOLVIDO',
    usuarioUpdateId: state.usuario?.id,
    atualizadoEm: new Date(),
  };
  const apagarCampos = () => {
    setEixoFinanciadoId('');
    setPlanoAplicacaoId('');
    setInstituicaoId('');
    setSearch('');
  };
  const { mutate: cancelarAprov } = useMutation({
    mutationFn: (idAprovisionamento: string) =>
      servicePatch('aprovisionamento', { ...dadosCancelar }, idAprovisionamento),
    onSuccess: (data) => {
      ToastFun('Cancelado com Sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const { mutate: devolverAprov } = useMutation({
    mutationFn: (idAprovisionamento: string) =>
      servicePatch('aprovisionamentoDevolver', { ...dadosDevolver }, idAprovisionamento),
    onSuccess: (data) => {
      ToastFun('Devolvido com Sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const columns: GridColDef[] = [
    {
      field: 'execicioOrcamentario',
      headerName: 'EXERCICIO ORÇAMENTÁRIO',
      flex: 1,
      renderCell: (params) => params.row.planoAplicacao.exercicioOrcamentario,
    },
    {
      field: 'eixoFinanciado',
      headerName: 'EIXO FINANCIADO',
      flex: 1.5,
      renderCell: (params) => params.row.eixo.nome,
    },

    {
      field: 'metaFisica',
      headerName: 'META FÍSICA',
      flex: 3,
      renderCell: (params) => params.row.metaFisica.nome,
    },
    {
      field: 'valorAprovisionado',
      headerName: 'VALOR',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => <> R$ {convertToCentavosCentavos(params.row.valorAprovisionado)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={getColorStatus(params.row.status)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>

          <IconButton
            disabled={
              params.row.totalDespesas === 0 ||
              params.row.status === 'DEVOLVIDO' ||
              params.row.status === 'CONCLUIDO'
            }
            color="warning"
            onClick={() => handleButtonClick(params.row.id, 'RetornarSaldo')}
          >
            <CurrencyExchangeIcon />
          </IconButton>
          <IconButton
            disabled={params.row.totalDespesas > 0}
            color="error"
            onClick={() => handleButtonClick(params.row.id, 'Cancelar')}
          >
            <DoDisturbAltIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };
  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao'),
  });

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack>
        <CustomBreadcrumbs
          heading="Aprovisionamento"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            {
              name: 'Aprovisionamento',
              href: '/planejamento/fundonacional/aprovisionamento',
            },
          ]}
        />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={1.5} xl={1.5} sx={{ my: 2 }}>
            <TextField
              size="small"
              select
              name="exercicioOrcamentario"
              label="Ano"
              fullWidth
              value={planoAplicacaoId}
              onChange={(event) => setPlanoAplicacaoId(event.target.value)}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                },
              }}
            >
              {rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
                <MenuItem
                  key={indexExercicio}
                  value={item.id}
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {item.exercicioOrcamentario}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} xl={3} sx={{ my: 2 }}>
            <TextField
              size="small"
              value={eixoFinanciadoId}
              select
              name="eixoFinanciadoId"
              label="Eixo Financiado"
              fullWidth
              onChange={(event) => setEixoFinanciadoId(event.target.value)}
            >
              {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
                <MenuItem key={indexEixoFinanciado} value={item.id}>
                  {item.eixoNome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2} xl={2} sx={{ my: 2 }}>
            <TextField
              size="small"
              select
              name="instituicaoId"
              value={instituicaoId}
              label="Instituição"
              fullWidth
              onChange={(event) => setInstituicaoId(event.target.value)}
            >
              {rowInstituicoes?.results.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.sigla}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3.5} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                value={search}
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                color="info"
                sx={{ background: '#D4EAF7   ', mx: 1 }}
                onClick={() => {
                  apagarCampos();
                }}
              >
                <CachedIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Aprovisionamento
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
              columns={columns}
              endpoint="aprovisionamento"
              setRefetch={tableRefetch}
              params={parametros}
              setTableRefetch={setTableRefetch}
            />
          </Grid>
        </Grid>
        {isModalOpen && type === 'Cadastro' && (
          <CadastroAprovisionamento
            open={isModalOpen}
            handleClose={handleCloseModal}
            RefetchMetas={setTableRefetch}
          />
        )}
        {isModalOpen && type === 'Visualizar' && id && (
          <AprovisionamentoVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
        )}
        {isModalOpen && type === 'RetornarSaldo' && id && (
          <ModalAcao
            isOpen={isModalOpen}
            nomeAcao="
            o saldo remanescente"
            acao="Devolver"
            id={id}
            mutate={devolverAprov}
            handleClose={handleCloseModal}
          />
        )}
        {isModalOpen && type === 'Cancelar' && id && (
          <ModalAcao
            isOpen={isModalOpen}
            nomeAcao="Aprovisionamento"
            acao="Cancelar"
            id={id}
            mutate={cancelarAprov}
            handleClose={handleCloseModal}
          />
        )}
      </Stack>
    </Box>
  );
}

export const getColorStatus = (status: string) => {
  switch (status) {
    case 'RESERVADO':
    case 'DEVOLVIDO':
    case 'Pendente':
      return 'warning';
    case 'CANCELADO':
      return 'error';
    default:
      return 'success';
  }
};
