import { Box, Alert } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import DrawIcon from '@mui/icons-material/Draw';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface Dados {
  children: JSX.Element;
  title: string;
  backgroundAlert?: string;
  type?: string;
  shadow?: boolean;
  icon?: JSX.Element;
}

export function CustomContainerCad({
  children,
  title,
  backgroundAlert,
  type,
  shadow,
  icon,
}: Dados) {
  return (
    <Box
      sx={{
        border: '1px solid #FBFBFB',
        borderRadius: '10px',
        background: 'white',
        p: 2,
        boxShadow: '1px 1px 5px 1px #c4c4c4',
      }}
    >
      <Alert
        sx={{ mt: -5, background: '#019362 ', color: 'white', fontWeight: '700' }}
        icon={<DrawIcon sx={{ color: 'white' }} />}
      >
        {title} - Cadastro{' '}
      </Alert>
      <Box sx={{ p: 2, my: 2 }}>{children}</Box>
    </Box>
  );
}

export function CustomContainerAtualizar({
  children,
  title,
  backgroundAlert,
  type,
  shadow,
  icon,
}: Dados) {
  return (
    <Box
      sx={{
        border: '1px solid #FBFBFB',
        borderRadius: '10px',
        background: 'white',
      }}
    >
      <Alert
        sx={{ background: '#019362 ', color: 'white', fontWeight: '700' }}
        icon={<DrawIcon sx={{ color: 'white' }} />}
      >
        {title} - Atualizar
      </Alert>
      <Box>{children}</Box>
    </Box>
  );
}
export function CustomContainerList({
  children,
  title,
  backgroundAlert,
  type,
  shadow,
  icon,
}: Dados) {
  return (
    <Box
      sx={{
        borderRadius: '10px',
        background: 'white',
        my: 4,
      }}
    >
      <Alert
        sx={{
          mt: -5,
          background: `${type === 'detalhes' ? '#4B96DD' : '#FFB146'}`,
          color: 'white',

          fontSize: '15px',
          fontWeight: '700',
        }}
        icon={
          icon ||
          (type === 'detalhes' ? (
            <VisibilityIcon sx={{ color: 'white' }} />
          ) : (
            <ListIcon sx={{ color: 'white' }} />
          ))
        }
      >
        {title}
      </Alert>
      <Box sx={{ py: 2 }}>{children}</Box>
    </Box>
  );
}
