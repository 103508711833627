import Box from '@mui/material/Box';
import { Button, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { getTipoDespesas } from '../../../services/Admin/get';
import { Cadastro } from './Cadastro';
import { tratandoData } from '../../../config/utils';

export function Despesas() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const { data: rows, refetch } = useQuery({
    queryKey: ['getTipoDespesas', skip, take],
    queryFn: getTipoDespesas,
  });

  const handleClose = () => {
    setIsModalOpen(false);
    refetch();
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const column: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
    },
    {
      field: 'criadoEm',
      headerName: 'Criando Em',
      flex: 1,
      renderCell: (param) => <>{tratandoData(param.row.criadoEm)}</>,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Despesas"
          links={[
            { name: 'Administrador', href: '/administrador' },
            { name: 'Despesas', href: '/administrador/desespesas' },
          ]}
        />

        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => handleButtonClick()}
            >
              Tipo de Despesa
            </Button>
          </Grid2>

          <Grid2 xs={12} mt={2}>
            {/* <CustomContainerList title="Tipos de Desespesas">
              {rows && (
                <TableContainer
                  columns={column}
                  rows={rows.results}
                  rowCount={rows.total}
                  onPaginationModelChange={setPaginationModel}
                  paginationModel={paginationModel}
                />
              )}
            </CustomContainerList> */}
          </Grid2>
        </Grid2>

        {isModalOpen && <Cadastro open={isModalOpen} handleClose={handleClose} refetch={refetch} />}
      </Stack>
    </Box>
  );
}
