import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import { DataLogin } from 'src/pages/Login/types';
import { IUnidadeData } from 'src/pages/Admin/Unidades/Formulario/type';
import { Api } from '../Api';
import Endpoints from '../endpoints';

export async function AuthLogin(dadosLogin: DataLogin) {
  const resultLogin = await Api.post(Endpoints.auth, dadosLogin);

  return resultLogin.data;
}

export async function postCadastrarUsuario(dados: IUsuarioData) {
  const result = await Api.post(Endpoints.usuario, dados);

  return result;
}

export async function postCadastrarUnidade(dadosUnidade: IUnidadeData) {
  const result = await Api.post(Endpoints.unidade, dadosUnidade);

  return result;
}

export async function postCadastrarTipoDespesa(dadosTipoDespesa: any) {
  const result = await Api.post(Endpoints.tipoDespesa, dadosTipoDespesa);

  return result;
}

export async function postCadastrarGruposPermissoes(grupoPermissoes: any) {
  const result = await Api.post(Endpoints.grupoPermissoes, grupoPermissoes);

  return result;
}
