import { Grid, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { convertToCentavosCentavos, valorMonetario } from 'src/config/utils';
import { serviceGet } from 'src/services';

import { UseFormReturn } from 'react-hook-form';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';

import { DadosVinculadosEmpenho, IEmpenhoDataAPI } from './type';

export default function VerificarEmpenhos({
  methods,
  fetch,
  empenho,
}: {
  methods: UseFormReturn<DadosVinculadosEmpenho>;
  fetch: boolean;
  empenho: IEmpenhoDataAPI | undefined;
}) {
  const [valores, setValores] = useState({
    somaValorEmpenhado: 0,
    somaValorAnulado: 0,
    somaValorComplemento: 0,
    somaValorPago: 0,
  });
  const numeroEmpenho = methods.watch(`numeroEmpenho`);
  const { data: rowsEmp } = useQuery({
    queryKey: ['getVerificarEmpenhos', numeroEmpenho],
    queryFn: () => serviceGet('verificarEmpenho', {}, numeroEmpenho),
    enabled: !!numeroEmpenho,
    retry: false,
  });
  useEffect(() => {
    if (rowsEmp) {
      setValores({
        somaValorEmpenhado: rowsEmp.reduce((acc, curr) => acc + curr.valorEmpenhado, 0) / 100,
        somaValorAnulado: rowsEmp.reduce((acc, curr) => acc + curr.valorAnulado, 0) / 100,
        somaValorComplemento: rowsEmp.reduce((acc, curr) => acc + curr.valorComplemento, 0) / 100,
        somaValorPago: rowsEmp.reduce((acc, curr) => acc + curr.valorPago, 0) / 100,
      });
    }
  }, [rowsEmp]);
  console.log(valores);
  return (
    <>
      {rowsEmp && (
        <Grid item md={12}>
          <Table sx={{ mt: 5, border: '2px solid #A4C7A4 ' }}>
            <TableHead>
              <TableRow>
                <TableCellHead
                  sx={{
                    background: '#A4C7A4 ',
                    color: '#2F5C39    ',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                  colSpan={12}
                >
                  Saldo do Empenho - Distribuição Parcial
                </TableCellHead>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow sx={{ background: '#FAFAFA' }}>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor de Empenho Não Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor de Pagamento Não Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor de Anulação Não Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor de Complemento Não Utilizado
                </TableCellHead>
              </TableRow>
              <TableRow sx={{ background: '#FAFAFA' }}>
                <TableCellBody align="center" sx={{ fontWeight: 'bold' }}>
                  {valorMonetario(
                    empenho?.valorEmpenhado
                      ? empenho.valorEmpenhado - valores.somaValorEmpenhado
                      : 0
                  )}
                </TableCellBody>
                <TableCellBody align="center" sx={{ fontWeight: 'bold' }}>
                  {valorMonetario(
                    empenho?.totalPago ? empenho.totalPago - valores.somaValorPago : 0
                  )}
                </TableCellBody>
                <TableCellBody align="center" sx={{ fontWeight: 'bold' }}>
                  {valorMonetario(
                    empenho?.totalAnulado ? empenho.totalAnulado - valores.somaValorAnulado : 0
                  )}
                </TableCellBody>
                <TableCellBody align="center" sx={{ fontWeight: 'bold' }}>
                  {valorMonetario(
                    empenho?.totalComplemento
                      ? empenho.totalComplemento - valores.somaValorComplemento
                      : 0
                  )}
                </TableCellBody>
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
      )}
      {rowsEmp && numeroEmpenho && rowsEmp.length > 0 && (
        <Grid item md={12}>
          <Table sx={{ mt: 5, border: '2px solid #FDEBD0' }}>
            <TableHead>
              <TableRow>
                <TableCellHead
                  sx={{
                    background: '#FFF3E0',
                    color: '#5C4B51',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                  colSpan={12}
                >
                  <WarningAmberIcon
                    sx={{ verticalAlign: 'middle', mr: 1, color: 'warning.main' }}
                  />
                  Aviso: Este Empenho já está sendo utilizado em outras despesas.
                </TableCellHead>
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow sx={{ background: '#FAFAFA' }}>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Número Empenho
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor Empenhado - Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor Pago - Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor Anulado - Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Valor Complementar - Utilizado
                </TableCellHead>
                <TableCellHead align="center" sx={{ fontWeight: 'bold' }}>
                  Descrição
                </TableCellHead>
              </TableRow>
            </TableHead>

            {/* Corpo da Tabela */}
            <TableBody>
              {rowsEmp.map((emp) => (
                <TableRow key={emp.id} sx={{ '&:nth-of-type(even)': { background: '#FCFCFC' } }}>
                  <TableCellBody align="center">{numeroEmpenho}</TableCellBody>
                  <TableCellBody align="center">
                    R$ {convertToCentavosCentavos(emp.valorEmpenhado)}
                  </TableCellBody>
                  <TableCellBody align="center">
                    R$ {convertToCentavosCentavos(emp.valorPago)}
                  </TableCellBody>
                  <TableCellBody align="center">
                    R$ {convertToCentavosCentavos(emp.valorAnulado)}
                  </TableCellBody>
                  <TableCellBody align="center">
                    R$ {convertToCentavosCentavos(emp.valorComplemento)}
                  </TableCellBody>
                  <TableCellBody align="center">{emp.descricao}</TableCellBody>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </>
  );
}
