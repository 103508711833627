import { Box, Modal, Grid, IconButton, Alert, MenuItem, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext } from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';

import { serviceGet, servicePost } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import CustomModal from 'src/components/Modal';
import { defaultValuesMetaGeral, IMetaGeralData, schemaMetaGeral } from './type';

export default function CadastroMetaGeral({
  open,
  handleClose,
  RefetchMetas,
}: {
  open: boolean;
  handleClose: any;
  RefetchMetas: (arg: boolean) => void;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaMetaGeral),
    defaultValues: defaultValuesMetaGeral,
  });

  const { watch } = methods;
  const { state } = useContext(AuthContext);
  const planoAplicacaoId = watch('planoAplicacaoId');

  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data: IMetaGeralData) => servicePost('metaGeral', { ...data }),
    onSuccess: (data) => {
      ToastFun('Meta Cadastrada com sucesso', 'success');
      handleClose();
      RefetchMetas(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.usuarioCreateId = state.usuario?.id;
    mutate(data);
  };
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Meta Geral - Cadastrar"
      size="sm"
      icon={<EditNoteIcon color="success" sx={{ fontSize: 30 }} />}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={4}>
                <CustomSelect
                  label="Exercício Orçamentário"
                  name="planoAplicacaoId"
                  options={
                    rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => ({
                      id: item.id,
                      nome: item.exercicioOrcamentario,
                    })) ?? []
                  }
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
                  {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
                    <MenuItem key={indexEixoFinanciado} value={item.id}>
                      {item.eixoNome}
                    </MenuItem>
                  ))}
                </TextFieldRG>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldRG type="text" name="nome" label="Meta geral" multiline />
              </Grid>

              <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </FormsProvider>
        </Grid>
      </Grid>
    </CustomModal>
  );
}
