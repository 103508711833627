import { Box, Stack, Typography } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { NODE_ENV } from 'src/config/constants';

export default function Dashboard() {
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ fontSize: '1.5rem', color: '#6D6C6C' }}>
          Dashboard
        </Typography>
        <Box>
          {NODE_ENV === 'production' ? (
            <iframe
              title="DPLAGE"
              width="100%"
              height="900px"
              style={{ border: 'none' }}
              src="https://app.powerbi.com/view?r=eyJrIjoiNmUwNjc4MGYtNGFhNi00ZDc3LTg1MGEtMWM5ODhhYzQ2MmU5IiwidCI6IjdmZDRjZTRmLWRjN2UtNDZhMS04ODlhLTg4OWI1ZWFhYjVmZiJ9"
            />
          ) : (
            <iframe
              title="DPLAGE"
              width="100%"
              height="900px"
              style={{ border: 'none' }}
              src="https://app.powerbi.com/view?r=eyJrIjoiNmUwNjc4MGYtNGFhNi00ZDc3LTg1MGEtMWM5ODhhYzQ2MmU5IiwidCI6IjdmZDRjZTRmLWRjN2UtNDZhMS04ODlhLTg4OWI1ZWFhYjVmZiJ9"
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
