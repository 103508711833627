import { Grid, Alert, styled, TableCell, Box, Chip, Typography } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { serviceGet } from 'src/services';
import CustomModal from 'src/components/Modal';
import { convertToCentavos, convertToCentavosCentavos, valorMonetario } from 'src/config/utils';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function AprovisionamentoVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: () => void;
}) {
  const { data: rows, isPending } = useQuery({
    queryKey: ['getAprovisionamento', id],
    queryFn: () => serviceGet('aprovisionamentoId', {}, id),
  });
  return (
    <div>
      <CustomModal
        open={open}
        onClose={handleClose}
        title="Aprovisionamento - Detalhes"
        icon={<VisibilityIcon color="primary" sx={{ fontSize: 25 }} />}
      >
        <Grid container sx={{ mb: 3 }}>
          <Grid md={12} xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Exercício Orçamentário</TableCellHead>
                    <TableCellHead colSpan={rows?.valorDevolvido ?? 0 ? 4 : 3}>
                      Eixo Financiado
                    </TableCellHead>
                    <TableCellHead align="center">Situação</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{rows?.planoAplicacao?.exercicioOrcamentario}</TableCellBody>
                    <TableCellBody colSpan={rows?.valorDevolvido ?? 0 ? 4 : 3}>
                      {rows?.eixo?.nome}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        label={rows?.status}
                        sx={{ color: 'white' }}
                        color={rows?.status ? getColorStatus(rows.status) : 'default'}
                      />
                    </TableCellBody>
                  </TableRow>
                </TableBody>

                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Meta Fisica</TableCellHead>
                    <TableCellHead colSpan={2}> Instituição</TableCellHead>
                    <TableCellHead>Natureza</TableCellHead>

                    <TableCellHead colSpan={2} align="center">
                      Documento SEI
                    </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{rows?.metaFisica?.nome}</TableCellBody>
                    <TableCellBody colSpan={2}>{rows?.instituicao?.sigla ?? ''}</TableCellBody>
                    <TableCellBody>{rows?.metaFisica?.natureza}</TableCellBody>

                    <TableCellBody align="center" colSpan={2}>
                      {rows?.documentoSei}
                    </TableCellBody>
                  </TableRow>
                </TableBody>
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Descrição </TableCellHead>
                    <TableCellHead align="center"> Aprovisionado </TableCellHead>
                    <TableCellHead align="center"> Empenhado </TableCellHead>
                    <TableCellHead align="center"> Executado </TableCellHead>
                    {(rows?.valorDevolvido ?? 0) > 0 && (
                      <TableCellHead align="center"> Devolvido</TableCellHead>
                    )}
                    <TableCellHead align="center">Saldo </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{rows?.descricao}</TableCellBody>
                    <TableCellBody align="center">
                      R${convertToCentavosCentavos(rows?.valorAprovisionado ?? 0)}
                    </TableCellBody>
                    <TableCellBody align="center">
                      R${convertToCentavosCentavos(rows?.valorEmpenhado ?? 0)}
                    </TableCellBody>
                    <TableCellBody align="center">
                      R${convertToCentavosCentavos(rows?.valorUtilizado ?? 0)}
                    </TableCellBody>
                    {(rows?.valorDevolvido ?? 0) > 0 && (
                      <TableCellBody align="center">
                        R$
                        {convertToCentavosCentavos(rows?.valorDevolvido ?? 0)}
                      </TableCellBody>
                    )}
                    <TableCellBody align="center">
                      R$
                      {rows?.status === 'DEVOLVIDO' || rows?.status === 'CANCELADO'
                        ? 0
                        : convertToCentavosCentavos(
                            (rows?.valorAprovisionado ?? 0) -
                              (rows?.valorEmpenhado ?? 0) -
                              (rows?.valorUtilizado ?? 0) -
                              (rows?.valorDevolvido ?? 0)
                          )}
                    </TableCellBody>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {(rows?.despesas?.length ?? 0) > 0 && (
            <Grid item md={12}>
              <Grid item md={10} xs={10} sm={10}>
                <Box display="flex" alignItems="center" sx={{ px: 2, pt: 2 }}>
                  <Typography variant="h5" sx={{ color: '#6D6C6C', mt: 1 }}>
                    <CurrencyExchangeIcon color="success" />
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#6D6C6C', ml: 1 }}>
                    Despesas
                  </Typography>
                </Box>
              </Grid>
              <Table>
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Empenho </TableCellHead>
                    <TableCellHead align="center">Valor Empenhado</TableCellHead>
                    <TableCellHead align="center">Valor Complementar </TableCellHead>
                    <TableCellHead align="center">Valor Anulado </TableCellHead>
                    <TableCellHead align="center">Valor Executado</TableCellHead>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows?.despesas?.map((despesa) => (
                    <TableRow key={despesa.id}>
                      <TableCellBody>{despesa?.numeroEmpenho}</TableCellBody>
                      <TableCellBody align="center">
                        R${convertToCentavosCentavos(despesa?.valorEmpenhado ?? 0)}
                      </TableCellBody>
                      <TableCellBody align="center">
                        R${convertToCentavosCentavos(despesa?.valorComplemento ?? 0)}
                      </TableCellBody>
                      <TableCellBody align="center">
                        R${convertToCentavosCentavos(despesa?.valorAnulado ?? 0)}
                      </TableCellBody>
                      <TableCellBody align="center">
                        R$ {convertToCentavosCentavos(despesa.valorPago)}
                      </TableCellBody>{' '}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </CustomModal>
    </div>
  );
}
export const getColorStatus = (status: string) => {
  switch (status) {
    case 'RESERVADO':
    case 'DEVOLVIDO':
      return 'warning';
    case 'CANCELADO':
      return 'error';
    default:
      return 'success';
  }
};
