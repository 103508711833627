import { Controller, useFormContext } from 'react-hook-form';
import {
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useState } from 'react';

interface SelectData {
  label: string;
  name: string;
  inputRef?: any;
  marginSub?: number;
  options: { id: string; nome: string; disabled?: boolean }[];
  [key: string]: any;
}

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomSelect({
  label,
  name,
  inputRef,
  options,
  marginSub,
  ...rest
}: SelectData) {
  const { control } = useFormContext();
  const [optionsCheck, setOptionsCheck] = useState<string[]>([]);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <FormControl fullWidth sx={{ my: 2 }} error={!!error}>
          <InputLabel>{label}</InputLabel>
          <Select
            value={value || ''}
            onChange={onChange}
            label={label}
            sx={{
              height: 57,
              overflow: 'hidden',
            }}
            error={!!error}
            renderValue={(selected) =>
              selected ? options.find((option) => option.id === selected)?.nome : ''
            }
            MenuProps={MenuProps}
            {...rest}
          >
            {options?.map((option) => (
              <MenuItem key={option.id} value={option.id} disabled={option.disabled}>
                <ListItemText
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                  primary={option.nome}
                />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
