/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { Grid, Typography, Button, IconButton, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { getGrupoPermissoes } from 'src/services/Admin/get';
import { useQuery } from '@tanstack/react-query';

type FormsUsuario = {
  setValue: UseFormSetValue<FieldValues>;
  unregister: any;
};

export default function FormularioUsuario({ setValue, unregister }: FormsUsuario) {
  const permissoes = useWatch({ name: 'permissoes' }) || [];
  const [arrayPermissao, setArrayPermissao] = useState<number[]>([0]);
  useEffect(() => {
    if (permissoes.length > 0) setArrayPermissao(permissoes.map((_: any, index: any) => index));
  }, [permissoes]);
  const { data: rowsGrupos } = useQuery({
    queryKey: ['getGrupos'],
    queryFn: getGrupoPermissoes,
  });

  const dados = rowsGrupos?.results.reduce((acc: any, item: any) => {
    const [cargo, modulo] = item.nome.split('_');
    if (!acc[cargo]) acc[cargo] = [];
    if (modulo) acc[cargo].push(modulo);
    return acc;
  }, {} as Record<string, string[]>);

  const funcoes = Object.keys(dados || {});

  const novaPermissao = () => {
    setArrayPermissao((prevMetas) => {
      const ultimoValor = prevMetas[prevMetas.length - 1] ?? 0;
      return [...prevMetas, ultimoValor + 1];
    });
  };

  const excluirPermissao = (index: number) => {
    if (arrayPermissao.length > 1) {
      const permissoesAtualizadas = permissoes?.filter((_: any, i: number) => i !== index) || [];
      setArrayPermissao((prev) => prev.filter((_, i) => i !== index));
      unregister(`permissoes.${index}`);
    }
  };

  return (
    <Grid container spacing={2} sx={{ color: '#555555' }}>
      {/* Dados do usuário */}
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2">Email</Typography>
        <TextFieldRG type="text" name="email" label="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2">Nome</Typography>
        <TextFieldRG type="text" name="nome" label="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2">CPF</Typography>
        <TextFieldRGMaks label="" name="cpf" mask="___.___.___-__" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2">Senha</Typography>
        <TextFieldRG type="password" name="senha" label="" />
      </Grid>

      {/* Permissões */}
      <Grid item xs={12} md={10}>
        <Typography variant="subtitle1">Permissões</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novaPermissao}>
          Nova Permissão
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Divider />
      </Grid>

      {rowsGrupos &&
        arrayPermissao.map((item, index) => (
          <Grid container spacing={2} key={item} sx={{ p: 2 }}>
            <Grid item xs={12} md={5.5}>
              <Typography variant="subtitle2">Função</Typography>
              <TextFieldRG
                select
                name={`permissoes[${item}].funcao`}
                label=""
                onChange={(e: any) => {
                  const funcaoSelecionada = e.target.value;
                  setValue(`permissoes[${item}].funcao`, funcaoSelecionada);
                  setValue(`permissoes[${item}].modulo`, '');
                }}
              >
                {funcoes.map((cargo) => (
                  <MenuItem key={cargo} value={cargo}>
                    {cargo.charAt(0).toUpperCase() + cargo.slice(1)}
                  </MenuItem>
                ))}
              </TextFieldRG>
            </Grid>

            <Grid item xs={12} md={5.5}>
              <Typography variant="subtitle2">Módulo</Typography>
              {permissoes[item]?.funcao && permissoes[item].funcao !== 'admin' && (
                <TextFieldRG select name={`permissoes[${item}].modulo`} label="">
                  {dados[permissoes[item]?.funcao]?.map((mod: any) => (
                    <MenuItem key={mod} value={mod}>
                      {mod.charAt(0).toUpperCase() + mod.slice(1)}
                    </MenuItem>
                  ))}
                </TextFieldRG>
              )}
            </Grid>

            <Grid item xs={12} md={1} sx={{ textAlign: 'right', mt: 5.5 }}>
              <IconButton aria-label="delete" color="error" onClick={() => excluirPermissao(item)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
