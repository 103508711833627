import { useContext, useState } from 'react';
import { Box, Grid, IconButton, Modal, Alert, styled, Button } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { tratandoData, convertToCentavosCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthContext } from 'src/Context/ContextUser';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import { serviceGet, servicePatch } from 'src/services';
import CustomModal from 'src/components/Modal';
import { IRemanejamentoData } from '../../Financeiro/Remanejamento/Formulario/type';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function RemanejamentoVisualizar({
  open,
  RefetchRemanejamentos,
  handleClose,
  id,
}: {
  open: boolean;
  handleClose: any;
  id: any;
  RefetchRemanejamentos: any;
}) {
  const { state } = useContext(AuthContext);

  const dadosAutorizacao = {
    id,
    usuarioAuthorizationId: state.usuario?.id,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = (idRendimento: string) => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: (data: IRemanejamentoData) =>
      servicePatch('remanejamentoAutorizacao', { ...data }, id),
    onSuccess: (data) => {
      RefetchRemanejamentos(true);
      handleClose();
      ToastFun('Remanejamento autorizado com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const { data: rows, isPending } = useQuery({
    queryKey: ['getRemanejamento', id],
    queryFn: () => serviceGet('remanejamentoId', {}, id),
  });

  return (
    <div>
      {rows && (
        <CustomModal
          open={open}
          onClose={handleClose}
          title="Autorização - Remanejamento"
          icon={<TaskAltIcon color="success" sx={{ fontSize: 25 }} />}
        >
          <Grid container justifyContent="right" spacing={1} pb={3}>
            <Grid md={12} xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                  <TableHead sx={{ background: '#f5f5f5' }}>
                    <TableRow>
                      <TableCellHead>Execício Orçamentário</TableCellHead>
                      <TableCellHead colSpan={4}>Eixo Financiado</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCellBody>{rows?.planoAplicacao?.exercicioOrcamentario}</TableCellBody>
                      <TableCellBody colSpan={4}>{rows?.eixoFinanciado?.eixoNome}</TableCellBody>
                    </TableRow>

                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead colSpan={2}>Meta Física - Retirada</TableCellHead>
                      <TableCellHead colSpan={2}>Meta Física - Recebida</TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellBody colSpan={2}>
                        {rows.metaFisicaRetirada?.nome}
                        <Box sx={{ my: 2 }}>
                          <span style={{ fontWeight: '600' }}>Instituição</span> -{' '}
                          {rows.metaInstituicaoRetirada?.sigla}
                        </Box>
                      </TableCellBody>
                      <TableCellBody colSpan={2}>
                        {rows.metaFisicaRecebida?.nome}{' '}
                        <Box sx={{ my: 2 }}>
                          <span style={{ fontWeight: '600' }}>Instituição</span> -{' '}
                          {rows.metaInstituicaoRecebida?.sigla}
                        </Box>
                      </TableCellBody>
                    </TableRow>
                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead align="center">Valor Remanejado</TableCellHead>
                      <TableCellHead align="center">Natureza</TableCellHead>
                      <TableCellHead align="center">Criado Em </TableCellHead>
                      <TableCellHead align="center">Criado Por </TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellBody align="center">
                        R$ {convertToCentavosCentavos(rows.valorRemanejado)}
                      </TableCellBody>
                      <TableCellBody align="center">{rows.natureza}</TableCellBody>
                      <TableCellBody align="center">{tratandoData(rows.criadoEm)}</TableCellBody>
                      <TableCellBody align="center"> {rows.usuarioCreate?.nome}</TableCellBody>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {dadosAutorizacao.id && (
              <Grid item lg={1.5} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  startIcon={<TaskAltIcon />}
                  onClick={() => handleButtonClick(id)}
                >
                  Autorizar
                </Button>
              </Grid>
            )}
            {isModalOpen && (
              <ModalAutorizar
                isOpen={isModalOpen}
                nomeAcao="remanejamento"
                id={id}
                mutate={mutate}
                handleClose={handleCloseModal}
              />
            )}
          </Grid>
        </CustomModal>
      )}
    </div>
  );
}
