import { useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { useQuery } from '@tanstack/react-query';
import { getInstituicoes } from 'src/services/Admin/get';
import { serviceGet } from 'src/services';
import { Box, Button, Divider, Grid, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import RelatorioEmpenhos from './RelatorioEmpenhos';
import RelatorioFinanceiro from './RelatorioFinanceiro';
import RelatorioGeral from './RelatorioGeral';

export default function Relatorios() {
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [tipoRelatorio, setTipoRelatorio] = useState<string>('');
  const [instituicao, setInstituicao] = useState<string>('');
  const [natureza, setNatureza] = useState<string>('');
  const [anoEmpenho, setAnoEmpenho] = useState<number>();
  const [dataInicial, setDataInicial] = useState<string>('');
  const [dataFinal, setDataFinal] = useState<string>('');
  const [search, setSearch] = useState(false);

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  // BUSCAR EIXOS CADASTRADOS
  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  // BUSCAR DADOS DO PLANO
  const include = ['user'];
  const { data: rowsPlano } = useQuery({
    queryKey: ['getPlanoAplicacao', planoAplicacaoId, include],
    queryFn: () => serviceGet('planoAplicacaoId', { include }, planoAplicacaoId),
    enabled: !!search,
  });

  // BUSCAR METAS DO EIXO FINANCIADO
  const includeEixo = ['metasGerais', 'metasFisicas', 'metasInstituicoes'];
  const dataParams = { include: includeEixo, instituicao, natureza };
  const { data: rowsEixoFinanciadoMeta } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: () => serviceGet('eixoFinanciadoMetas', { ...dataParams }, eixoFinanciadoId),
    enabled: !!search,
  });

  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao'),
  });
  const dadosBusca = {
    planoAplicacaoId,
    anoEmpenho,
    dataInicial,
    instituicao,
    tipoRelatorio,
    dataFinal,
    natureza,
    eixoFinanciadoId,
  };
  const { data: rowsDespesasEmpenhos } = useQuery({
    queryKey: ['getInstituicoes', dadosBusca],
    queryFn: () => serviceGet('despesaRelatorioEmpenhos', { ...dadosBusca }),
    enabled: !!(tipoRelatorio === 'empenhos') && !!search,
  });
  const { data: rowsDespesasPagamentos } = useQuery({
    queryKey: ['getInstituicoes', dadosBusca],
    queryFn: () => serviceGet('despesaRelatorioPagamentos', { ...dadosBusca }),
    enabled: !!(tipoRelatorio === 'pagamentos') && !!search,
  });
  const dadosPagamentos = {
    anoEmpenho,
    dataInicio: dataInicial,
    dataFim: dataFinal,
    empenhos: rowsDespesasPagamentos?.empenhos,
    anos: rowsDespesasPagamentos?.anos,
  };
  //   const { data: rowsPagamentos } = useQuery({
  //     queryKey: ['getInstituicoes', dadosPagamentos],
  //     queryFn: () => getPagamentosEmpenhos(dadosPagamentos),
  //     enabled: !!(tipoRelatorio === 'pagamentos') && !!search && !!rowsDespesas,
  //   });
  useEffect(() => {
    if (rowsPlano && rowsEixoFinanciadoMeta && search) {
      setSearch(false);
    }
  }, [rowsPlano, rowsEixoFinanciadoMeta, search]);

  const buscar = () => {
    setSearch(true);
  };
  const handlePrint = () => {
    const printContents = document.getElementById('imprimir')?.innerHTML;

    if (!printContents) {
      console.error('Elemento "imprimir" não encontrado!');
      return;
    }

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      console.error('Não foi possível abrir a janela de impressão.');
      return;
    }
    printWindow.document.write(`
      <html>
        <head>
          <title>Relatório Financeiro</title>
          <style>
            @media print {
                *{
                    font-family: Arial !important    
                }
                body {
                    -webkit-print-color-adjust: exact; /* Para habilitar cores */
                    print-color-adjust: exact;
                    text-transform: uppercase;
                }
                @page {
                    size: A4 landscape;
                    margin: 10mm;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid black;
                    padding: 5px;
                    text-align: center;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
                .bg-color {
                    background-color: #FEE69C !important;
                }
                .text-white {
                    color: white;
                }
  
                .title-cabecalho{
                    font-size:30px ;
                    color: #082765;  
                }
                .title-cabecalho-geral{
                    font-size:20px ;
                    color: #082765;  
                    font-weight: 700

                }
                .sub-title{
                    color:white;
                    background: #082765;
                    font-weight: 700

                }
                .sub-title-geral{
                    color:#082765;
                    font-size: 10px;
                    font-weight: 800
                }
                    .text-aling{
                    text-align: left;}
                #fundo{
                    background: #FEE69C;
                    
                }
            }          
        </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  };
  useEffect(() => {
    if (tipoRelatorio !== 'financeiro') {
      setInstituicao('');
      setNatureza('');
    }
  }, [tipoRelatorio]);
  const LimparFiltros = () => {
    setPlanoAplicacaoId('');
    setEixoFinanciadoId('');
    setTipoRelatorio('');
    setInstituicao('');
    setNatureza('');
    setSearch(false);
  };
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Relatórios"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Relatórios', href: '/planejamento/fundonacional/planodeaplicacao' },
          ]}
        />
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={1.5}>
              <TextField
                select
                name="planoAplicacaoId"
                value={planoAplicacaoId}
                label="Exercício Orçamentário"
                fullWidth
                size="small"
                error={search && planoAplicacaoId === ''}
                helperText={search && planoAplicacaoId === '' ? 'Este campo é obrigatório.' : ''}
                onChange={(e) => setPlanoAplicacaoId(e.target.value)}
                sx={{ my: 2 }}
              >
                {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
                  <MenuItem key={indexExercicio} value={item.id}>
                    {item.exercicioOrcamentario}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* EIXOS FINANCIADOS */}
            <Grid item xs={12} md={3.5}>
              <Box display="grid">
                <TextField
                  select
                  size="small"
                  value={eixoFinanciadoId}
                  name="eixoFinanciadoId"
                  label="Eixo/Área Temática"
                  error={search && eixoFinanciadoId === ''}
                  helperText={search && eixoFinanciadoId === '' ? 'Este campo é obrigatório.' : ''}
                  onChange={(e) => setEixoFinanciadoId(e.target.value)}
                  sx={{
                    mt: 2,
                  }}
                >
                  {eixosFinanciados?.results?.map((item: any, indexEixoFinanciado: number) => (
                    <MenuItem key={indexEixoFinanciado} value={item.id}>
                      {item.eixoNome}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            {/* TIPO DE RELATORIO */}
            <Grid item xs={12} md={2}>
              <Box display="grid">
                <TextField
                  select
                  size="small"
                  name="tipoRelatorio"
                  label="Tipo de Relatório"
                  error={search && tipoRelatorio === ''}
                  value={tipoRelatorio}
                  helperText={search && tipoRelatorio === '' ? 'Este campo é obrigatório.' : ''}
                  onChange={(e) => setTipoRelatorio(e.target.value)}
                  sx={{
                    mt: 2,
                  }}
                >
                  <MenuItem value="geral">Geral</MenuItem>
                  <MenuItem value="financeiro">Financeiro</MenuItem>
                  {/* <MenuItem value="pagamentos">Pagamentos</MenuItem> */}
                  <MenuItem value="empenhos">Empenhos</MenuItem>
                </TextField>
              </Box>
            </Grid>

            <Grid item md={1.5} xs={12}>
              <TextField
                size="small"
                select
                fullWidth
                disabled={tipoRelatorio === 'rendimentos'}
                name="instituicaoBeneficiada"
                label="Instituição Beneficiada"
                value={instituicao}
                onChange={(e) => setInstituicao(e.target.value)}
                sx={{
                  mt: 2,
                }}
              >
                {rowInstituicoes?.results.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.sigla}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={1.5} xs={12}>
              <TextField
                size="small"
                select
                fullWidth
                name="natureza"
                label="Natureza"
                value={natureza}
                onChange={(e) => setNatureza(e.target.value)}
                sx={{
                  mt: 2,
                }}
              >
                <MenuItem value="investimento">Investimento</MenuItem>
                <MenuItem value="custeio">Custeio</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={2} my={2}>
              <IconButton
                color="info"
                sx={{ background: '#D4EAF7   ', mr: 5, ml: 2 }}
                onClick={() => {
                  LimparFiltros();
                }}
              >
                <CachedIcon />
              </IconButton>

              <Button onClick={buscar} color="success" variant="soft" startIcon={<SearchIcon />}>
                Gerar Relatório
              </Button>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>

            {tipoRelatorio !== 'geral' &&
              tipoRelatorio !== 'financeiro' &&
              tipoRelatorio !== '' && (
                <>
                  <Grid item xs={12} md={2}>
                    <TextField
                      label="Data Inicial"
                      type="date"
                      value={dataInicial}
                      onChange={(e) => setDataInicial(e.target.value)}
                      fullWidth
                      sx={{ my: 2 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      label="Data Final"
                      type="date"
                      value={dataFinal}
                      onChange={(e) => setDataFinal(e.target.value)}
                      fullWidth
                      sx={{ my: 2 }}
                    />
                  </Grid>
                </>
              )}

            {(rowsEixoFinanciadoMeta || rowsDespesasEmpenhos || rowsDespesasPagamentos) &&
              tipoRelatorio && (
                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
                  <Button
                    onClick={handlePrint}
                    color="error"
                    variant="soft"
                    startIcon={<PrintIcon />}
                  >
                    Imprimir Relatório
                  </Button>
                </Grid>
              )}
            {/* {rowsPlano && rowsEixoFinanciadoMeta && tipoRelatorio === 'pagamentos' && (
             <RelatorioPagamentos
               id="imprimir"
               rowsPlano={rowsPlano}
               rowsPagamentos={rowsPagamentos}
               rowsEixoFinanciadoMeta={rowsEixoFinanciadoMeta}
             />
           )} */}
            {rowsPlano && rowsEixoFinanciadoMeta && tipoRelatorio === 'geral' && (
              <RelatorioGeral
                id="imprimir"
                rowsPlano={rowsPlano}
                rowsEixoFinanciadoMeta={rowsEixoFinanciadoMeta}
              />
            )}
            {rowsPlano && rowsEixoFinanciadoMeta && tipoRelatorio === 'financeiro' && (
              <RelatorioFinanceiro
                id="imprimir"
                rowsPlano={rowsPlano}
                rowsEixoFinanciadoMeta={rowsEixoFinanciadoMeta}
              />
            )}

            {rowsPlano &&
              rowsEixoFinanciadoMeta &&
              rowsDespesasEmpenhos &&
              tipoRelatorio === 'empenhos' && (
                <RelatorioEmpenhos
                  id="imprimir"
                  rowsPlano={rowsPlano}
                  rowsEixoFinanciadoMeta={rowsEixoFinanciadoMeta}
                  rowEmpenhos={rowsDespesasEmpenhos}
                />
              )}
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
}
