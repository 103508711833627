import { Alert, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useEffect } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { AuthContext } from 'src/Context/ContextUser';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { servicePost } from 'src/services';
import CustomModal from 'src/components/Modal';
import FormularioRendimento from '../Formulario/Rendimento';
import { IRendimentoSolicitacaoData, schemaRendimento } from '../Formulario/type';

export default function CadastrarRendimento({
  RefetchRendimentos,
  open,
  handleClose,
  planoAplicacao,
}: {
  RefetchRendimentos: (arg0: boolean) => void;
  open: boolean;
  handleClose: () => void;
  planoAplicacao: string;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaRendimento),
  });
  const { setValue } = methods;
  const { state } = useContext(AuthContext);

  useEffect(() => {
    if (planoAplicacao) {
      setValue('planoAplicacaoId', planoAplicacao);
    }
  }, [planoAplicacao, setValue]);

  const { mutate, isPending } = useMutation({
    mutationFn: (data: IRendimentoSolicitacaoData) => servicePost('rendimento', { ...data }),
    onSuccess: (data) => {
      ToastFun('Rendimento cadastrado com sucesso!', 'success');
      handleClose();
      RefetchRendimentos(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.usuarioCreatedId = state.usuario?.id;
    if (data.valor < 0) {
      ToastFun('O valor Não poder ser negativo', 'error');
    } else {
      mutate(data);
    }
  };
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Rendimentos - Cadastrar"
      size="sm"
      icon={<EditNoteIcon color="success" sx={{ fontSize: 30 }} />}
    >
      <Box sx={{ my: 1 }}>
        <FormsProvider methods={methods} handleSubmit={onSubmit}>
          <FormularioRendimento setValue={setValue} />
        </FormsProvider>
      </Box>
    </CustomModal>
  );
}
