import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useMutation, useQuery } from '@tanstack/react-query';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ErroResponse } from 'src/pages/types';
import { ToastFun } from 'src/config/functions';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { tratandoData, convertToCentavosCentavos } from 'src/config/utils';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useState } from 'react';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import { serviceGet, servicePatch } from 'src/services';
import { IPlanoAplicacaoData } from '../../PlanoAplicacao/Formulario/type';

const TypographyTitulo = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TypographyBody = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  textAlign: 'center',
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  textAlign: 'center',
  color: '#555555',
  textTransform: 'uppercase',
}));
export default function PlanoAplicacaoVisualizarAutorizacao() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const nav = useNavigate();
  const { id } = useParams();
  const { state } = useContext(AuthContext);

  const include = ['eixoFinanciado', 'user'];
  const {
    data: rowsPlano,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getPlanoAplicacao', id, include],
    queryFn: () => serviceGet('planoAplicacaoId', { include }, id),
  });
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: (data: IPlanoAplicacaoData) =>
      servicePatch('planoAplicacaoAutorizacao', { ...data }, id),
    onSuccess: (data) => {
      refetch();
      ToastFun('Plano Autorizado com sucesso', 'success');
      nav('/planejamento/fundonacional/autorizacao');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  if (isPending || !rowsPlano) return <ModalCarregamento isOpen={isPending} />;

  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <CustomBreadcrumbs
        heading="Plano de Aplicação - Autorizar"
        links={[
          { name: 'Planejamento', href: '/planejamento' },
          { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
          { name: 'Autorização', href: '/planejamento/fundonacional/autorizacao' },
          { name: 'Plano de Aplicação', href: '#' },
        ]}
      />

      <Stack
        spacing={3}
        sx={{
          mt: '2rem',
        }}
      >
        <>
          <Box
            sx={{
              display: {
                md: 'block',
                sm: 'none',
                xs: 'none',
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ background: '#f5f5f5' }}>
                  <TableCellHead>Exercício Orçamentário</TableCellHead>
                  <TableCellHead>Prazo para Execução</TableCellHead>
                  <TableCellHead>Situação</TableCellHead>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellBody>{rowsPlano.exercicioOrcamentario}</TableCellBody>
                  <TableCellBody>{tratandoData(rowsPlano.prazoExecucao)}</TableCellBody>
                  <TableCellBody>
                    <Chip
                      label={rowsPlano.status}
                      sx={{ color: 'white' }}
                      color={rowsPlano.status === 'Pendente' ? 'warning' : 'success'}
                    />
                  </TableCellBody>
                </TableRow>
              </TableBody>
            </Table>
            <Grid container>
              <Grid md={4} xs={12} my={1}>
                <TypographyTitulo>Eixo Financiado</TypographyTitulo>
              </Grid>
              <Grid md={2} xs={12} my={1} sx={{ textAlign: 'center' }}>
                <TypographyTitulo>Conta Investimento</TypographyTitulo>
              </Grid>
              <Grid md={2} xs={12} my={1} sx={{ textAlign: 'center' }}>
                <TypographyTitulo>Valor Investimento</TypographyTitulo>
              </Grid>
              <Grid md={2} xs={12} my={1} sx={{ textAlign: 'center' }}>
                <TypographyTitulo>Conta Custeio</TypographyTitulo>
              </Grid>
              <Grid md={2} xs={12} my={1} sx={{ textAlign: 'center' }}>
                <TypographyTitulo>Valor Custeio</TypographyTitulo>
              </Grid>
            </Grid>
            <Grid container sx={{ '& .MuiButtonBase-root .MuiAccordionSummary-root': { p: -8 } }}>
              {rowsPlano.eixosFinanciados.map((eixo: any, index: number) => (
                <Accordion key={eixo} sx={{ boxShadow: 0 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item md={4} xs={12}>
                      <TypographyBody>
                        <span style={{ fontWeight: '700' }}>{index + 1} - </span>
                        {eixo.eixoNome}
                      </TypographyBody>
                    </Grid>
                    <Grid item md={2.1} xs={12} sx={{ textAlign: 'center', pl: 1.5 }}>
                      <TypographyBody>{eixo.contaInvestimento}</TypographyBody>
                    </Grid>
                    <Grid item md={2.1} xs={12} sx={{ textAlign: 'center', pl: 1.5 }}>
                      <TypographyBody>
                        R$ {convertToCentavosCentavos(eixo.valorInicialInvestimento)}
                      </TypographyBody>
                    </Grid>
                    <Grid item md={2.5} xs={12} sx={{ textAlign: 'center', pr: 4.3 }}>
                      <TypographyBody>{eixo.contaCusteio}</TypographyBody>
                    </Grid>
                    <Grid item md={1.5} xs={12} sx={{ textAlign: 'center' }}>
                      <TypographyBody>
                        R$ {convertToCentavosCentavos(eixo.valorInicialCusteio)}
                      </TypographyBody>
                    </Grid>
                  </AccordionSummary>
                  <Metas idEixo={eixo.id} index={index} />
                </Accordion>
              ))}
            </Grid>

            <Grid container>
              <Grid item lg={12} md={12} sm={12} sx={{ textAlign: 'right', my: 2 }}>
                <Button
                  sx={{ mx: 2 }}
                  href="/planejamento/fundonacional/autorizacao/"
                  variant="contained"
                  color="light"
                  startIcon={<SubdirectoryArrowLeftIcon />}
                >
                  Voltar
                </Button>
                {id && (
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<TaskAltIcon />}
                    onClick={() => handleButtonClick()}
                  >
                    Autorizar
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: {
                md: 'none',
                sm: 'block',
                xs: 'block',
              },
            }}
          >
            <Alert severity="warning">
              É NECESSÁRIO ESTÁ NO COMPUTADOR PARA VISUALIZAR E AUTORIZAR O PLANO!
            </Alert>
            <Button
              variant="contained"
              color="info"
              sx={{ m: 2 }}
              href="/planejamento/fundonacional/autorizacao/"
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              Voltar
            </Button>
          </Box>
        </>
      </Stack>
      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Plano de Aplicação"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}

function Metas({ idEixo, index }: { idEixo: string; index: number }) {
  const include = ['metasGerais', 'metasFisicas', 'metasInstituicoes'];
  const { data: rowsEixoFinanciadoMeta } = useQuery({
    queryKey: ['getPlanoAplicacao', idEixo, include],
    queryFn: () => serviceGet('eixoFinanciadoMetas', { include }, idEixo),
    enabled: !!idEixo,
  });

  return (
    <AccordionDetails>
      <Grid container>
        <Grid md={12} xs={12} my={1} p={2}>
          <TypographyTitulo>Metas Gerais</TypographyTitulo>
          {rowsEixoFinanciadoMeta?.metasGerais?.map((metaGeral: any, indexMeta: number) => (
            <Box key={metaGeral} my={2}>
              <Accordion sx={{ boxShadow: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ fontSize: '13px', textTransform: 'uppercase' }}
                >
                  {index + 1}.{indexMeta + 1}. {metaGeral.nome}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container px={1} spacing={2}>
                    <Grid item md={8}>
                      <TypographyTitulo>Meta Física</TypographyTitulo>
                    </Grid>
                    <Grid item md={2}>
                      <TypographyTitulo>Natureza</TypographyTitulo>
                    </Grid>
                    <Grid item md={2}>
                      <TypographyTitulo>Despesa</TypographyTitulo>
                    </Grid>
                    <Grid item md={12}>
                      {metaGeral.metasFisicas.map((metaFisica: any, indexMetaFisica: number) => (
                        <Accordion key={metaFisica} sx={{ boxShadow: 0, p: 0 }}>
                          <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                            <Grid container>
                              <Grid item md={8.2}>
                                <Box>
                                  <TypographyBody>
                                    <span
                                      style={{
                                        fontWeight: '700',
                                        margin: 5,
                                      }}
                                    >
                                      {index + 1}.{indexMeta + 1}.{indexMetaFisica + 1}
                                    </span>
                                    {metaFisica.nome}
                                  </TypographyBody>
                                </Box>
                              </Grid>
                              <Grid item md={2}>
                                <TypographyBody>{metaFisica.natureza}</TypographyBody>
                              </Grid>
                              <Grid item md={1.8}>
                                <TypographyBody>{metaFisica.nomeDespesa}</TypographyBody>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container px={2} spacing={2}>
                              <Grid item md={8.1}>
                                <TypographyTitulo>Instituição Beneficiada</TypographyTitulo>
                              </Grid>
                              <Grid item md={2}>
                                <TypographyTitulo>Valor Inicial</TypographyTitulo>
                              </Grid>
                              <Grid item md={1.9}>
                                <TypographyTitulo>Unidade</TypographyTitulo>
                              </Grid>

                              {metaFisica.metasInstituicoes.map(
                                (
                                  metaFisicaInstituicao: any,
                                  indexMetaFisicaInstituicao: number
                                ) => (
                                  <>
                                    <Grid item md={8.1}>
                                      <TypographyBody>{metaFisicaInstituicao.nome}</TypographyBody>
                                    </Grid>
                                    <Grid item md={2}>
                                      <TypographyBody>
                                        R$
                                        {convertToCentavosCentavos(
                                          metaFisicaInstituicao.valorInicial
                                        )}
                                      </TypographyBody>
                                    </Grid>
                                    <Grid item md={1.9}>
                                      <TypographyBody>
                                        {metaFisicaInstituicao.unidade?.sigla || 'Não se Aplica'}
                                      </TypographyBody>
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
