import { Alert, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalVerificar from 'src/components/Modal/Verificar';
import CustomModal from 'src/components/Modal';
import { servicePost } from 'src/services';
import DestinacaoRendimentoForms from '../Formulario';
import { schemaIRendimentoAlocacaoData } from '../Formulario/type';
import { IRendimentoSolicitacaoData } from '../../../PlanoAplicacao/Rendimentos/Formulario/type';

export default function CadastrarDestinacaoRendimento({
  RefetchRendimentos,
  open,
  handleClose,
}: {
  RefetchRendimentos: (arg: boolean) => void;
  open: boolean;
  handleClose: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const methods = useForm({ resolver: yupResolver(schemaIRendimentoAlocacaoData) });
  const { setValue, watch } = methods;
  const { state } = useContext(AuthContext);

  //   const { data: rendimentos } = useQuery({
  //     queryKey: ['getVerificarRendimento', watch()],
  //     queryFn: () => servicePost('verificarRendimento', { ...watch() }),
  //     enabled: !!watch(),
  //   });
  const { mutate, isPending } = useMutation({
    mutationFn: (data: IRendimentoSolicitacaoData) =>
      servicePost('rendimentoSolicitacao', { ...data }),
    onSuccess: (data) => {
      ToastFun('Alocação de Rendimento cadastrada com sucesso!', 'success');
      RefetchRendimentos(true);
      handleClose();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.usuarioCreatedId = state.usuario?.id;
    mutate(data);
  };
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Destinar Rendimento - Cadastrar"
      icon={<EditNoteIcon color="success" sx={{ fontSize: 30 }} />}
    >
      <Grid container>
        <Grid item md={12} xs={12}>
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <DestinacaoRendimentoForms setValue={setValue} />
          </FormsProvider>
        </Grid>
      </Grid>
      <ModalVerificar
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dados={methods.getValues()}
        mutate={mutate}
        title="Rendimento"
      />
    </CustomModal>
  );
}
