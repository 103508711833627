import * as yup from 'yup';
import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import { ITipoDespesaData } from 'src/pages/Admin/Despesas/Formulario/type';
import { IInstituicaoData, IUnidadeData } from 'src/pages/Admin/Unidades/Formulario/type';
import { IEixosFinanciadosData, IPlanoAplicacaoData } from '../../PlanoAplicacao/Formulario/type';

type IMetaGeralData = import('../Cadastro/MetaGeral/type').IMetaGeralData;

export const schemaMetasFisicas = yup.object<IMetaFisicaSchemaData>({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  metaGeralId: yup.string().required('MetaGeral é Obrigatório'),
  usuarioCreateId: yup.string(),
  usuarioUpdateId: yup.string(),
  metasFisicas: yup
    .array()
    .of(
      yup.object().shape({
        nome: yup.string().required('Meta Física é Obrigatório'),
        natureza: yup.string().required('Natureza é Obrigatório'),
        tipoDespesa: yup.string().required('Despesa é Obrigatório'),
        metasInstituicoes: yup.array().of(
          yup.object().shape({
            instituicaoId: yup.string().required('Intituição é Obrigatório'),
            valorInicial: yup
              .number()
              .positive('Valor não pode ser negativo')
              .min(0, 'Valor não pode ser negativo')
              .transform((value: number) => value * 100)
              .required('Valor é Obrigatório'),
            unidadeId: yup.string(),
            saldoReal: yup
              .number()
              .positive('Valor não pode ser negativo')
              .min(0, 'Valor não pode ser negativo')
              .transform((value: number) => value * 100),
            saldoEstimativo: yup
              .number()
              .positive('Valor não pode ser negativo')
              .min(0, 'Valor não pode ser negativo')

              .transform((value: number) => value * 100),
          })
        ),
      })
    )
    .required(),
});

export interface IMetaFisicaSchemaData {
  planoAplicacaoId?: string;
  eixoFinanciadoId?: string;
  metaGeralId?: string;
  usuarioCreateId?: string;
  usuarioUpdateId?: string;
  metasFisicas: IMetaFisicaData[];
}
export interface IMetaFisicaData {
  id?: string;
  nome: string;
  natureza: string;
  tipoDespesa: string;
  metaGeralId: string;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  metasInstituicoes: IMetaInstituicaoData[];
}
export interface IMetaFisicaResponseData extends IMetaFisicaData {
  exercicioOrcamentario?: string;
  planoAplicacao?: IPlanoAplicacaoData;
  eixosFinanciados?: IEixosFinanciadosData;
  metasInst?: IMetaInstituicaoData[];
  tipoDespesaData?: ITipoDespesaData;
  metasInstituicoes: Array<
    IMetaInstituicaoData & { nome?: string; sigla?: string; unidade?: string }
  >;
  instituicoes?: string[];
  valorInicialTotal?: number;
  countMetasInstituicoes?: number;
  metaGeral?: IMetaGeralData;
  usuarioCreate?: IUsuarioData;
  usuarioUpdate?: IUsuarioData;
}
export interface IMetaInstituicaoData {
  nome: string;
  id?: string;
  instituicaoId: string;
  valorInicial: any;
  unidade: string;
  saldoReal: any;
  saldoEstimativo: any;
}
export interface IMetaInstituicaoResponseData extends IMetaInstituicaoData {
  id?: string;
  valorInicial: number;
  saldoReal: number;
  saldoEstimativo: number;
  saldoRecebido: number;
  saldoRetirado: number;
  saldoRecebidoRendimentos: number;
  valorExecutado: number;
  valorEmpenhado: number;
  instituicaoId: string;
  metaFisicaId: string;
  unidadeId: string;
  instituicaoBeneficiada?: IInstituicaoData;
  unidadeBeneficiada?: IUnidadeData;
  status: string;
}

export const defaultValuesMetasFisicas = {
  planoAplicacaoId: '',
  eixoFinanciadoId: '',
  metaGeralId: '',
  usuarioCreateId: '',
  usuarioUpdateId: '',
  metasFisicas: [
    { metasInstituicoes: [{ instituicaoId: '', valorInicial: undefined, unidade: '' }] },
  ],
};
