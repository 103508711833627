// TYPES / DEFAULT
import { convertToCentavos } from 'src/config/utils';
import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import * as yup from 'yup';

export interface Eixo {
  id: string;
  nome: string;
}

export interface IPlanoAplicacaoData {
  id?: string;
  prazoExecucao: string;
  exercicioOrcamentario: any;
  valorInicialCusteio: number;
  valorInicialInvestimento: number;
  usuarioCreateId: string;
  usuarioUpdateId?: string;
  usuarioAuthorizationId?: string;
  atualizadoEm?: Date;
  eixosFinanciados: IEixosFinanciadosData[];
  status: string;
}
export interface eixosResponse {
  id: string;
  nome: string;
  criadoEm?: Date;
}

export interface planoAplicacaoResponse extends IPlanoAplicacaoData {
  novoPrazoExecucao?: Date;
  criadoEm?: string;
  valorInicialCusteioEixos?: number;
  valorInicialInvestimentoEixos?: number;
  usuarioCreated?: IUsuarioData;
  usuarioAuthorization?: IUsuarioData;
  usuarioUpdate?: IUsuarioData;
}

export const schemaPlanoAplicacao = yup.object<IPlanoAplicacaoData>({
  exercicioOrcamentario: yup.string().required('Ano é obrigatório'),
  prazoExecucao: yup.date().required('Prazo de Execução é obrigatório'),
  eixosFinanciados: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        eixoId: yup.string().required('Eixo é obrigatório'),
        // INVESTIMENTO
        agenciaInvestimento: yup.string().required('Agência de Investimento é obrigatório').trim(),
        contaInvestimento: yup.string().required('Conta de Investimento é obrigatório').trim(),
        valorInicialInvestimento: yup
          .number()
          .positive('Valor não pode ser negativo')
          .required('valor de Investimento é obrigatório')
          .transform((value: number) => convertToCentavos(value)),

        // CUSTEIO
        agenciaCusteio: yup.string().required('Agência de Custeio é obrigatório').trim(),
        contaCusteio: yup.string().required('Conta de Custeio é obrigatório').trim(),
        valorInicialCusteio: yup
          .number()
          .positive('Valor deve ser positivo')
          .required('valor de Custeio é obrigatório')
          .transform((value: number) => convertToCentavos(value)),
      })
    )
    .required(),
});
export const schemaPlanoAplicacaoNovoPrazo = yup.object({
  planoAplicacaoId: yup.string(),
  novoPrazoExecucao: yup.string().required('Prazo de Execução é obrigatório'),
});

export interface IEixosFinanciadosData {
  id?: string;
  eixoId: string;
  // INVESTIMENTO
  agenciaInvestimento: string;
  contaInvestimento: string;
  valorInicialInvestimento: number;
  valorInicialTotal?: number;
  valorExecutadoTotal?: number;
  valorSaldoTotal?: number;
  // CUSTEIO
  agenciaCusteio: string;
  contaCusteio: string;
  valorInicialCusteio: number;
  eixoNome?: string;
}
export interface IEixosFinanciadosResponseData extends IEixosFinanciadosData {
  metasGerais?: any;
}
export interface ISaldoEixo extends IEixosFinanciadosData {
  saldoCusteio: number;
  saldoInvestimento: number;
}
export const defaultValuesPlano = {
  eixosFinanciados: [
    {
      eixoId: '',
      // INVESTIMENTO
      agenciaInvestimento: '',
      contaInvestimento: '',
      valorInicialInvestimento: null,

      // CUSTEIO
      agenciaCusteio: '',
      contaCusteio: '',
      valorInicialCusteio: null,
    },
  ],
  prazoExecucao: '',
  exercicioOrcamentario: '',
  usuarioCreateId: '',
};
