import * as yup from 'yup';

export const schemaTipoDespesa = yup.object({
  nome: yup.string().required('Campo Obrgatório'),
});

export interface ITipoDespesaData {
  id?: string;
  nome: string;
}
