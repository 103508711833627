// TYPES / DEFAULT
import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import * as yup from 'yup';
import { IInstituicaoData } from 'src/pages/Admin/Unidades/Formulario/type';
import { convertToCentavos } from 'src/config/utils';
import {
  Eixo,
  IEixosFinanciadosData,
  IPlanoAplicacaoData,
} from '../../PlanoAplicacao/Formulario/type';
import { IMetaFisicaData } from '../../Metas/Formulario/types';
import { IDespesaResponseData } from '../../Despesas/Empenhos/Formulario/type';

export interface IAprovisionamentoData {
  id?: string;
  descricao: string;
  status: string;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  documentoSei: string;
  valorAprovisionado: number;
  valorUtilizado: number;
  valorEmpenhado: number;
  valorDevolvido: number;

  metasInstituicoesId: string;
  usuarioCreatedId: string;
  usuarioUpdateId: string;
  criadoEm?: string;
  atualizadoEm?: string;
}

export interface AprovisionamentoResponse extends IAprovisionamentoData {
  planoAplicacao?: IPlanoAplicacaoData;
  eixoFinanciado?: IEixosFinanciadosData;
  eixo?: Eixo;
  valorDisponivel?: number;

  metaFisica?: IMetaFisicaData;
  instituicao?: IInstituicaoData;
  usuarioCreated?: IUsuarioData;
  usuarioUpdate?: IUsuarioData;
  despesas?: IDespesaResponseData[];
}

export const schemaAprovisionamento = yup.object<IAprovisionamentoData>({
  planoAplicacaoId: yup.string().required('é obrigatório'),
  eixoFinanciadoId: yup.string().required('é obrigatório'),
  natureza: yup.string().required('é obrigatório'),
  documentoSei: yup
    .string()
    .test(
      'is-greater',
      'Informe um número sei válido',
      (value) => !!value && value.length >= 5 && value.length <= 10
    )
    .required('é obrigatório'),
  valorAprovisionado: yup
    .number()
    .positive('Valor deve ser positivo')
    .required(' é obrigatório')
    .transform((value: number) => convertToCentavos(value)),
  valorEmpenhado: yup
    .number()
    .positive('Valor deve ser positivo')
    .optional()
    .transform((value: number) => convertToCentavos(value)),
  metasInstituicoesId: yup.string().required('é obrigatório').required('é obrigatório'),
  metaFisicaId: yup.string().required('é obrigatório'),
  descricao: yup.string().required('é obrigatório'),
});

export const defaultValuesPlano = {
  documentoSei: '',
  valorAprovisionado: '',
  valorEmpenhado: '',
  metasInstituicoesId: '',
};
