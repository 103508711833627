import { Box, Grid, Paper, Table, TableHead, TableRow, Typography } from '@mui/material';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import { CalculandoPorcentagem, convertToCentavosCentavos } from 'src/config/utils';
import GovFed from 'src/assets/images/gov-fed.png';
import { IEixosFinanciadosData, planoAplicacaoResponse } from '../PlanoAplicacao/Formulario/type';
import { IMetaGeralData } from '../Metas/Cadastro/MetaGeral/type';

export default function RelatorioFinanceiro({
  rowsPlano,
  id,
  rowsEixoFinanciadoMeta,
}: {
  rowsPlano: planoAplicacaoResponse;
  id: string;
  rowsEixoFinanciadoMeta: IEixosFinanciadosData & {
    metasGerais: IMetaGeralData[];
  };
}) {
  return (
    <Grid item md={12}>
      <Paper sx={{ height: '65vh', p: 3, overflow: 'auto' }} id={id}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead size="small">
                  <img src={GovFed} alt="" width={100} />
                </TableCellHead>
                <TableCellHead colSpan={10}>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    PLANO DE AÇÃO
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsPlano.exercicioOrcamentario}
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    AC
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow className="vertical">
                <TableCellHead
                  id="fundo"
                  size="small"
                  colSpan={14}
                  sx={{
                    background: '#FEE69C',
                  }}
                >
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsEixoFinanciadoMeta.eixoNome}
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow>
                <TableCellHead
                  className="sub-title"
                  colSpan={14}
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>METAS FÍSICAS</Typography>
                </TableCellHead>
              </TableRow>

              <TableRow>
                <TableCellHead
                  className="sub-title"
                  colSpan={3}
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>DESPESA</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>NATUREZA</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>INSTITUIÇÃO</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR INICIAL</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    VALOR REMANEJADO RECEBIDO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    VALOR REMANEJADO RETIRADO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    RENDIMENTO ALOCADO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR EMPENHADO</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR EXECUTADO</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>SALDO</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>% EXECUÇÃO</Typography>
                </TableCellHead>
              </TableRow>
            </TableHead>
            {rowsEixoFinanciadoMeta?.metasGerais.map((item: any, index: number) => (
              <>
                {item.metasFisicas?.map((obj: any) => (
                  <>
                    {obj.metasInstituicoes.map((objeto: any) => (
                      <TableRow key={item.id}>
                        <TableCellBody align="center" colSpan={3}>
                          {obj.nomeDespesa}
                        </TableCellBody>
                        <TableCellBody align="center">{obj.natureza}</TableCellBody>
                        <TableCellBody align="center">{objeto.sigla}</TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.valorInicial)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.saldoRecebido)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.saldoRetirado)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.saldoRecebidoRendimentos)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.valorEmpenhado)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.valorExecutado)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {convertToCentavosCentavos(objeto.saldoReal)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          {CalculandoPorcentagem(
                            objeto.valorExecutado,
                            objeto.valorExecutado + objeto.saldoReal
                          )}{' '}
                          %
                        </TableCellBody>
                      </TableRow>
                    ))}
                  </>
                ))}
              </>
            ))}
            <TableRow>
              <TableCellHead colSpan={5} size="small">
                VALOR INICIAL
              </TableCellHead>
              <TableCellHead colSpan={9} size="small">
                R$ {convertToCentavosCentavos(rowsEixoFinanciadoMeta.valorInicialTotal || 0)}
              </TableCellHead>
            </TableRow>
            <TableRow>
              <TableCellHead colSpan={5} size="small">
                VALOR EXECUTADO
              </TableCellHead>
              <TableCellHead colSpan={9} size="small">
                R$ {convertToCentavosCentavos(rowsEixoFinanciadoMeta.valorExecutadoTotal || 0)}
              </TableCellHead>
            </TableRow>
            <TableRow>
              <TableCellHead colSpan={5} size="small">
                SALDO
              </TableCellHead>
              <TableCellHead colSpan={9} size="small">
                R$ {convertToCentavosCentavos(rowsEixoFinanciadoMeta.valorSaldoTotal || 0)}
              </TableCellHead>
            </TableRow>
          </Table>
        </Box>
      </Paper>
    </Grid>
  );
}
