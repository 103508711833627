const Endpoints = {
  auth: '/auth/login',
  eixos: '/eixo',
  usuario: '/usuario',
  unidade: '/unidade',
  grupoPermissoes: '/grupo-de-usuarios',
  instituicao: '/instituicao',
  // PLANEJAMENTO
  eixoFinanciado: '/eixo-financiado',
  eixoFinanciadoMetas: '/eixo-financiado/meta',
  eixosFinanciadosPlanoAplicacaoId: '/eixo-financiado/plano',
  eixoFinanciadoSaldo: '/eixo-financiado/saldo',
  metaGeral: '/meta-geral',
  metaGeralAutorizacao: '/meta-geral/autorizar',
  metaGeralId: '/meta-geral',
  metaFisicalId: '/meta-fisica',
  metaFisica: '/meta-fisica',
  remanejamentoMetaFisica: '/remanejamento/meta',
  metaInstituicaoId: '/meta-instituicao',
  metaFisicaValor: 'meta-fisica/metas-fisicas-valor',
  metaFisicaRemanejamento: 'meta-fisica/metas-fisicas-remanejamento',
  usuarioPermissao: '/des/permissao',
  planoAplicacao: '/plano-aplicacao',
  planoAplicacaoId: '/plano-aplicacao',
  planoAplicacaoAutorizacao: '/plano-aplicacao/autorizar',
  tipoDespesa: '/tipo-despesa',
  rendimentoConta: '/rendimento',
  rendimentoContaId: '/rendimento/rendimento-conta',
  rendimentoMeta: '/rendimento/meta',
  rendimento: '/rendimento',
  rendimentoAutorizacao: '/rendimento/autorizar',
  rendimentoSolicitacao: '/rendimento/alocar',
  autorizarAlocacaoRendimentos: '/rendimento/autorizar/alocacao',
  verificarRendimento: '/rendimento/verificar-rendimento',
  remanejamento: '/remanejamento',
  remanejamentoId: '/remanejamento',
  remanejamentoAutorizacao: '/remanejamento/autorizar',
  planoAplicacaoEixos: '/plano-aplicacao/eixos',
  planoAplicacaoExercicioEixos: '/plano-aplicacao/exercicio-eixo',
  planoAplicacaoEixosFinanciados: '/plano-aplicacao/eixos-financiados',
  flagsPermissoes: '/usuario/flasgPermissoes',
  empenhos: '/empenhos',
  verificarEmpenho: '/despesas/empenho',
  pagamentosEmpenho: '/empenhos/pagamento',
  empenhoApi: '/empenhos',
  despesaMetaFisicaId: '/despesas/meta',
  despesaRelatorioEmpenhos: '/despesas/despesas-relatorio/empenhos',
  despesaRelatorioPagamentos: '/despesas/despesas-relatorio/pagamentos',
  despesas: '/despesas',
  despesasId: '/despesas',
  aprovisionamento: '/aprovisionamento',
  aprovisionamentoDevolver: '/aprovisionamento/devolver',
  aprovisionamentoId: '/aprovisionamento/',
  aprovisionamentoMeta: '/aprovisionamento/meta',
  entregasFundoNacional: '/entregas/fundo-nacional',
  entregaId: '/entregas/fundo-nacional',
  entregaResponsavel: '/entregas/responsavel',
  empenhosAtualizados: 'empenhos/multiplos',
  realizarEntrega: 'entregas/realizar-entrega',
  verificarEmpenhosMetaFisica: 'meta-fisica/verificar-empenhos',

  // OPERACIONAL
  usuarioCiopaer: '/usuarios-ciopaer',
  aeronaves: '/aeronaves',
  abastecimento: '/abastecimento',
  escalaCiopaer: '/escala-ciopaer',
  eventosCiopaer: '/eventos-ciopaer',
};

export default Endpoints;
