import { Alert, Button, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalVerificar from 'src/components/Modal/Verificar';

import CustomModal from 'src/components/Modal';
import { servicePost } from 'src/services';
import FormularioRemanejamento from '../Formulario';
import { IRemanejamentoData, schemaRemanejamento } from '../Formulario/type';

export default function CadastrarRemanejamento({
  open,
  handleClose,
  RefetchRemanejamentos,
}: {
  open: boolean;
  handleClose: () => void;
  RefetchRemanejamentos: (arg: boolean) => void;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaRemanejamento),
  });

  const { state } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const { mutate } = useMutation({
    mutationFn: (data: IRemanejamentoData) => servicePost('remanejamento', { ...data }),
    onSuccess: () => {
      ToastFun('Remanejamento cadastrado com sucesso!', 'success');
      handleClose();
      RefetchRemanejamentos(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response?.data?.message ?? 'Erro ao cadastrar!', 'error');
    },
  });
  const onSubmit = async (data: any) => {
    data.usuarioCreateId = state.usuario?.id;
    mutate(data);
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Remanejamento - Cadastrar"
      icon={<EditNoteIcon color="success" sx={{ fontSize: 30 }} />}
    >
      <Grid container>
        <FormsProvider methods={methods} handleSubmit={onSubmit}>
          <FormularioRemanejamento setValue={setValue} />
        </FormsProvider>
      </Grid>
      <ModalVerificar
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dados={methods.getValues()}
        mutate={mutate}
        title="Remanejamento"
      />
    </CustomModal>
  );
}
