import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalExcluir from 'src/components/Modal/Excluir';
import { convertToCentavosCentavos } from 'src/config/utils';
import { serviceDelete, serviceGet } from 'src/services';

export default function MetaFisica() {
  const include = ['metaInstituicaoCount', 'metaGeral'];

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [instituicaoId, setInstituicaoId] = useState<string>('');

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };

  const parametros = {
    filters: { search },
    planoAplicacaoId,
    eixoFinanciadoId,
    instituicaoId,
    include,
  };

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: (idMeta: string) => serviceDelete('metaFisica', {}, idMeta),
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      handleCloseModal();
      setTableRefetch(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'META FÍSICA',
      flex: 7,
      minWidth: 350,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'valorInicialTotal',
      headerName: 'VALOR INICIAL',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valorInicialTotal)}</>,
    },

    {
      field: 'Instituições',
      minWidth: 150,
      headerName: 'INSTITUIÇÕES',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Tooltip
          title={params.row.instituicoes.map((item: any) => (
            <>{item} </>
          ))}
        >
          <Typography>{params.row.countMetasInstituicoes}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,

      renderCell: (params) => (
        <Box>
          <IconButton
            disabled={params.row.metaGeral.status !== 'Pendente'}
            sx={{ color: 'gray' }}
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton color="primary" href={`metas/${params.row.id}`}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao'),
  });
  const apagarCampos = () => {
    setEixoFinanciadoId('');
    setPlanoAplicacaoId('');
    setInstituicaoId('');
    setSearch('');
  };
  useEffect(() => {
    setEixoFinanciadoId('');
  }, [planoAplicacaoId]);
  return (
    <Grid container>
      <Grid item xs={12} md={1.5} xl={1.5} sx={{ my: 2, pr: 0.5 }}>
        <TextField
          size="small"
          select
          name="exercicioOrcamentario"
          label="Ano"
          fullWidth
          value={planoAplicacaoId}
          onChange={(event) => setPlanoAplicacaoId(event.target.value)}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            },
          }}
        >
          {rowPlanosAplicacao &&
            rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
              <MenuItem
                key={indexExercicio}
                value={item.id}
                sx={{
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {item.exercicioOrcamentario}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={3} xl={3} sx={{ my: 2, px: 0.5 }}>
        <TextField
          size="small"
          select
          name="eixoFinanciado"
          label="Eixo Financiado"
          fullWidth
          value={eixoFinanciadoId}
          onChange={(event) => setEixoFinanciadoId(event.target.value)}
        >
          {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={1.5} xl={1.5} sx={{ my: 2, px: 0.5 }}>
        <TextField
          size="small"
          select
          name="instituicaoId"
          label="Instituição"
          fullWidth
          value={instituicaoId}
          onChange={(event) => setInstituicaoId(event.target.value)}
        >
          {rowInstituicoes?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={4} xl={4} sx={{ my: 2, px: 0.5 }}>
        <Stack direction="row" spacing={2}>
          <TextField
            value={search}
            size="small"
            fullWidth
            onChange={(event) => setSearch(event.target.value)}
          />
          <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
            <SearchIcon />
          </IconButton>
          <IconButton
            color="info"
            sx={{ background: '#D4EAF7   ', mx: 1 }}
            onClick={() => {
              apagarCampos();
            }}
          >
            <CachedIcon />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2} xl={2} sx={{ my: 2, pl: 0.5, textAlign: 'right' }}>
        <Button
          href="/planejamento/fundonacional/metas/cadastro"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Meta Física
        </Button>
      </Grid>

      <Grid xs={12} mt={2}>
        <TableContainer
          endpoint="metaFisica"
          columns={columns}
          params={parametros}
          setRefetch={tableRefetch}
          setTableRefetch={setTableRefetch}
        />
      </Grid>

      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Meta Física"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Grid>
  );
}
