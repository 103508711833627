import { useEffect, useState } from 'react';
import { Grid, Button, Typography, Card, Alert, CardContent, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { useQuery } from '@tanstack/react-query';
import { FieldValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';

import { convertToCentavosCentavos } from 'src/config/utils';
import { serviceGet } from 'src/services';
import { ToastFun } from 'src/config/functions';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import { MetaFisicaForms } from './MetaFisica';

interface Plano {
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  watch: any;
}

export default function MetasFisicas({ setValue, unregister, watch }: Plano) {
  const [metasFisicas, setMetasFisicas] = useState<number[]>([0]);

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });
  const metasFisicasForm = useWatch({ name: 'metasFisicas' });

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const params = {
    planoAplicacaoId,
    eixoFinanciadoId,
  };
  const { data: metasGerais } = useQuery({
    queryKey: ['getMetas', planoAplicacaoId, eixoFinanciadoId],
    queryFn: () => serviceGet('metaGeral', { ...params }),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId,
  });

  const eixoSelecionado = useWatch({ name: 'eixoFinanciadoId' });
  const { data: saldoEixo } = useQuery({
    queryKey: ['getMetas', eixoSelecionado],
    queryFn: () => serviceGet('eixoFinanciadoSaldo', {}, eixoSelecionado),
    enabled: !!eixoSelecionado,
  });
  const novaMeta = () => {
    const novasMetas = [...metasFisicas];
    const count = novasMetas[novasMetas.length - 1] + 1;
    setMetasFisicas([...novasMetas, count]);
  };

  const metasFisicasForms = useWatch({ name: 'metasFisicas' });

  useEffect(() => {
    if (metasFisicasForms?.length > 0) {
      setMetasFisicas(metasFisicasForms.map((_: any, index: number) => index));
    }
  }, [metasFisicasForms]);

  const excluirMetaFisica = (index: number) => {
    if (metasFisicas.length > 1) {
      unregister(`metasFisicas[${index}]`);
      setMetasFisicas((prevMetas) => prevMetas.filter((eixo) => eixo !== index));
    } else {
      ToastFun('É necessário ter ao menos uma Meta Física', 'error');
    }
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={12} lg={4}>
        <CustomSelect
          label="Exercício Orçamentário"
          name="planoAplicacaoId"
          options={
            planoAplicacao?.results?.map((item: any, indexExercicio: number) => ({
              id: item.id,
              nome: item.exercicioOrcamentario,
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box display="grid">
          <CustomSelect
            name="eixoFinanciadoId"
            label="Eixo/Área Temática"
            options={
              eixosFinanciados?.results?.map((item: any, indexEixoFinanciado: number) => ({
                id: item.id,
                nome: item.eixoNome,
              })) ?? []
            }
          />{' '}
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box display="grid">
          <CustomSelect
            label="Meta Geral"
            name="metaGeralId"
            marginSub={-1}
            options={
              metasGerais?.results?.map((item: any) => ({
                id: item.id,
                nome: item.nome,
                marginSub: 5,
              })) ?? []
            }
          />
        </Box>
      </Grid>

      {saldoEixo && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center', mx: 1 }}>
            <Card sx={{ background: '#E8F7FF', color: '#4CC0FF' }}>
              <CardContent>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                  Valor Disponível Investimento
                </Typography>

                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  R$ {convertToCentavosCentavos(saldoEixo?.saldoInvestimento ?? 0)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center', mx: 1 }}>
            <Card sx={{ background: '#ECF2FF', color: '#5D87FF' }}>
              <CardContent>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                  Valor Disponível Custeio
                </Typography>

                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  R$ {convertToCentavosCentavos(saldoEixo?.saldoCusteio ?? 0)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6.3} sx={{ textAlign: 'center' }}>
            <Alert variant="standard" severity="warning" sx={{ mx: 1 }}>
              Os valores cadastrados não devem exceder o valor disponível para este eixo!
            </Alert>
          </Grid>
        </>
      )}

      {/* METAS FISICAS */}
      <Grid item xs={12}>
        {metasFisicas.map(
          (item, indexMeta) =>
            item !== undefined && (
              <MetaFisicaForms
                key={item}
                index={item}
                setValue={setValue}
                unregister={unregister}
                excluirMetaFisica={excluirMetaFisica}
                watch={watch}
              />
            )
        )}
      </Grid>
      <Grid item xs={12} md={12} mt={3} sx={{ my: 5 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novaMeta}>
          Meta Física
        </Button>
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
