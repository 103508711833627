import { Box, Grid, Alert, styled, TableCell, Chip, Typography, Divider } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoData, convertToCentavosCentavos, valorMonetario } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { serviceGet } from 'src/services';
import CustomModal from 'src/components/Modal';
import TableContainerCustom from 'src/components/Table';
import { ApiEmpenho } from 'src/services/Api';
import { getColorStatus } from '../../../Aprovisionamento';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function DespesaVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: () => void;
}) {
  const include = ['eixoFinanciado', 'user'];
  const parametros = { include };

  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const { data: rows, isPending } = useQuery({
    queryKey: ['getDespesasId', id, include],
    queryFn: () => serviceGet('despesasId', { include }, id),
  });
  const columns: GridColDef[] = [
    {
      field: 'numeroPagamento',
      headerName: 'Nº PAGAMENTO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.numeroPagamento}/{params.row.anoPagamento}
        </>
      ),
    },
    {
      field: 'valorPagamento',
      headerName: 'VALOR PAGO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <> {valorMonetario(params.row.valorPagamento)}</>,
    },
    {
      field: 'dataEmissao',
      headerName: 'DATA DE PAGAMENTO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <> {tratandoData(params.row.dataEmissao)}</>,
    },
    {
      field: 'descricaoNaturezaPagamento',
      headerName: 'DESCRIÇÃO',
      align: 'center',
      minWidth: 150,
      flex: 2,
      headerAlign: 'center',
    },
    {
      field: 'razaoSocial',
      headerName: 'BENEFICÁRIO',
      align: 'center',
      minWidth: 150,
      flex: 2,
      headerAlign: 'center',
    },
  ];
  return (
    <div>
      {!isPending && rows && (
        <CustomModal
          open={open}
          onClose={handleClose}
          title="Despesas - Detalhes"
          icon={<VisibilityIcon color="primary" sx={{ fontSize: 25 }} />}
        >
          {rows && (
            <Grid container>
              <Grid md={12} xs={12}>
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead align="center">Exercício Orçamentário</TableCellHead>
                        <TableCellHead align="center">Eixo Financiado</TableCellHead>
                        <TableCellHead>Meta Geral</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody align="center">
                          {rows?.planoAplicacao?.exercicioOrcamentario}
                        </TableCellBody>
                        <TableCellBody align="center">
                          {rows?.eixoFinanciado?.eixoNome}
                        </TableCellBody>
                        <TableCellBody>{rows?.metaGeral?.nome}</TableCellBody>
                      </TableRow>
                    </TableBody>
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead align="center">Instituição</TableCellHead>
                        <TableCellHead align="center">Natureza</TableCellHead>
                        <TableCellHead>Meta Física</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody align="center">{rows.instituicao}</TableCellBody>
                        <TableCellBody align="center">{rows?.metaFisica?.natureza}</TableCellBody>
                        <TableCellBody>{rows?.metaFisica?.nome}</TableCellBody>
                      </TableRow>
                    </TableBody>
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead>Processo de Contratação</TableCellHead>
                        <TableCellHead align="center">Processo Pagamento</TableCellHead>
                        <TableCellHead>Descrição </TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody>{rows.processoSeiMatriz}</TableCellBody>
                        <TableCellBody align="center">{rows.processoSeiPagamento}</TableCellBody>
                        <TableCellBody>{rows.descricao}</TableCellBody>
                      </TableRow>
                    </TableBody>
                    {rows.entrega && (
                      <>
                        <TableHead sx={{ background: '#f5f5f5' }}>
                          <TableRow>
                            <TableCellHead colSpan={10}>Responsável Pela Entrega</TableCellHead>
                            <TableCellHead align="center">Situação de Entrega</TableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCellBody colSpan={10}>
                              {rows.entrega.nomeResponsavel}
                            </TableCellBody>
                            <TableCellBody align="center">
                              <Chip
                                label={rows.entrega.status}
                                sx={{ color: 'white' }}
                                color={getColorStatus(rows.entrega.status)}
                              />
                            </TableCellBody>
                          </TableRow>
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                <Box>
                  <Table>
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead colSpan={2}>Empenho</TableCellHead>
                        <TableCellHead align="center" colSpan={2}>
                          Valor Empenhado
                        </TableCellHead>
                        <TableCellHead align="center" colSpan={3}>
                          Valor Anulado
                        </TableCellHead>
                        <TableCellHead align="center" colSpan={2}>
                          Valor Pago
                        </TableCellHead>
                        <TableCellHead align="center" colSpan={2}>
                          Valor Complementar
                        </TableCellHead>
                        <TableCellHead align="center" colSpan={2}>
                          Saldo
                        </TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody colSpan={2}>{rows?.numeroEmpenho}</TableCellBody>
                        <TableCellBody colSpan={2} align="center">
                          R$ {convertToCentavosCentavos(rows.valorEmpenhado)}
                        </TableCellBody>
                        <TableCellBody align="center" colSpan={3}>
                          R$ {convertToCentavosCentavos(rows.valorAnulado)}
                        </TableCellBody>
                        <TableCellBody align="center" colSpan={2}>
                          R$ {convertToCentavosCentavos(rows.valorPago)}
                        </TableCellBody>
                        <TableCellBody align="center" colSpan={2}>
                          R$ {convertToCentavosCentavos(rows.valorComplemento)}
                        </TableCellBody>
                        <TableCellBody align="center" colSpan={2}>
                          R${' '}
                          {convertToCentavosCentavos(
                            rows.valorEmpenhado +
                              rows.valorComplemento -
                              rows.valorPago -
                              rows.valorAnulado
                          )}
                        </TableCellBody>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Divider />
                <Box mt={2}>
                  <Box display="flex" alignItems="center" sx={{}}>
                    <Typography variant="h6" sx={{ color: '#6D6C6C', mt: 1 }}>
                      <CurrencyExchangeIcon color="success" />
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#6D6C6C', ml: 1 }}>
                      Pagamentos
                    </Typography>
                  </Box>
                  <TableContainerCustom
                    columns={columns}
                    endpoint="pagamentosEmpenho"
                    setRefetch={tableRefetch}
                    params={{}}
                    idGet={rows.numeroEmpenho}
                    setTableRefetch={setTableRefetch}
                    client={ApiEmpenho}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </CustomModal>
      )}
    </div>
  );
}
