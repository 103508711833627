import { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { FieldValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import { serviceGet } from 'src/services';
import EixoForms from './formEixoFinanciado';

export default function FormPlanoAplicacao({
  unregister,
  setValue,
}: {
  unregister: UseFormUnregister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}) {
  const plano = useWatch({ name: `id` });
  const eixosPlano = useWatch({ name: `eixosFinanciados` });

  const [eixos, setEixos] = useState<number[]>([0]);

  useEffect(() => {
    if (eixosPlano?.length > 0) {
      setEixos(eixosPlano.map((_: any, index: number) => index));
    }
  }, [eixosPlano]);

  const novoEixo = () => {
    const novoEixos = [...eixos];
    const count = novoEixos[novoEixos.length - 1] + 1;
    setEixos([...novoEixos, count]);
  };
  const excluirEixo = (index: number) => {
    if (eixos.length > 1) {
      setEixos((prevEixos) => prevEixos.filter((eixo) => eixo !== index && eixo !== null));
      unregister(`eixosFinanciados[${index}]`);
    } else {
      ToastFun('É necessário que o Plano possua ao menos um Eixo', 'error');
    }
  };

  const { data: rowsPlan } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosCadadastrados } = useQuery({
    queryKey: ['getEixos'],
    queryFn: () => serviceGet('eixos'),
  });
  const startYear = 2019;
  const currentYear = new Date().getFullYear();
  const anos = Array.from({ length: currentYear - startYear + 1 }, (_, i) =>
    (startYear + i).toString()
  );

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={4}>
        <CustomSelect
          label="Exercício Orçamentário"
          name="exercicioOrcamentario"
          disabled={plano}
          options={anos
            ?.filter(
              (ano) =>
                plano || !rowsPlan?.results.some((item: any) => item.exercicioOrcamentario === ano)
            )
            .map((ano) => ({
              id: ano,
              nome: ano,
            }))}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextFieldRG type="date" name="prazoExecucao" label="Prazo de Execução" />
      </Grid>

      <Grid item xs={12} md={4} mt={5} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novoEixo}>
          Eixo
        </Button>
      </Grid>
      <Grid item xs={12}>
        {eixos.map(
          (item, index) =>
            item !== undefined && (
              <EixoForms
                key={item}
                index={item}
                eixosList={eixosPlano}
                eixosCadadastrados={eixosCadadastrados?.results || []}
                excluirEixo={excluirEixo}
              />
            )
        )}
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          {!plano ? 'Cadastrar' : 'Atualizar'}
        </Button>
      </Grid>
    </Grid>
  );
}
