import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';

interface ItemListProps {
  title: string;
  Icon: JSX.Element;
  select: boolean;
  link: string;
  open: boolean;
  disabled?: boolean;
  children?: Array<{ title: string; icon: JSX.Element; link: string }>;
}

const ItemList: React.FC<ItemListProps> = ({
  title,
  Icon,
  select,
  link,
  open,
  children,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const subMod = pathSegments[2];

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <ListItem key={title} disablePadding sx={{ display: 'block', my: 1, px: open ? 2 : 0.3 }}>
      <ListItemButton
        className="listMenu"
        disabled={disabled}
        href={link}
        selected={select}
        sx={{
          justifyContent: 'center',
          borderRadius: 1,
          '&.MuiListItemButton-root': {
            color: '#626262',
          },
          '&.MuiListItemButton-root.Mui-selected ': {
            background: '#019362 ',
            color: '#f5f5f5',
          },
          '&.MuiListItemButton-root.Mui-selected:hover ': {
            color: '#626262',
          },
          '&.MuiListItemButton-root.Mui-selected:hover .MuiListItemIcon-root': {
            color: '#626262',
          },
          '&.MuiListItemButton-root.Mui-selected .MuiListItemIcon-root': {
            color: '#f5f5f5',
          },

          '&.MuiListItemButton-root:hover': {
            background: '#F4FFF4 ',
          },
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: 'center',
            color: '#626262',
          }}
        >
          {Icon}
        </ListItemIcon>
        {open && (
          <ListItemText
            primary={title}
            sx={{
              fontSize: '10px',
              '&.MuiListItemText-root .MuiTypography-root': {},
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default ItemList;
