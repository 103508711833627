import {
  Box,
  Modal,
  Grid,
  IconButton,
  Table,
  TableHead,
  TableRow,
  Chip,
  Alert,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from '@tanstack/react-query';

import { getListarEscala } from 'src/services/Operacional/Ciopaer/escala';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import { formatarDataExtenso, tratandoData } from 'src/config/utils';

interface Usuario {
  id: string;
  nome: string;
  email: string;
  cpf: string;
  status: string;
  criadoEm: string;
  atualizadoEm: string;
}

interface Tripulacao {
  id: string;
  origem: string;
  comandanteAeronaveId: string;
  horarioEntradaComandanteAeronave: string;
  horarioSaidaComandanteAeronave: string;
  comandanteOpAereaId: string;
  horarioEntradaComandanteOpAereas: string;
  horarioSaidaComandanteOpAereas: string;
  escalaCiopaerId: string;
  criadoEm: string;
  atualizadoEm: string;
  comandanteAeronaveNome: Usuario;
  comandanteOpAreaNome: Usuario;
}
interface NomeOperador {
  id: string;
  nome: string;
  email: string;
  cpf: string;
  status: string;
  criadoEm: string;
  atualizadoEm: string;
}

interface EscalaCiopaer {
  id: string;
  origem: string;
  servidorId: string;
  horarioEntrada: string;
  horarioSaida: string;
  escalaCiopaerId: string;
  criadoEm: string;
  atualizadoEm: string;
  nomeOperador: NomeOperador;
}
const escalas: EscalaCiopaer[] = [
  {
    id: '',
    origem: '',
    servidorId: '',
    horarioEntrada: '',
    horarioSaida: '',
    escalaCiopaerId: '',
    criadoEm: '',
    atualizadoEm: '',
    nomeOperador: {
      id: '',
      nome: '',
      email: '',
      cpf: '',
      status: '',
      criadoEm: '',
      atualizadoEm: '',
    },
  },
];
export function VisualizarEscala({
  id,
  open,
  handleClose,
  refetch,
}: {
  id: string;
  open: boolean;
  handleClose: () => void;
  refetch?: () => void;
}) {
  const [value, setValue] = useState(0);

  const [operadoresAsaRotativa, setOperadoresAsaRotativa] = useState<EscalaCiopaer[]>(escalas);
  const [operadoresAsaFixa, setOperadoresAsaFixa] = useState<EscalaCiopaer[]>(escalas);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: rows, isPending } = useQuery({
    queryKey: ['getEscala', id],
    queryFn: getListarEscala,
  });

  useEffect(() => {
    if (rows) {
      setOperadoresAsaFixa(rows?.Operadores?.filter((item: any) => item.origem === 'Asa Fixa'));
      setOperadoresAsaRotativa(
        rows?.Operadores?.filter((item: any) => item.origem === 'Asa Rotativa')
      );
    }
  }, [rows]);
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right', mt: -4 }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12} sx={{ mt: 3 }}>
            <CustomContainerList type="detalhes" title={`Escala `} shadow>
              <Table>
                <TableHead>
                  <TableRow sx={{ background: '#f7f7f7' }}>
                    <TableCellHead colSpan={2}>Data da Escala</TableCellHead>

                    <TableCellHead>Situação</TableCellHead>
                  </TableRow>
                  <TableRow>
                    <TableCellBody colSpan={2} align="center">
                      {formatarDataExtenso(rows?.DadosEscala.dataEscala)}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        label={rows?.DadosEscala.status}
                        sx={{ color: 'white' }}
                        color={rows?.DadosEscala.status === 'Pendente' ? 'warning' : 'success'}
                      />
                    </TableCellBody>
                  </TableRow>
                </TableHead>
                {rows?.Tripulacao?.map((trip: any) => (
                  <>
                    <TableRow sx={{ background: '#f7f7f7' }}>
                      <TableCellHead colSpan={3} sx={{ fontSize: 13 }}>
                        TRIPULAÇÃO {trip.origem}
                      </TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellHead colSpan={1} sx={{ width: '150px' }}>
                        Comandante de Aeronave
                        <br />
                        <span style={{ color: '#a72525' }}>
                          {' '}
                          das {trip.horarioEntradaComandanteAeronave} às{' '}
                          {trip.horarioSaidaComandanteAeronave}
                        </span>
                      </TableCellHead>
                      <TableCellHead colSpan={1} sx={{ width: '150px' }}>
                        Comandante de Op. Aéreas <br />
                        <span style={{ color: '#a72525' }}>
                          das {trip.horarioEntradaComandanteOpAereas} às{' '}
                          {trip.horarioSaidaComandanteOpAereas}
                        </span>
                      </TableCellHead>
                      <TableCellHead colSpan={1} sx={{ width: '150px' }}>
                        Operador Aerotático <br />
                        <span style={{ color: '#a72525' }}>
                          das{' '}
                          {trip.origem === 'Asa Rotativa'
                            ? operadoresAsaRotativa[0].horarioEntrada
                            : operadoresAsaFixa[0].horarioEntrada}{' '}
                          às{' '}
                          {trip.origem === 'Asa Rotativa'
                            ? operadoresAsaRotativa[0].horarioSaida
                            : operadoresAsaFixa[0].horarioSaida}
                        </span>
                      </TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellBody align="center">
                        {trip.comandanteAeronaveNome.nome}
                      </TableCellBody>
                      <TableCellBody align="center">{trip.comandanteOpAreaNome.nome}</TableCellBody>
                      <TableCellBody align="center">
                        {trip.origem === 'Asa Rotativa'
                          ? operadoresAsaRotativa?.map((serv) => (
                              <Box>{serv.nomeOperador.nome}</Box>
                            ))
                          : operadoresAsaFixa?.map((serv) => <Box>{serv.nomeOperador.nome}</Box>)}
                      </TableCellBody>
                    </TableRow>
                  </>
                ))}
                <TableRow sx={{ background: '#f7f7f7' }}>
                  <TableCellHead colSpan={4} sx={{ fontSize: 13 }}>
                    EXPEDIENTE ADMINITRATIVO / PERMANÊNCIA NO HANGAR
                  </TableCellHead>
                </TableRow>
                <TableRow sx={{ background: '#fcfcfc' }}>
                  <TableCellHead>
                    Asa Rotativa das <br />
                    <span style={{ color: '#a72525' }}>
                      Das{' '}
                      {rows?.Administracao?.find((admin: any) => admin.origem === 'Asa Rotativa')
                        ?.horarioEntrada || '---'}{' '}
                      às{' '}
                      {rows?.Administracao?.find((admin: any) => admin.origem === 'Asa Rotativa')
                        ?.horarioSaida || '---'}
                    </span>
                  </TableCellHead>

                  <TableCellHead>
                    Asa Fixa
                    <br />
                    <span style={{ color: '#a72525' }}>
                      Das{' '}
                      {rows?.Administracao?.find((admin: any) => admin.origem === 'Asa Fixa')
                        ?.horarioEntrada || '---'}{' '}
                      às{' '}
                      {rows?.Administracao?.find((admin: any) => admin.origem === 'Asa Fixa')
                        ?.horarioSaida || '---'}
                    </span>
                  </TableCellHead>
                  <TableCellHead>
                    {' '}
                    Permanência no Hangar
                    <br />
                    <span style={{ color: '#a72525' }}>
                      Das {rows?.PermanenciaHangar[0].dataHoraEntrada?.split('T')[1].slice(0, 5)}{' '}
                      até {rows?.PermanenciaHangar[0].dataHoraSaida?.split('T')[1].slice(0, 5)} -
                      {tratandoData(rows?.PermanenciaHangar[0].dataHoraSaida)}
                    </span>
                  </TableCellHead>
                </TableRow>
                <TableRow>
                  <TableCellBody align="center">
                    {rows?.Administracao?.filter(
                      (admin: any) => admin.origem === 'Asa Rotativa'
                    ).map((admin: any, index: number) => (
                      <div key={index}>{admin.nomeServidor.nome}</div>
                    ))}
                  </TableCellBody>
                  <TableCellBody align="center">
                    {rows?.Administracao?.filter((admin: any) => admin.origem === 'Asa Fixa').map(
                      (admin: any, index: number) => (
                        <div key={index}>{admin.nomeServidor.nome}</div>
                      )
                    )}
                  </TableCellBody>
                  <TableCellBody align="center">
                    {rows?.PermanenciaHangar[0].nomeServidor?.nome}
                  </TableCellBody>
                </TableRow>
                <TableRow sx={{ background: '#f7f7f7' }}>
                  <TableCellHead colSpan={4} sx={{ fontSize: 13 }}>
                    MANUTENÇÃO
                  </TableCellHead>
                </TableRow>
                <TableRow sx={{ background: '#fcfcfc' }}>
                  <TableCellHead colSpan={2} sx={{ textAlign: 'left' }}>
                    SERVIDOR
                  </TableCellHead>
                  <TableCellHead>HORÁRIO</TableCellHead>
                </TableRow>
                {rows?.Manutencao?.map((mant: any) => (
                  <TableRow key={mant.id}>
                    <TableCellBody colSpan={2} sx={{ fontWeight: 700 }}>
                      {mant.nomeServidor.nome}
                    </TableCellBody>
                    <TableCellHead align="center">
                      <span style={{ color: '#a72525' }}>
                        Das {mant.horarioEntrada} às {mant.horarioSaida}
                      </span>
                    </TableCellHead>
                  </TableRow>
                ))}
              </Table>
            </CustomContainerList>
          </Grid>
          <Grid item md={5}>
            {rows?.DadosEscala?.status === 'Autorizado' && (
              <Box my={2}>
                <Alert sx={{ fontWeight: 500 }} severity="info">
                  Autorizado por : {rows?.usuarioAuthorization?.nome}
                </Alert>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
