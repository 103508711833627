import { Navigate } from 'react-router-dom';
import { RoutePrivate } from 'src/auth/RouteProtect';
import LayoutSidebar from 'src/components/Layout';
import Entregas from 'src/pages/Entregas/Planejamento';

export const Entrega = {
  element: <RoutePrivate element={<LayoutSidebar />} permission="ENTREGA" />,
  path: '/entregas',
  children: [
    {
      element: <Navigate to="/entregas/planejamento" />,
      path: '',
    },
    {
      element: <RoutePrivate element={<Entregas />} permission="ENTREGA" />,
      path: '/entregas/planejamento',
    },
  ],
};
