/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuery } from '@tanstack/react-query';
import EventIcon from '@mui/icons-material/Event';
import { getListarEventos } from 'src/services/Operacional/Ciopaer/eventos';
import { AuthContext } from 'src/Context/ContextUser';
import PersonIcon from '@mui/icons-material/Person';
import { VisualizarEscala } from '../Escalas/Visualizar';

export function EventosAgenda() {
  const { state } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEvent, setIdEvent] = useState<string | null>(null);

  const id = state.usuario?.id;
  const handleButtonClick = (event: string) => {
    setIdEvent(event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();

  const handleDatesSet = (dateInfo: any) => {
    setDataInicial(dateInfo.startStr);
    setDataFinal(dateInfo.endStr);
  };
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);
  const [eventInfo, setEventInfo] = useState<any>(null);
  const {
    data: rows,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getEscalar', dataInicial, dataFinal, id],
    queryFn: getListarEventos,
    enabled: !!dataInicial && !!dataFinal && !!id,
  });

  const handleEventClick = (info: any) => {
    setAnchorPosition({
      top: info.jsEvent.pageY,
      left: info.jsEvent.pageX,
    });
    setEventInfo(info.event);
  };

  const handleClose = () => {
    setAnchorPosition(null);
    setEventInfo(null);
  };

  const open = Boolean(anchorPosition);

  return (
    <Box
      sx={{
        m: 'auto',
        '& .fc-toolbar-title': {
          textTransform: 'uppercase',
          fontSize: '20px',
        },
        '& .fc .fc-col-header-cell-cushion': {
          textTransform: 'uppercase',
        },
      }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek',
        }}
        initialView="dayGridMonth"
        locale="pt-br"
        datesSet={handleDatesSet}
        buttonText={{
          today: 'Hoje',
          month: 'Mês',
          week: 'Semana',
          day: 'Dia',
          list: 'Lista',
        }}
        height={800}
        eventClick={handleEventClick}
        timeZone="UTC"
        events={rows}
      />{' '}
      <Popover
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition ? { top: anchorPosition.top, left: anchorPosition.left } : undefined
        }
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {eventInfo && (
          <div style={{ padding: '16px', maxWidth: '300px' }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <EventIcon
                sx={{
                  marginRight: '8px',
                  color: eventInfo._def.ui.backgroundColor,
                }}
              />
              {eventInfo.title}
            </Typography>
            <Typography variant="body2">
              <strong>Início:</strong> {new Date(eventInfo.start).toLocaleString('pt-BR')}
            </Typography>
            <Typography variant="body2">
              <strong>Término:</strong> {new Date(eventInfo.end).toLocaleString('pt-BR')}
            </Typography>
            <Typography variant="body2">
              <strong>Descrição:</strong> {eventInfo.extendedProps.description}
            </Typography>
            <Typography
              gutterBottom
              sx={{ display: 'flex', alignItems: 'center', mt: 2, fontSize: 12, fontWeight: 500 }}
            >
              <PersonIcon sx={{ fontSize: 20, mr: 1 }} />
              {eventInfo.extendedProps.user}
            </Typography>

            <Button
              color="primary"
              size="small"
              onClick={() => handleButtonClick(eventInfo._def.publicId)}
            >
              <VisibilityIcon sx={{ fontSize: 20, mr: 1 }} />
              Ver Escala
            </Button>
          </div>
        )}
      </Popover>
      {idEvent && (
        <VisualizarEscala id={idEvent} open={isModalOpen} handleClose={handleCloseModal} />
      )}
    </Box>
  );
}
