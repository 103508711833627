import { convertToCentavos } from 'src/config/utils';
import * as yup from 'yup';

export const schemaIRendimentoAlocacaoData = yup.object<IRendimentoAlocacaoData>({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  rendimentoContaId: yup.string(),
  conta: yup.string().required('é Obrigatório'),
  metaFisicaId: yup.string().required('é Obrigatório'),
  metaInstituicaoId: yup.string().required('é Obrigatório'),
  valor: yup
    .number()
    .transform((value) => (value ? convertToCentavos(value) : 0))
    .required('é Obrigatório'),
  processoSei: yup
    .string()
    .required('é Obrigatório')
    .test('maxLength', 'Número SEI inválido', (value) => value.length === 25)
    .test('validYear', 'Número SEI inválido', (value) => {
      const year = parseInt(value.split('/')[1]?.split('-')[0], 10);
      const currentYear = new Date().getFullYear();
      return year > 2015 && year <= currentYear;
    }),
  usuarioCreatedId: yup.string(),
});

export type IRendimentoAlocacaoData = {
  id: string;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  conta: string;
  metaFisicaId: string;
  metaInstitucionalId: string;
  valor: number;
  processoSei: string;
};
export interface ContaFinanciamento {
  id: string;
  conta: string;
  valorTotal: number;
  valorRetirado: number;
  valorDisponivel: number;
  eixoFinanciadoId: string;
  criadoEm: string;
}
