import { Box, Card, CardContent, Typography } from '@mui/material';

interface CardValueProps {
  title: string;
  value: number | string;
  color?: string;
}

export const CardValue: React.FC<CardValueProps> = ({ title, value, color }) => (
  <Card>
    <CardContent sx={{ height: '100%' }}>
      <Typography component="div" sx={{ color: '#6D6C6C', fontSize: '16px', fontWeight: '600' }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '15px' }} color="text.secondary">
        {value}
      </Typography>
    </CardContent>
    <Box sx={{ borderBottom: `2px solid ${color}` }}> </Box>
  </Card>
);
