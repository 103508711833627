import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  styled,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useMutation, useQuery } from '@tanstack/react-query';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ErroResponse } from 'src/pages/types';
import { ToastFun } from 'src/config/functions';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { convertToCentavosCentavos } from 'src/config/utils';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { useState } from 'react';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import { serviceGet, servicePatch } from 'src/services';
import { IMetaGeralData } from '../../Metas/Cadastro/MetaGeral/type';

const TypographyTitulo = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TypographyBody = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaGeralVisualizarAutorizacao() {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const nav = useNavigate();

  const include = ['eixoFinanciado', 'user', 'planoAplicacao', 'metasFisicas'];
  const {
    data: rowsMetaGeral,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getMetaGeral', id, include],
    queryFn: () => serviceGet('metaGeralId', { include }, id),
  });

  const { mutate } = useMutation({
    mutationFn: (data: IMetaGeralData) => servicePatch('metaGeralAutorizacao', { ...data }, id),
    onSuccess: (data) => {
      refetch();
      ToastFun('Meta Autorizada com sucesso', 'success');
      nav('/planejamento/fundonacional/autorizacao');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  if (isPending || !rowsMetaGeral) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <CustomBreadcrumbs
        heading="Meta Geral - Autorização"
        links={[
          { name: 'Planejamento', href: '/planejamento' },
          { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
          { name: 'Autorização', href: '/planejamento/fundonacional/autorizacao' },
          { name: 'Meta Geral', href: '#' },
        ]}
      />

      <Stack
        spacing={3}
        sx={{
          mt: '2rem',
        }}
      >
        <Divider />
        <Box
          sx={{
            display: {
              md: 'block',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <Grid container px={2}>
            {rowsMetaGeral.status === 'Pendente' &&
              rowsMetaGeral.planoAplicacao?.status === 'Pendente' && (
                <Grid item md={12} mb={2}>
                  {rowsMetaGeral.status === 'Pendente' && (
                    <Alert
                      severity="warning"
                      color="warning"
                      sx={{ textTransform: 'uppercase', fontSize: 12, fontWeight: 500 }}
                    >
                      Essa meta não pode ser autorizada pois está vinculada a um Plano não
                      Autorizado
                    </Alert>
                  )}
                </Grid>
              )}
            <Table>
              <TableRow sx={{ background: '#f5f5f5' }}>
                <TableCellHead>Descrição</TableCellHead>
                <TableCellHead align="center">Situação</TableCellHead>
              </TableRow>
              <TableRow>
                <TableCellBody>{rowsMetaGeral?.nome}</TableCellBody>
                <TableCellBody align="center">
                  <Chip
                    label={rowsMetaGeral.status}
                    sx={{ color: 'white' }}
                    color={rowsMetaGeral.status === 'Pendente' ? 'warning' : 'success'}
                  />
                </TableCellBody>
              </TableRow>
            </Table>

            <Grid item md={12} sx={{ background: '#f5f5f5', p: 2 }}>
              <TypographyTitulo>meta física</TypographyTitulo>
            </Grid>

            <Grid item md={12} sx={{ textAlign: 'center' }}>
              {rowsMetaGeral.metasFisicas?.length === 0 && (
                <Box sx={{ textTransform: 'uppercase', fontSize: 11, my: 2 }}>
                  Nenhuma Meta Física Cadastrada
                </Box>
              )}
              {rowsMetaGeral?.metasFisicas?.map((metaFisica: any, indexMetaFisica: number) => (
                <Accordion key={metaFisica} sx={{ boxShadow: 0, p: 0 }}>
                  <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                    <TypographyBody>
                      <span
                        style={{
                          fontWeight: '700',
                          margin: 5,
                        }}
                      >
                        {indexMetaFisica + 1}.
                      </span>
                      {metaFisica.nome}
                    </TypographyBody>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container px={2} spacing={2}>
                      <Grid item md={6}>
                        <TypographyTitulo>Natureza</TypographyTitulo>
                      </Grid>
                      <Grid item md={6}>
                        <TypographyTitulo>Despesa</TypographyTitulo>
                      </Grid>
                      <Grid item md={6}>
                        <TypographyBody>{metaFisica.natureza}</TypographyBody>
                      </Grid>
                      <Grid item md={6}>
                        <TypographyBody>{metaFisica.nomeDespesa}</TypographyBody>
                      </Grid>
                      <Grid item md={12}>
                        <Divider />
                      </Grid>
                      <Grid item md={4}>
                        <TypographyTitulo>Instituição Beneficiada </TypographyTitulo>
                      </Grid>
                      <Grid item md={4}>
                        <TypographyTitulo>Valor Inicial</TypographyTitulo>
                      </Grid>
                      <Grid item md={4}>
                        <TypographyTitulo>Unidade</TypographyTitulo>
                      </Grid>

                      {metaFisica.metasInstituicoes.map(
                        (metaFisicaInstituicao: any, indexMetaFisicaInstituicao: number) => (
                          <>
                            <Grid item md={4}>
                              <TypographyBody>{metaFisicaInstituicao.sigla}</TypographyBody>
                            </Grid>

                            <Grid item md={4}>
                              <TypographyBody>
                                R$ {convertToCentavosCentavos(metaFisicaInstituicao.valorInicial)}
                              </TypographyBody>
                            </Grid>
                            <Grid item md={4}>
                              <TypographyBody>
                                {metaFisicaInstituicao.unidade || 'Não se Aplica'}
                              </TypographyBody>
                            </Grid>
                          </>
                        )
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>

            <Grid item lg={12} md={12} sx={{ textAlign: 'right' }}>
              <Button
                sx={{ mx: 2 }}
                href="/planejamento/fundonacional/autorizacao"
                variant="contained"
                color="light"
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                Voltar
              </Button>
              {rowsMetaGeral.status === 'Pendente' &&
                rowsMetaGeral.planoAplicacao?.status === 'Autorizado' &&
                id && (
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<TaskAltIcon />}
                    onClick={() => handleButtonClick()}
                  >
                    Autorizar
                  </Button>
                )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: {
              md: 'none',
              sm: 'block',
              xs: 'block',
            },
          }}
        >
          <Alert severity="warning">
            É NECESSÁRIO ESTÁ NO COMPUTADOR PARA VISUALIZAR E AUTORIZAR O PLANO!
          </Alert>
          <Button
            variant="contained"
            href="/planejamento/fundonacional/autorizacao"
            color="info"
            sx={{ m: 2 }}
            startIcon={<SubdirectoryArrowLeftIcon />}
          >
            Voltar
          </Button>
        </Box>
      </Stack>
      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Meta Geral"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
