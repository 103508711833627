import * as yup from 'yup';

interface Permissoes {
  funcao: string;
  modulo: string;
}

export interface IUsuarioData {
  id?: string;
  nome: string;
  email: string;
  senha: string;
  cpf: string;
  criadoEm?: Date;
  atualizadoEm?: Date;
  permissoes: Permissoes[];
  permissoesUsuario?: object[];
}

export const schemaUsuario = yup.object<IUsuarioData>({
  nome: yup.string().required('Nome é obrigatório'),
  email: yup.string().required('E-mail é obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .transform((value: any) => {
      const limpandoString = value.replace(/[.-]/g, '');
      return limpandoString;
    }),
  senha: yup.string(),
  permissoes: yup.array().of(
    yup.object().shape({
      funcao: yup.string().required('Função é obrigatória'),
      modulo: yup.string(),
    })
  ),
});

export const defaultValuesUsuario: IUsuarioData = {
  nome: '',
  email: '',
  senha: '',
  cpf: '',
  permissoes: [],
  permissoesUsuario: [],
};
