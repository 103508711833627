import { Grid, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { useEffect } from 'react';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import { IInstituicaoData } from 'src/pages/Admin/Unidades/Formulario/type';
import PaginatedResults from 'src/services/endpointsResponse';

export function InstituicaoBeneficiada({
  indexInstituicao,
  index,
  rowInstituicoes,
  instituicaoBeneficiada,

  setValue,
  excluirInstituicao,
}: {
  indexInstituicao: number;
  index: number;
  rowInstituicoes: PaginatedResults<IInstituicaoData>;
  instituicaoBeneficiada: any;
  setValue: UseFormSetValue<FieldValues>;
  excluirInstituicao: (indexInst: number) => void;
}) {
  const idInstituicao = useWatch({
    name: `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].instituicaoId`,
  });
  const instituicaoSelecionadas = useWatch({ name: `metasFisicas[${index}].metasInstituicoes` });
  const valorInicial = useWatch({
    name: `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].valorInicial`,
  });

  const { data: rowsUnidades } = useQuery({
    queryKey: ['getUnidadesInstituicao', idInstituicao],
    queryFn: () => serviceGet('unidade', {}, idInstituicao),
    enabled: !!idInstituicao,
  });
  useEffect(() => {
    if (valorInicial >= 0) {
      setValue(
        `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].saldoEstimativo`,
        valorInicial
      );
      setValue(
        `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].saldoReal`,
        valorInicial
      );
    }
  }, [index, indexInstituicao, setValue, valorInicial]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3.5}>
        <CustomSelect
          label="Instituição"
          name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].instituicaoId`}
          options={
            rowInstituicoes?.results
              ?.filter((item) => item.id !== undefined)
              .map((item) => ({
                id: item.id as string,
                nome: item.sigla,
                disabled: instituicaoSelecionadas?.some(
                  (inst: any) => inst.instituicaoId === item.id
                ),
              })) ?? []
          }
        />
      </Grid>
      {(rowsUnidades?.total ?? 0) > 0 && (
        <Grid item xs={12} md={4}>
          <CustomSelect
            label="Unidade"
            name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].unidadeId`}
            options={
              rowsUnidades?.results
                ?.filter((item) => item.id !== undefined)
                .map((item) => ({
                  id: item.id as string,
                  nome: item.sigla,
                })) ?? []
            }
          />
        </Grid>
      )}

      <Grid item xs={12} md={4}>
        <TextFieldMoney
          label="Valor Inicial"
          name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].valorInicial`}
        />
      </Grid>
      <Grid item xs={12} md={0.5} sx={{ textAlign: 'right', my: 1 }}>
        <IconButton
          aria-label="delete"
          disabled={instituicaoBeneficiada.length === 1}
          color="error"
          sx={{ my: 2 }}
          onClick={() => excluirInstituicao(indexInstituicao)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
