import { Box, Grid, Paper, Table, TableHead, TableRow, Typography } from '@mui/material';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import { tratandoData, convertToCentavosCentavos } from 'src/config/utils';
import GovFed from 'src/assets/images/gov-fed.png';
import { IEixosFinanciadosData, planoAplicacaoResponse } from '../PlanoAplicacao/Formulario/type';
import { IMetaGeralData } from '../Metas/Cadastro/MetaGeral/type';

export default function RelatorioEmpenhos({
  rowsPlano,
  id,
  rowEmpenhos,
  rowsEixoFinanciadoMeta,
}: {
  rowsPlano: planoAplicacaoResponse;
  id: string;
  rowsEixoFinanciadoMeta: IEixosFinanciadosData & {
    metasGerais: IMetaGeralData[];
  };
  rowEmpenhos: any;
}) {
  return (
    <Grid item md={12}>
      <Paper sx={{ height: '65vh', p: 3, overflow: 'auto' }} id={id}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead size="small">
                  <img src={GovFed} alt="" width={100} />
                </TableCellHead>
                <TableCellHead colSpan={10}>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    PLANO DE AÇÃO
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsPlano.exercicioOrcamentario}
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    AC
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow className="vertical">
                <TableCellHead
                  id="fundo"
                  size="small"
                  colSpan={14}
                  sx={{
                    background: '#FEE69C',
                  }}
                >
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsEixoFinanciadoMeta.eixoNome}
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow>
                <TableCellHead
                  className="sub-title"
                  colSpan={14}
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>EMPENHOS</Typography>
                </TableCellHead>
              </TableRow>

              <TableRow>
                <TableCellHead
                  className="sub-title"
                  colSpan={3}
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    NÚMERO DO EMPENHO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    DATA DO EMEPENHO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR EMPENHADO</Typography>
                </TableCellHead>

                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    VALOR COMPLEMENTAR
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR EXECUTADO</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR ANULADO</Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>SALDO</Typography>
                </TableCellHead>
                <TableCellHead
                  colSpan={6}
                  className="sub-title"
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>DESCRIÇÃO</Typography>
                </TableCellHead>
              </TableRow>
            </TableHead>

            {rowEmpenhos?.map((objeto: any) => (
              <TableRow key={objeto.id}>
                <TableCellBody align="center" colSpan={3}>
                  {objeto.numeroEmpenho}
                </TableCellBody>
                <TableCellBody align="center">{tratandoData(objeto.dataEmpenho)}</TableCellBody>
                <TableCellBody align="center">
                  R$ {convertToCentavosCentavos(objeto.valorEmpenhado)}
                </TableCellBody>
                <TableCellBody align="center">
                  R$ {convertToCentavosCentavos(objeto.valorComplemento)}
                </TableCellBody>
                <TableCellBody align="center">
                  R$ {convertToCentavosCentavos(objeto.valorPago)}
                </TableCellBody>
                <TableCellBody align="center">
                  R$ {convertToCentavosCentavos(objeto.valorAnulado)}
                </TableCellBody>
                <TableCellBody align="center">
                  R$
                  {convertToCentavosCentavos(
                    objeto.valorEmpenhado +
                      objeto.valorComplemento -
                      objeto.valorPago -
                      objeto.valorAnulado
                  )}
                </TableCellBody>
                <TableCellBody colSpan={6} align="center">
                  {objeto.descricao}
                </TableCellBody>
              </TableRow>
            ))}
          </Table>
        </Box>
      </Paper>
    </Grid>
  );
}
