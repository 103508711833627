import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  CalculandoPorcentagem,
  convertToCentavosCentavos,
  tratandoData,
  valorMonetario,
} from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from 'react';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { GridColDef } from '@mui/x-data-grid';
import { serviceGet } from 'src/services';
import TableContainerCustom from 'src/components/Table';
import { set } from 'react-hook-form';
import { ApiEmpenho } from 'src/services/Api';
import CustomModal from 'src/components/Modal';
import CustomTabs from 'src/components/CustomTabs';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { CardValue } from 'src/components/CustomCard';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textAlign: 'center',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '15px',
  color: '#555555',
  textAlign: 'center',
  textTransform: 'uppercase',
}));

export default function FinanceiroVisualizaDetalhes({ id }: { id: string }) {
  const include = ['metaFisicaCompleto', 'metasInstituicoes'];

  const [valores, setValores] = useState({
    valorTotalInicial: 0,
    valorTotalEmpenhado: 0,
    valorTotalExecutado: 0,
    valorTotalSaldoEstimativo: 0,
    valorTotalSaldoReal: 0,
    valorTotalAprovisionado: 0,
  });

  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => serviceGet('metaFisicalId', { include }, id),
  });
  const { data: aprov } = useQuery({
    queryKey: ['getAprovisionamento', id, include],
    queryFn: () => serviceGet('aprovisionamentoMeta', {}, id),
  });

  useEffect(() => {
    if (rowsMeta) {
      const novosValores = rowsMeta.metasInstituicoes.reduce(
        (acc: any, item: any) => ({
          valorTotalInicial: acc.valorTotalInicial + item.valorInicial,
          valorTotalEmpenhado: acc.valorTotalEmpenhado + item.valorEmpenhado,
          valorTotalExecutado: acc.valorTotalExecutado + item.valorExecutado,
          valorTotalSaldoEstimativo: acc.valorTotalSaldoEstimativo + item.saldoEstimativo,
          valorTotalSaldoReal: acc.valorTotalSaldoReal + item.saldoReal,
          valorTotalAprovisionado:
            acc.valorTotalAprovisionado + item.valorAprovisionado ? item.valorAprovisionado : 0,
        }),
        {
          valorTotalInicial: 0,
          valorTotalEmpenhado: 0,
          valorTotalExecutado: 0,
          valorTotalSaldoEstimativo: 0,
          valorTotalSaldoReal: 0,
          valorTotalAprovisionado: 0,
        }
      );

      setValores(novosValores);
    }
    if (aprov && aprov?.total > 0) {
      setValores((prevState) => ({
        ...prevState,
        valorTotalAprovisionado: aprov.results?.reduce(
          (acc, item) =>
            item.status === 'RESERVADO' ? acc + item.valorAprovisionado - item.valorUtilizado : acc,
          0
        ),
      }));
    }
  }, [aprov, rowsMeta]);

  return (
    <Box
      sx={{
        py: 2,
      }}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={2}>
                <CardValue
                  title="VALOR PLANEJADO"
                  value={`R$ ${convertToCentavosCentavos(valores.valorTotalInicial)}`}
                  color="#3498db"
                />
              </Grid>
              <Grid item md={2}>
                <CardValue
                  title="VALOR EMPENHADO"
                  value={`R$ ${convertToCentavosCentavos(valores.valorTotalEmpenhado)}`}
                  color="#f1c40f"
                />
              </Grid>

              <Grid item md={2}>
                <CardValue
                  title="APROVISIONADO"
                  color="#e68d1e"
                  value={`R$ ${convertToCentavosCentavos(valores.valorTotalAprovisionado)}`}
                />
              </Grid>
              <Grid item md={2}>
                <CardValue
                  title="SALDO ESTIMADO"
                  value={`R$ ${convertToCentavosCentavos(valores.valorTotalSaldoEstimativo)}`}
                  color="#007aff"
                />
              </Grid>
              <Grid item md={2}>
                <CardValue
                  title="VALOR EXECUTADO"
                  value={`R$ ${convertToCentavosCentavos(valores.valorTotalExecutado)}`}
                  color="#e74c3c"
                />
              </Grid>
              <Grid item md={2}>
                <CardValue
                  title="SALDO REAL"
                  value={`R$ ${convertToCentavosCentavos(valores.valorTotalSaldoReal)}`}
                  color="#4cd964"
                />
              </Grid>
            </Grid>

            <TableContainer>
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
                  <TableRow>
                    <TableCellHead>INSTITUIÇÃO</TableCellHead>
                    <TableCellHead>VALOR Planejado</TableCellHead>
                    <TableCellHead>REMANEJAMENTO - RECEBIDO</TableCellHead>
                    <TableCellHead>REMANEJAMENTO - RETIRADO</TableCellHead>
                    <TableCellHead>RENDIMENTO ALOCADO</TableCellHead>
                    <TableCellHead>VALOR EMPENHADO</TableCellHead>
                    <TableCellHead>VALOR EXECUTADO</TableCellHead>
                    <TableCellHead>SALDO ESTIMAdo</TableCellHead>
                    <TableCellHead>SALDO REAL</TableCellHead>
                    <TableCellHead>% Execução</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsMeta?.metasInstituicoes.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCellBody>{item.sigla}</TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.valorInicial)}
                      </TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.saldoRecebido)}
                      </TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.saldoRetirado)}
                      </TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.saldoRecebidoRendimentos)}
                      </TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.valorEmpenhado)}
                      </TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.valorExecutado)}
                      </TableCellBody>
                      <TableCellBody>
                        R$ {convertToCentavosCentavos(item.saldoEstimativo)}
                      </TableCellBody>
                      <TableCellBody>R$ {convertToCentavosCentavos(item.saldoReal)}</TableCellBody>
                      <TableCellBody>
                        {CalculandoPorcentagem(
                          item.valorExecutado,
                          item.valorExecutado + item.saldoReal
                        )}
                        %
                      </TableCellBody>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
