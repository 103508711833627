import { Box, Grid, Chip, Alert, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CalculandoPorcentagem } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { serviceGet } from 'src/services';
import CustomModal from 'src/components/Modal';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaGeralVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: () => void;
}) {
  const include = ['eixoFinanciado', 'user', 'planoAplicacao', 'metasFisicas'];
  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetasGerais', id, include],
    queryFn: () => serviceGet('metaGeralId', { include }, id),
  });

  function CalculandoPorcetagemGeral(metasInstituicoes: any[]) {
    const novosValores = metasInstituicoes.reduce(
      (acc: any, item: any) => ({
        valorTotalInicial: acc.valorTotalInicial + item.valorInicial,
        valorTotalEmpenhado: acc.valorTotalEmpenhado + item.valorEmpenhado,
        valorTotalExecutado: acc.valorTotalExecutado + item.valorExecutado,
        valorTotalSaldoEstimativo: acc.valorTotalSaldoEstimativo + item.saldoEstimativo,
        valorTotalSaldoReal: acc.valorTotalSaldoReal + item.saldoReal,
      }),
      {
        valorTotalInicial: 0,
        valorTotalEmpenhado: 0,
        valorTotalExecutado: 0,
        valorTotalSaldoEstimativo: 0,
        valorTotalSaldoReal: 0,
      }
    );

    return CalculandoPorcentagem(
      novosValores.valorTotalExecutado,
      novosValores.valorTotalExecutado + novosValores.valorTotalSaldoReal
    );
  }
  return (
    <div>
      {!isPending && rowsMeta && (
        <CustomModal
          open={open}
          onClose={handleClose}
          title="Meta Geral - Detalhes"
          icon={<VisibilityIcon color="primary" sx={{ fontSize: 25 }} />}
        >
          <Grid container>
            <Grid md={12} xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead sx={{ background: '#f5f5f5' }}>
                    <TableRow>
                      <TableCellHead align="center">Exercicío Orçamentário</TableCellHead>
                      <TableCellHead>Eixo</TableCellHead>
                      <TableCellHead colSpan={2}>NOME</TableCellHead>

                      <TableCellHead align="center">Situação</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCellBody align="center">
                        {rowsMeta?.planoAplicacao?.exercicioOrcamentario}
                      </TableCellBody>
                      <TableCellBody>{rowsMeta?.eixoFinanciado?.eixoNome}</TableCellBody>
                      <TableCellBody colSpan={2}>{rowsMeta?.nome}</TableCellBody>

                      <TableCellBody align="center">
                        <Chip
                          label={rowsMeta.status}
                          sx={{ color: 'white' }}
                          color={rowsMeta.status === 'Pendente' ? 'warning' : 'success'}
                        />
                      </TableCellBody>
                    </TableRow>
                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead colSpan={3}>Meta Física</TableCellHead>
                      <TableCellHead align="center">Natureza</TableCellHead>
                      <TableCellHead align="center">% Execução</TableCellHead>
                    </TableRow>
                    {rowsMeta?.metasFisicas?.map((item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCellBody colSpan={3}>
                          {index + 1} - {item.nome}
                        </TableCellBody>
                        <TableCellBody align="center">{item.natureza}</TableCellBody>
                        <TableCellBody align="center">
                          {CalculandoPorcetagemGeral(item.metasInstituicoes)} %
                        </TableCellBody>
                      </TableRow>
                    ))}
                    <TableRow sx={{ textAlign: 'center' }}>
                      {rowsMeta && rowsMeta.metasFisicas?.length === 0 && (
                        <TableCellBody align="center" colSpan={12}>
                          Nenhum registro encontrado
                        </TableCellBody>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {rowsMeta.status === 'Autorizado' && (
              <Box my={2}>
                <Alert severity="info" sx={{ fontSize: 13, fontWeight: 500 }}>
                  Autorizado por : {rowsMeta?.usuarioAuthorization?.nome}
                </Alert>
              </Box>
            )}
          </Grid>
        </CustomModal>
      )}
    </div>
  );
}
