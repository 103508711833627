import { Alert, Button, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useEffect } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import SaveIcon from '@mui/icons-material/Save';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import DrawIcon from '@mui/icons-material/Draw';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import CloseIcon from '@mui/icons-material/Close';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { servicePatch } from 'src/services';
import CustomModal from 'src/components/Modal';
import { IPlanoAplicacaoData, schemaPlanoAplicacaoNovoPrazo } from '../Formulario/type';

export default function CadastrarNovoPrazo({
  RefetchPlano,
  open,
  handleClose,
  planoAplicacaoId,
}: {
  RefetchPlano: (arg0: boolean) => void;
  open: boolean;
  handleClose: () => void;
  planoAplicacaoId: string;
}) {
  const { state } = useContext(AuthContext);
  const methods = useForm({
    resolver: yupResolver(schemaPlanoAplicacaoNovoPrazo),
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (data: IPlanoAplicacaoData) =>
      servicePatch('planoAplicacao', { ...data }, planoAplicacaoId),
    onSuccess: (data) => {
      ToastFun('Prazo atualizado com sucesso!', 'success');
      handleClose();
      RefetchPlano(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  useEffect(() => {
    if (planoAplicacaoId) {
      methods.setValue('planoAplicacaoId', planoAplicacaoId);
    }
  }, [methods, planoAplicacaoId]);

  const onSubmit = async (data: any) => {
    data.usuarioUpdateId = state.usuario?.id;
    data.atualizadoEm = new Date();

    mutate(data);
  };
  return (
    <CustomModal
      open={open}
      size="sm"
      onClose={handleClose}
      title="Prazo de Execução"
      icon={<EditNoteIcon color="warning" sx={{ fontSize: 30 }} />}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <Grid container>
              <Grid md={12} xs={12}>
                <TextFieldRG label="Prazo de Execução" name="novoPrazoExecucao" type="date" />
              </Grid>
              <Grid item xs={12} md={12} sx={{ textAlign: 'right', pt: 4 }}>
                <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
                  Atualizar
                </Button>
              </Grid>
            </Grid>
          </FormsProvider>
        </Grid>
      </Grid>
    </CustomModal>
  );
}
