import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Divider, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import { AuthContext } from 'src/Context/ContextUser';

const style = {
  position: 'absolute' as 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  maxWidth: 500,
  minWidth: 250,
  background: 'white',
  borderRadius: '10px',
  p: 2,
};

export default function ModalAutorizar({
  isOpen,
  nomeAcao,
  mutate,
  handleClose,
  id,
}: {
  isOpen: boolean;
  nomeAcao: string;
  id: string;
  mutate: any;
  handleClose: () => void;
}) {
  const { state } = useContext(AuthContext);
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          borderRadius: 2,
          p: 3,
          boxShadow: 24,
        }}
      >
        <Grid container spacing={2} sx={{ pb: 2 }}>
          <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
            <WarningAmberIcon
              color="warning"
              sx={{ fontSize: 80, p: 2, background: '#fcebd4', borderRadius: '50%' }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="h5" sx={{ color: '#6D6C6C', fontWeight: 'bold' }}>
              Autorizar {nomeAcao}?
            </Typography>
            <Typography variant="body2" sx={{ color: '#6D6C6C', mt: 1 }}>
              Você tem certeza que deseja autorizar {nomeAcao}? Esta ação não poderá ser desfeita.
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} sx={{ pt: 4 }} justifyContent="flex-end">
          <Grid item xs={12} sm={5} md={2.5}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<ClearIcon />}
              onClick={handleClose}
              color="error"
            >
              NÃO
            </Button>
          </Grid>
          <Grid item xs={12} sm={5} md={2.5}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<CheckIcon />}
              onClick={() => {
                mutate({ id, usuarioAuthorizationId: state.usuario?.id });
                handleClose();
              }}
              color="success"
            >
              SIM
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
