import { Alert, Box, Grid, Typography } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useWatch } from 'react-hook-form';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import { eixosResponse, IEixosFinanciadosData } from './type';

interface Eixos {
  index: number;
  eixosCadadastrados: eixosResponse[] | [];
  eixosList: IEixosFinanciadosData[];
  excluirEixo: (index: number) => void;
}

export default function EixoForms({ index, excluirEixo, eixosCadadastrados, eixosList }: Eixos) {
  const idEixoFinanciado = useWatch({ name: `eixosFinanciados[${index}].id` });
  const count = useWatch({ name: `eixosFinanciados[${index}].countMetasFisica` });

  return (
    <Grid container spacing={2} mb={5}>
      <Grid item xs={12} md={12}>
        <Box
          px={1}
          my={1}
          sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}
        >
          <Grid container>
            <Grid item md={6} xs={10}>
              <Typography variant="subtitle2" sx={{ py: 1 }}>
                Eixo Financiado
              </Typography>
            </Grid>
            <Grid item md={6} xs={2} sx={{ textAlign: 'right', py: 0.5 }}>
              <IconButton
                disabled={!!idEixoFinanciado}
                color="error"
                size="small"
                onClick={() => excluirEixo(index)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* SELECT EIXO */}
      <Grid item xs={12} md={12}>
        <Box display="grid">
          <CustomSelect
            label="Eixo"
            name={`eixosFinanciados[${index}].eixoId`}
            options={eixosCadadastrados.map((item) => ({
              id: item.id,
              nome: item.nome,
              disabled: eixosList?.some((inst) => inst.eixoId === item.id),
            }))}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="subtitle2">Investimento</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixosFinanciados[${index}].agenciaInvestimento`}
          label="Agência"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixosFinanciados[${index}].contaInvestimento`}
          label="Conta"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldMoney
          disabled={count}
          label="Valor Investimento"
          name={`eixosFinanciados[${index}].valorInicialInvestimento`}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="subtitle2">Custeio</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixosFinanciados[${index}].agenciaCusteio`}
          label="Agência"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG type="text" name={`eixosFinanciados[${index}].contaCusteio`} label="Conta" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldMoney
          disabled={count}
          label="Valor Custeio"
          name={`eixosFinanciados[${index}].valorInicialCusteio`}
        />
      </Grid>
      <Grid item md={12}>
        {count > 0 && (
          <Alert severity="warning" sx={{ fontSize: '0.8rem' }}>
            Não será possível alterar o valor caso existam metas vinculadas a este eixo.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}
