import * as yup from 'yup';

export const schemaUnidade = yup.object({
  instituicaoId: yup.string().required('Campo Obrgatório'),
  nome: yup.string().required('Campo Obrgatório'),
  sigla: yup.string().required('Campo Obrgatório'),
});

export interface IUnidadeData {
  id?: string;
  nome: string;
  sigla: string;
  instituicaoId: string;
}
export interface IInstituicaoData {
  id?: string;
  nome: string;
  sigla: string;
}
