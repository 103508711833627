import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import CustomTabs from 'src/components/CustomTabs';
import MetaFisicaVisualizaDetalhes from './MetasFisicas/detalhes';
import MetaFisicaVisualizaRemanejamentos from './MetasFisicas/remanejamento';
import MetaFisicaVisualizaRendimentos from './MetasFisicas/rendimento';
import FinanceiroVisualizaDetalhes from './MetasFisicas/financeiro';
import AprovisionamentoVisualizaDetalhes from './MetasFisicas/aprovisionamento';
import EmpenhosPagamentosVisualizaDetalhes from './MetasFisicas/empenhos_pagamentos';

export default function MetaFisicaVisualizar() {
  const { id } = useParams();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const listTabs = [
    {
      label: 'Detalhes',
      children: <MetaFisicaVisualizaDetalhes id={id!} />,
    },
    {
      label: 'Remanejamento',
      children: <MetaFisicaVisualizaRemanejamentos id={id!} />,
    },
    {
      label: 'Rendimentos',
      children: <MetaFisicaVisualizaRendimentos id={id!} />,
    },
    {
      label: 'Financeiro',
      children: <FinanceiroVisualizaDetalhes id={id!} />,
    },
    {
      label: 'Aprovisionamento',
      children: <AprovisionamentoVisualizaDetalhes id={id!} />,
    },
    {
      label: 'Empenhos e Pagamentos',
      children: <EmpenhosPagamentosVisualizaDetalhes id={id!} />,
    },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <CustomBreadcrumbs
            heading={`Meta Física - ${listTabs[value]?.label}`}
            links={[
              { name: 'Planejamento', href: '/planejamento' },
              { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
              { name: 'Metas', href: '/planejamento/fundonacional/metas' },
              { name: `${listTabs[value].label}`, href: '#' },
            ]}
          />
        </Grid>

        <Grid item md={6} sx={{ mt: 2 }}>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              startIcon={<SubdirectoryArrowLeftIcon />}
              variant="contained"
              color="light"
              href="/planejamento/fundonacional/metas"
            >
              Voltar
            </Button>
          </Box>
        </Grid>
        <CustomTabs listTabs={listTabs} value={value} handleChange={handleChange} />
      </Grid>
    </Box>
  );
}
