import { Box, Chip, Grid, styled } from '@mui/material';
import { convertToCentavosCentavos } from 'src/config/utils';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import TableContainer from 'src/components/Table';

export default function MetaFisicaVisualizaRemanejamentos({ id }: { id: string }) {
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'acao',
      headerName: 'AÇÃO REALIZADA',
      flex: 1,
    },
    {
      field: 'metaFisicaAcao',
      headerName: 'META FÍSICA',
      flex: 4,
    },

    {
      field: 'valorRemanejado',
      headerName: 'VALOR',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valorRemanejado)}</>,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
  ];
  return (
    <Box
      sx={{
        py: 2,
      }}
    >
      <TableContainer
        endpoint="remanejamentoMetaFisica"
        idGet={id}
        columns={columns}
        params={{}}
        setRefetch={tableRefetch}
        setTableRefetch={setTableRefetch}
      />
    </Box>
  );
}
