import { Alert, Box, Stack } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErroResponse } from 'src/pages/types';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from 'src/components/Modal';
import { serviceGet, servicePost } from 'src/services';
import EmpenhoForms from '../Formulario';
import { DadosVinculadosEmpenho, defaultValuesEmpenhos, schemaEmpenhos } from '../Formulario/type';

export default function CadastrarEmpenho({
  open,
  id,
  handleClose,
  setTableRefetch,
  refechEmp,
}: {
  open: boolean;
  id?: string;
  handleClose: () => void;
  refechEmp?: () => void;
  setTableRefetch: (arg: boolean) => void;
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { state } = useContext(AuthContext);

  const methods = useForm<DadosVinculadosEmpenho>({
    resolver: yupResolver(schemaEmpenhos),
    defaultValues: defaultValuesEmpenhos,
  });
  const include = ['metaFisicaCompleto', 'metasInstituicoes'];
  const params = {
    include,
  };
  const { data: rowsMeta } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => serviceGet('metaFisicalId', { ...params }, id),
    enabled: !!id,
  });

  useEffect(() => {
    if (rowsMeta) {
      methods.reset({
        planoAplicacaoId: rowsMeta.planoAplicacao?.id,
        eixoFinanciadoId: rowsMeta.eixosFinanciados?.id,
        natureza: rowsMeta.natureza,
        metaFisicaId: rowsMeta.id,
      });
    }
  }, [methods, rowsMeta]);

  useEffect(() => {
    const errors = methods.formState.errors;
    if (Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0] as keyof DadosVinculadosEmpenho;
      ToastFun(errors[firstErrorField]?.message || 'Preencha os campos obrigatórios', 'error');
    }
  }, [methods, methods.formState.errors]);
  const { mutate, isPending } = useMutation({
    mutationFn: (data: DadosVinculadosEmpenho) => servicePost('despesas', { ...data }),
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: ['getEmpenhos'] });
      queryClient.removeQueries({ queryKey: ['getVerificarEmpenhos'] });
      setTableRefetch(true);
      handleClose();
      refechEmp?.();
      if (!id) {
        navigate('/planejamento/fundonacional/despesas/');
      }
      ToastFun('Despesa Cadastrada com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: DadosVinculadosEmpenho) => {
    data.usuarioCreatedId = state.usuario?.id!;
    mutate(data);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <CustomModal
        open={open}
        onClose={handleClose}
        title="Despesa - Cadastro"
        icon={<EditNoteIcon color="success" sx={{ fontSize: 30 }} />}
      >
        <FormsProvider methods={methods} handleSubmit={onSubmit}>
          <EmpenhoForms methods={methods} onSubmit={methods.handleSubmit(onSubmit)} />
        </FormsProvider>
      </CustomModal>
    </Box>
  );
}
