import { Box, Modal, Grid, IconButton, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';

import ModalCarregamento from 'src/components/Modal/Carregamento';
import { criarAeronave } from 'src/services/Operacional/Ciopaer/aeronave';
import AeronaveForms from '../Formulario/aeronave';
import { schemaAeronave } from './type';

export default function CadastroAeronave({
  open,
  handleClose,
  refetch,
}: {
  open: boolean;
  handleClose: any;
  refetch: any;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaAeronave),
  });

  const { state } = useContext(AuthContext);
  const { reset } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: criarAeronave,
    onSuccess: (data) => {
      ToastFun('Aeronave cadastra com sucesso', 'success');
      handleClose();
      reset();
      refetch();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.usuarioCreateId = state.usuario?.id;
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362 ', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Aeronave - Cadastro
            </Alert>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <AeronaveForms />
            </FormsProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
