import { Button, InputAdornment, Typography } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import { fontSize } from '@mui/system';

export default function LoginForm() {
  return (
    <>
      {' '}
      <Typography variant="subtitle2">Email</Typography>
      <TextFieldRG
        size="small"
        sx={{
          my: 1,
          '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
          },
        }}
        name="email"
        fullWidth
        placeholder="Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon sx={{ color: '#c4c4c4' }} />
            </InputAdornment>
          ),
        }}
        label=""
      />
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Senha
      </Typography>
      <TextFieldRG
        size="small"
        sx={{
          my: 1,

          '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
          },
        }}
        name="senha"
        fullWidth
        type="password"
        placeholder="Senha"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsIcon sx={{ color: '#c4c4c4' }} />
            </InputAdornment>
          ),
        }}
        label=""
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{
          borderRadius: '8px',
          background: 'linear-gradient(45deg, rgba(255, 191, 0, 1), rgba(255, 140, 0, 1))',
          '&:hover': { background: 'rgb(255, 184, 5)' },
          py: 1.5,
          my: 2,
        }}
      >
        ENTRAR
      </Button>
    </>
  );
}
