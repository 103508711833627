import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData, convertToCentavosCentavos } from 'src/config/utils';
import SearchIcon from '@mui/icons-material/Search';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AtualizarPlano from './Atualizar';
import CadastrarNovoPrazo from './Cadastro/novoPrazo';

export default function PlanoAplicacao() {
  const [idPlano, setIdPlano] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalType, setIsModalType] = useState('');
  const [search, setSearch] = useState<string>('');
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const include = ['eixoFinanciadoCount', 'user'];
  const params = { filters: { search }, include };

  const handleButtonClick = (type: string, id: string) => {
    setIsModalType(type);
    setIdPlano(id);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIdPlano(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => <>{cellParams.row.exercicioOrcamentario}</>,
    },

    {
      field: 'countEixos',
      headerName: 'EIXOS FINANCIADOS',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
    },

    {
      field: 'valorInicialCusteioEixos',
      headerName: 'VALOR CUSTEIO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (cellParams) => (
        <>R$ {convertToCentavosCentavos(cellParams.row.valorInicialCusteioEixos)}</>
      ),
    },
    {
      field: 'valorInicialInvestimentoEixos',
      headerName: 'VALOR INVESTIMENTO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (cellParams) => (
        <>R$ {convertToCentavosCentavos(cellParams.row.valorInicialInvestimentoEixos)}</>
      ),
    },
    {
      field: 'valorTotal',
      headerName: 'VALOR TOTAL',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => (
        <>
          R${' '}
          {convertToCentavosCentavos(
            cellParams.row.valorInicialInvestimentoEixos + cellParams.row.valorInicialCusteioEixos
          )}
        </>
      ),
    },

    {
      field: 'prazoExecucao',
      headerName: 'PRAZO DE EXECUÇÃO',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,

      align: 'center',
      renderCell: (cellParams) =>
        tratandoData(
          cellParams.row.novoPrazoExecucao !== null
            ? cellParams.row.novoPrazoExecucao
            : cellParams.row.prazoExecucao
        ),
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,

      renderCell: (cellParams) => (
        <Chip
          label={cellParams.row.status}
          sx={{ color: 'white', fontWeight: '600' }}
          color={cellParams.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (cellParams) => (
        <Box>
          <IconButton color="primary" href={`plano/${cellParams.row.id}`}>
            <VisibilityIcon />
          </IconButton>

          <IconButton
            disabled={cellParams.row.status !== 'Pendente'}
            color="warning"
            onClick={() => handleButtonClick('editar', cellParams.row.id)}
          >
            <ModeEditIcon />
          </IconButton>
          <IconButton
            disabled={cellParams.row.status === 'Pendente'}
            color="warning"
            onClick={() => handleButtonClick('novoPrazo', cellParams.row.id)}
          >
            <EditCalendarIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <Stack spacing={1}>
        <CustomBreadcrumbs
          heading="Plano de Aplicação "
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Plano de Aplicação', href: '/planejamento/fundonacional/planodeaplicacao' },
          ]}
        />
        <Grid container>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }}>
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={9} sx={{ my: 2, textAlign: 'right' }}>
            <Button
              sx={{ width: { xs: '100%', md: '150px' } }}
              href="/planejamento/fundonacional/planodeaplicacao/cadastro"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Plano
            </Button>
          </Grid>
          <Divider />
          <Grid item xs={12} mt={2}>
            <TableContainer
              endpoint="planoAplicacao"
              columns={columns}
              params={params}
              setRefetch={tableRefetch}
              setTableRefetch={setTableRefetch}
            />
          </Grid>
        </Grid>
      </Stack>

      {idPlano && isModalOpen && isModalType === 'editar' && (
        <AtualizarPlano
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={idPlano}
          RefetchPlano={setTableRefetch}
        />
      )}

      {idPlano && isModalOpen && isModalType === 'novoPrazo' && (
        <CadastrarNovoPrazo
          open={isModalOpen}
          handleClose={handleCloseModal}
          planoAplicacaoId={idPlano}
          RefetchPlano={setTableRefetch}
        />
      )}
    </Box>
  );
}
