import { Box, Stack, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import CustomTabs from 'src/components/CustomTabs';
import PlanoAplicacaoTabelaAutorizacao from './Tabelas/PlanoAplicacao';
import MetasGeraisTabelaAutorizacao from './Tabelas/MetasGerais';
import RemanejamentosAutorizacao from './Tabelas/Remanejamentos';
import RendimentosTabelaAutorizacao from './Tabelas/Rendimentos';
import RendimentosAlocacao from './Tabelas/DestinacaoRendimento';

export default function Autorizacao() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const listTabs = [
    { label: 'Plano de Aplicação', children: <PlanoAplicacaoTabelaAutorizacao /> },
    { label: 'Metas Gerais', children: <MetasGeraisTabelaAutorizacao /> },
    { label: 'Remanejamentos', children: <RemanejamentosAutorizacao /> },
    { label: 'Rendimentos', children: <RendimentosTabelaAutorizacao /> },
    { label: 'Destinação de Rendimentos', children: <RendimentosAlocacao /> },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading={`Autorização -  ${listTabs[value].label}`}
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Autorização', href: '/planejamento/fundonacional/autorizacao' },
          ]}
        />
        <CustomTabs listTabs={listTabs} value={value} handleChange={handleChange} />
      </Stack>
    </Box>
  );
}
