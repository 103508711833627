import { Grid, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';

export default function FormularioRendimento({ setValue }: { setValue: any }) {
  const [conta, setConta] = useState();

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });

  const natureza = useWatch({ name: 'natureza' });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  useEffect(() => {
    if (planoAplicacaoId && planoAplicacaoId && natureza) {
      if (eixoFinanciadoId && eixoFinanciadoId && natureza) {
        eixosFinanciados?.results?.forEach((element: { [key: string]: any; id?: string }) => {
          const propertyName = `conta${natureza}`;

          if (element[propertyName] !== undefined && eixoFinanciadoId === element.id) {
            setConta(element[propertyName]);
            setValue('conta', element[propertyName]);
          }
        });
      }
    }
  });
  const naturezaDespesa = [
    { id: 'Investimento', nome: 'Investimento' },
    { id: 'Custeio', nome: 'Custeio' },
  ];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={6}>
        <CustomSelect
          label="Eixo/Área Temática"
          name="eixoFinanciadoId"
          options={
            eixosFinanciados?.results?.map((item) => ({
              id: item.id ?? '',
              nome: item.eixoNome ?? '',
            })) ?? []
          }
        />
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <CustomSelect
          label="Natureza"
          name="natureza"
          options={
            naturezaDespesa?.map((item) => ({
              id: item.id,
              nome: item.nome,
            })) ?? []
          }
        />
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG placeholder="Conta" label="" value={conta} name="conta" disabled />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG placeholder="" type="date" label="Data de Referência" name="dataReferencia" />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldMoney label="Valor" name="valor" />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldRG multiline rows={2} label="Observação" name="observacao" />
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
