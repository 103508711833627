import { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
//
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { FieldValues, UseFormWatch, UseFormSetValue, UseFormUnregister } from 'react-hook-form';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import { InstituicaoBeneficiada } from './InstituicaoBeneficiada';

interface Metas {
  index: number;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  excluirMetaFisica: (id: number) => void;
  watch: UseFormWatch<FieldValues>;
}

export function MetaFisicaForms({ index, setValue, unregister, excluirMetaFisica, watch }: Metas) {
  const todasInsst = watch(`metasFisicas[${index}].metasInstituicoes`);

  const [instituicaoBeneficiada, setInstituicaoBeneficiada] = useState<number[]>([0]);

  const novaInstituicao = () => {
    const novasInstituicoes = [...instituicaoBeneficiada];
    const count = novasInstituicoes[novasInstituicoes.length - 1] + 1;
    setInstituicaoBeneficiada([...novasInstituicoes, count]);
  };
  const excluirInstituicao = (indexInst: number) => {
    if (instituicaoBeneficiada.length > 1) {
      unregister(`metasFisicas[${index}].metasInstituicoes[${indexInst}]`);
      setInstituicaoBeneficiada((prevInst) => prevInst.filter((inst) => inst !== indexInst));
    } else ToastFun('É necessário que a Meta possua ao menos um instituição', 'error');
  };

  const { data: rowsTipoDespesa } = useQuery({
    queryKey: ['getTipoDespesas'],
    queryFn: () => serviceGet('tipoDespesa', {}),
  });

  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao', {}),
  });

  useEffect(() => {
    if (todasInsst?.length > 0) {
      const eixoAtualizar: number[] = [];
      todasInsst.map((_item: any, indexInst: number) => eixoAtualizar.push(indexInst));
      setInstituicaoBeneficiada(eixoAtualizar);
    }
  }, [todasInsst]);
  const naturezaDespesa = [
    { id: 'investimento', nome: 'Investimento' },
    { id: 'custeio', nome: 'Custeio' },
  ];
  return (
    <Grid container spacing={2} mb={2} p={1}>
      <Grid item xs={12} md={12}>
        <Box
          px={1}
          my={1}
          sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}
        >
          <Grid container>
            <Grid item md={6} xs={10}>
              <Box display="grid">
                <Typography variant="subtitle2" sx={{ py: 1 }}>
                  Meta Física
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={2} sx={{ textAlign: 'right', py: 0.5 }}>
              <IconButton color="error" size="small" onClick={() => excluirMetaFisica(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldRG
          multiline
          type="text"
          name={`metasFisicas[${index}].nome`}
          label="Meta Física"
        />
      </Grid>
      {/* SELECT NATUREZA DESPESA */}
      <Grid item xs={12} md={6}>
        <CustomSelect
          label="Natureza Despesa"
          name={`metasFisicas[${index}].natureza`}
          options={
            naturezaDespesa?.map((item) => ({
              id: item.id,
              nome: item.nome,
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomSelect
          label="Tipo de Despesa"
          name={`metasFisicas[${index}].tipoDespesa`}
          options={
            rowsTipoDespesa?.results
              ?.filter((item) => item.id !== undefined)
              .map((item) => ({
                id: item.id as string,
                nome: item.nome,
              })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={4}>
          <Typography variant="subtitle2" sx={{ color: '#0d5b01', py: 1 }}>
            Instituições Beneficiadas
          </Typography>
          <Button variant="soft" color="info" startIcon={<AddIcon />} onClick={novaInstituicao}>
            Instituição
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {instituicaoBeneficiada.map((item, indexInstituicao) => (
          <InstituicaoBeneficiada
            key={item}
            indexInstituicao={item}
            index={index}
            rowInstituicoes={rowInstituicoes ?? { results: [], total: 0 }}
            instituicaoBeneficiada={instituicaoBeneficiada}
            setValue={setValue}
            excluirInstituicao={excluirInstituicao}
          />
        ))}
      </Grid>
    </Grid>
  );
}
