import { convertToCentavos } from 'src/config/utils';
import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import * as yup from 'yup';

export interface rendimentoResponse {
  id?: string;
  conta: string;
  valorTotal: number;
  valorRetirado: string;
  valorDisponivel: number;
  eixoFinanciadoId: string;
  criadoEm?: Date;
  instituicaoNome?: string;
}
export const schemaRendimento = yup.object({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  conta: yup.string().required('é Obrigatório'),
  dataReferencia: yup
    .date()
    .max(new Date(), 'A data informada não pode ser superior à data atual.')
    .required('Este campo é obrigatório.'),
  valor: yup
    .number()
    .positive()
    .transform((value: number) => convertToCentavos(value))
    .required('é Obrigatório'),
});

export interface IRendimentoSolicitacaoData {
  id?: string;
  valor: number;
  dataReferencia: Date;
  status: string;
  observacao: string;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  usuarioCreatedId: string;
  usuarioAuthorizationId: string;
  rendimentoContaId: string;
  criadoEm: Date;
  atualizadoEm: Date;
}

export interface IRendimentoContaData {
  id?: string;
  conta: string;
  valorTotal: number;
  valorRetirado: number;
  valorDisponivel: number;
  eixoFinanciadoId: string;
  criadoEm: Date;
  usuarioCreated?: IUsuarioData;
}
