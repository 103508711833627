import { useEffect, useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CachedIcon from '@mui/icons-material/Cached';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalExcluir from 'src/components/Modal/Excluir';
import { serviceDelete, serviceGet } from 'src/services';
import CadastroMetaGeral from './Cadastro/MetaGeral';
import MetaGeralVisualizar from './Visualizar/MetaGeral';
import AtualizarMetaGeral from './Atualizar/MetaGeral';

export default function MetaGeral() {
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');

  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const include = ['metaFisicaCount', 'exercicioOrcamentario'];
  const dadosParams = {
    filters: { search },
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
  };

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };
  const { mutate: excluirMetaGeral } = useMutation({
    mutationFn: (idMetaGetal: string) => serviceDelete(`metaGeral`, {}, idMetaGetal),
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      setTableRefetch(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1.5,
      renderCell: (params) => <>{params.row.exercicioOrcamentario}</>,
    },
    {
      field: 'nome',
      headerName: 'META GERAL',
      flex: 6,
      minWidth: 250,
    },
    {
      field: '_count',
      headerName: 'METAS FÍSICAS',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row.dadosMetasFisicasCount}</>,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Box>
          {params.row.status === 'Pendente' && (
            <>
              <IconButton
                color="warning"
                onClick={() => handleButtonClick(params.row.id, 'Editar')}
              >
                <ModeEditIcon />
              </IconButton>
              <IconButton
                sx={{ color: 'gray' }}
                onClick={() => handleButtonClick(params.row.id, 'Excluir')}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visuzalizar')}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  useEffect(() => {
    setEixoFinanciadoId('');
  }, [planoAplicacaoId]);
  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} xl={2} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="exercicioOrcamentario"
            label="Ano"
            fullWidth
            value={planoAplicacaoId}
            onChange={(event) => setPlanoAplicacaoId(event.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              },
            }}
          >
            {rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
              <MenuItem
                key={indexExercicio}
                value={item.id}
                sx={{
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {item.exercicioOrcamentario}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} xl={3} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="eixoFinanciado"
            label="Eixo Financiado"
            fullWidth
            value={eixoFinanciadoId}
            onChange={(event) => setEixoFinanciadoId(event.target.value)}
          >
            {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} sx={{ my: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              value={search}
              size="small"
              fullWidth
              onChange={(event) => setSearch(event.target.value)}
            />
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
            <IconButton
              color="info"
              sx={{ background: '#D4EAF7   ', mx: 1 }}
              onClick={() => {
                setEixoFinanciadoId('');
                setPlanoAplicacaoId('');
                setSearch('');
              }}
            >
              <CachedIcon />
            </IconButton>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick('', 'Cadastro')}
            startIcon={<AddIcon />}
          >
            Meta Geral
          </Button>
        </Grid>
        <Grid xs={12} mt={2}>
          <TableContainer
            columns={columns}
            endpoint="metaGeral"
            setRefetch={tableRefetch}
            params={dadosParams}
            setTableRefetch={setTableRefetch}
          />
        </Grid>
      </Grid>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastroMetaGeral
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefetchMetas={setTableRefetch}
        />
      )}

      {id && isModalOpen && modalType === 'Visuzalizar' && (
        <MetaGeralVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
      )}
      {id && isModalOpen && modalType === 'Editar' && (
        <AtualizarMetaGeral
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={id}
          RefetchMetaGeral={setTableRefetch}
        />
      )}
      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Meta Geral"
          id={id}
          mutate={excluirMetaGeral}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
