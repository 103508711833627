import { Box, Grid, IconButton, Chip, Modal, Alert, styled } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuery } from '@tanstack/react-query';
import { tratandoData, convertToCentavosCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomModal from 'src/components/Modal';
import { serviceGet } from 'src/services';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function RemanejamentoVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: any;
}) {
  const { data: rowsRemanejamento, isPending } = useQuery({
    queryKey: ['getRemanejamento', id],
    queryFn: () => serviceGet('remanejamentoId', {}, id),
  });

  return (
    <div>
      {rowsRemanejamento && (
        <CustomModal
          open={open}
          onClose={handleClose}
          title="Remanejamento - Detalhes"
          icon={<VisibilityIcon color="primary" sx={{ fontSize: 25 }} />}
        >
          <Grid container spacing={1}>
            <Grid md={12} xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                  <TableHead sx={{ background: '#f5f5f5' }}>
                    <TableRow>
                      <TableCellHead>Execício Orçamentário</TableCellHead>
                      <TableCellHead colSpan={3}>Eixo Financiado</TableCellHead>
                      <TableCellHead align="center">Situação</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCellBody>
                        {rowsRemanejamento.planoAplicacao?.exercicioOrcamentario}
                      </TableCellBody>
                      <TableCellBody colSpan={3}>
                        {rowsRemanejamento.eixoFinanciado?.eixoNome}
                      </TableCellBody>
                      <TableCellBody align="center">
                        <Chip
                          label={rowsRemanejamento.status}
                          sx={{ color: 'white' }}
                          color={rowsRemanejamento.status === 'Pendente' ? 'warning' : 'success'}
                        />
                      </TableCellBody>
                    </TableRow>

                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead colSpan={4}>Meta Física - Retirada</TableCellHead>
                      {/* <TableCellHead colSpan={2}>Meta Física - Recebida</TableCellHead> */}
                      <TableCellHead align="center">Instituição - Retirada</TableCellHead>
                      {/* <TableCellHead>Instituição - Recebida</TableCellHead> */}
                    </TableRow>
                    <TableRow>
                      <TableCellBody colSpan={4}>
                        {rowsRemanejamento.metaFisicaRetirada?.nome}
                      </TableCellBody>

                      <TableCellBody align="center">
                        {rowsRemanejamento.metaInstituicaoRetirada?.sigla}
                      </TableCellBody>
                    </TableRow>
                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead colSpan={4}>Meta Física - Recebida</TableCellHead>
                      <TableCellHead align="center">Instituição - Recebida</TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellBody colSpan={4}>
                        {rowsRemanejamento.metaFisicaRecebida?.nome}
                      </TableCellBody>

                      <TableCellBody align="center">
                        {rowsRemanejamento.metaInstituicaoRecebida?.sigla}
                      </TableCellBody>
                    </TableRow>
                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead>Processo SEI</TableCellHead>
                      <TableCellHead align="center">Valor Remanejado</TableCellHead>
                      <TableCellHead align="center">Natureza</TableCellHead>
                      <TableCellHead align="center">Criado Em </TableCellHead>
                      <TableCellHead align="center">Criado Por </TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellBody>{rowsRemanejamento.processoSei}</TableCellBody>
                      <TableCellBody align="center">
                        R$ {convertToCentavosCentavos(rowsRemanejamento.valorRemanejado)}
                      </TableCellBody>
                      <TableCellBody align="center">{rowsRemanejamento.natureza}</TableCellBody>
                      <TableCellBody align="center">
                        {tratandoData(rowsRemanejamento.criadoEm)}
                      </TableCellBody>
                      <TableCellBody align="center">
                        {rowsRemanejamento.usuarioCreate?.nome}
                      </TableCellBody>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={5}>
              {rowsRemanejamento.status === 'Autorizado' && (
                <Box my={2}>
                  <Alert severity="info" sx={{ fontSize: '13', fontWeight: 500 }}>
                    Autorizado por : {rowsRemanejamento.usuarioAuthorization?.nome}
                  </Alert>
                </Box>
              )}
            </Grid>
          </Grid>
        </CustomModal>
      )}
    </div>
  );
}
