import * as yup from 'yup';

export const schemaEscala = yup.object({
  dadosEscala: yup.object({
    dataEscala: yup
      .string()
      .required('A data da escala é obrigatória')
      .matches(/\d{4}-\d{2}-\d{2}/, 'Data inválida'),
    usuarioCreatedId: yup.string(),
  }),

  AsaRotativa: yup
    .object({
      comandanteAeronaveId: yup.string().required('Comandante da aeronave é obrigatório'),
      horarioEntradaComandanteAeronave: yup
        .string()
        .required('Horário de entrada do comandante da aeronave é obrigatório'),
      horarioSaidaComandanteAeronave: yup
        .string()
        .required('Horário de saída do comandante da aeronave é obrigatório')
        .test(
          'is-greater',
          'Horário de saída deve ser maior que o horário de entrada',
          function (value) {
            return this.parent.horarioEntradaComandanteAeronave && value
              ? value > this.parent.horarioEntradaComandanteAeronave
              : true;
          }
        ),
      comandanteOpAereaId: yup.string().required('Comandante de operação aéreas é obrigatório'),
      horarioEntradaComandanteOpAereas: yup
        .string()
        .required('Horário de entrada do comandante de operação aéreas é obrigatório'),
      horarioSaidaComandanteOpAereas: yup
        .string()
        .required('Horário de saída do comandante de operação aéreas é obrigatório')
        .test(
          'is-greater',
          'Horário de saída deve ser maior que o horário de entrada',
          function (value) {
            return this.parent.horarioEntradaComandanteOpAereas && value
              ? value > this.parent.horarioEntradaComandanteOpAereas
              : true;
          }
        ),
      Operadores: yup.object({
        servidores: yup
          .array()
          .of(yup.string().required())
          .min(1, 'Ao menos um operador aerotático é obrigatório'),
        horarioEntrada: yup
          .string()
          .required('Horário de entrada do operador aerotático é obrigatório'),
        horarioSaida: yup
          .string()
          .required('Horário de saída do operador aerotático é obrigatório')
          .test(
            'is-greater',
            'Horário de saída deve ser maior que o horário de entrada',
            function (value) {
              return this.parent.horarioEntrada && value
                ? value > this.parent.horarioEntrada
                : true;
            }
          ),
      }),
    })
    .required(),

  AsaFixa: yup
    .object({
      comandanteAeronaveId: yup.string().required('Comandante da aeronave é obrigatório'),
      horarioEntradaComandanteAeronave: yup
        .string()
        .required('Horário de entrada do comandante da aeronave é obrigatório'),
      horarioSaidaComandanteAeronave: yup
        .string()
        .required('Horário de saída do comandante da aeronave é obrigatório')
        .test(
          'is-greater',
          'Horário de saída deve ser maior que o horário de entrada',
          function (value) {
            return this.parent.horarioEntradaComandanteAeronave && value
              ? value > this.parent.horarioEntradaComandanteAeronave
              : true;
          }
        ),
      comandanteOpAereaId: yup.string().required('Comandante de operação aéreas é obrigatório'),
      horarioEntradaComandanteOpAereas: yup
        .string()
        .required('Horário de entrada do comandante de operação aéreas é obrigatório'),
      horarioSaidaComandanteOpAereas: yup
        .string()
        .required('Horário de saída do comandante de operação aéreas é obrigatório')
        .test(
          'is-greater',
          'Horário de saída deve ser maior que o horário de entrada',
          function (value) {
            return this.parent.horarioEntradaComandanteOpAereas && value
              ? value > this.parent.horarioEntradaComandanteOpAereas
              : true;
          }
        ),
    })
    .required(),

  PermanenciaHangar: yup
    .object({
      servidorId: yup.string().required('Servidor é obrigatório'),
      horarioEntrada: yup.string().required('Horário de entrada é obrigatório'),
      horarioSaida: yup.string().required('Horário de saída é obrigatório'),
    })
    .required(),

  Manutencao: yup.array().of(
    yup
      .object({
        servidorId: yup.string().required('Nome do servidor é obrigatório'),
        horarioEntrada: yup.string().required('Horário de entrada é obrigatório'),
        horarioSaida: yup
          .string()
          .required('Horário de saída é obrigatório')
          .test(
            'is-greater',
            'Horário de saída deve ser maior que o horário de entrada',
            function (value) {
              return this.parent.horarioEntrada && value
                ? value > this.parent.horarioEntrada
                : true;
            }
          ),
      })
      .nullable()
  ),
});

export interface Escala {
  dadosEscala: {
    dataEscala: string;
    usuarioCreatedId: string;
  };
  AsaRotativa: AsaDetails;
  AsaFixa: AsaDetails;
  PermanenciaHangar: PermanenciaHangarDetails;
  Manutencao: ManutencaoDetails[];
}

interface AsaDetails {
  comandanteAeronaveId: string;
  horarioEntradaComandanteAeronave: string;
  horarioSaidaComandanteAeronave: string;
  comandanteOpAereaId: string;
  horarioEntradaComandanteOpAereas: string;
  horarioSaidaComandanteOpAereas: string;
  Operadores: {
    servidores: string[];
    horarioEntrada: string;
    horarioSaida: string;
  };
  Administracao: {
    servidores: string[];
    horarioEntrada: string;
    horarioSaida: string;
  };
}

interface PermanenciaHangarDetails {
  servidor: string;
  horarioEntrada: string;
  horarioSaida: string;
}

interface ManutencaoDetails {
  name: string;
  entrada: string;
  saida: string;
}
