import { useState } from 'react';
import { Box, Button, Chip, Grid, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import { convertToCentavosCentavos } from 'src/config/utils';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalExcluir from 'src/components/Modal/Excluir';
import { ToastFun } from 'src/config/functions';
import CachedIcon from '@mui/icons-material/Cached';
import { ErroResponse } from 'src/pages/types';
import { serviceDelete, serviceGet } from 'src/services';
import TableContainer from 'src/components/Table';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CadastrarRemanejamento from './Cadastrar';
import RemanejamentoVisualizar from './Visualizar';

export default function Remanejamentos() {
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  const dataParams = {
    filters: { search },
    planoAplicacaoId,
    eixoFinanciadoId,
  };
  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const apagarCampos = () => {
    setEixoFinanciadoId('');
    setPlanoAplicacaoId('');
    setSearch('');
  };
  const { mutate } = useMutation({
    mutationFn: (idRemanejamento: string) => serviceDelete('remanejamento', {}, idRemanejamento),
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      handleCloseModal();
      setTableRefetch(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };
  const columns: GridColDef[] = [
    {
      field: 'metaRetirada',
      headerName: 'META FÍSICA - RETIRADA',
      flex: 6,
      minWidth: 400,

      renderCell: (params) => (
        <span>
          {params.row.metaFisicaRetirada.nome} <br /> <br />
          <span style={{ fontWeight: 700 }}>Instituição</span> -{' '}
          <span style={{}}> {params.row.metaInstituicaoRetirada.sigla}</span>
        </span>
      ),
    },

    {
      field: 'metaRecebida',
      headerName: 'META FÍSICA - RECEBIDA',
      flex: 6,
      minWidth: 400,

      renderCell: (params) => (
        <span>
          {params.row.metaFisicaRecebida.nome} <br /> <br />
          <span style={{ fontWeight: 700 }}>Instituição</span> -{' '}
          <span style={{}}> {params.row.metaInstituicaoRecebida.sigla}</span>
        </span>
      ),
    },

    {
      field: 'valorRemanejado',
      headerName: 'VALOR',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valorRemanejado)}</>,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },

    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            sx={{ color: 'gray' }}
            disabled={params.row.status === 'Autorizado'}
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={1.5} xl={1.5} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="exercicioOrcamentario"
            label="Ano"
            fullWidth
            value={planoAplicacaoId}
            onChange={(event) => setPlanoAplicacaoId(event.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              },
            }}
          >
            {rowPlanosAplicacao &&
              rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
                <MenuItem
                  key={indexExercicio}
                  value={item.id}
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {item.exercicioOrcamentario}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} xl={3} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="eixoFinanciado"
            label="Eixo Financiado"
            fullWidth
            onChange={(event) => setEixoFinanciadoId(event.target.value)}
          >
            {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} sx={{ my: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              value={search}
              size="small"
              fullWidth
              onChange={(event) => setSearch(event.target.value)}
            />
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
            <IconButton
              color="info"
              sx={{ background: '#D4EAF7   ', mx: 1 }}
              onClick={() => {
                apagarCampos();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4.5} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick('', 'Cadastro')}
            startIcon={<AddIcon />}
          >
            Remanejamento
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        columns={columns}
        endpoint="remanejamento"
        setRefetch={tableRefetch}
        params={dataParams}
        setTableRefetch={setTableRefetch}
      />
      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Remanejamento"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
      {id && isModalOpen && modalType === 'Visualizar' && (
        <RemanejamentoVisualizar open={isModalOpen} id={id} handleClose={handleCloseModal} />
      )}
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastrarRemanejamento
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefetchRemanejamentos={setTableRefetch}
        />
      )}
    </Box>
  );
}
