import { Box, Button, Chip, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useContext, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { convertToCentavosCentavos, tratandoData } from 'src/config/utils';
import ModalAcao from 'src/components/Modal/CustomModalAcao';
import { useMutation } from '@tanstack/react-query';
import { servicePatch } from 'src/services';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { AuthContext } from 'src/Context/ContextUser';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import AprovisionamentoVisualizar from '../../../Aprovisionamento/Visualizar';
import CadastroAprovisionamento from '../../../Aprovisionamento/Cadastro';
import { getColorStatus } from '../../../Aprovisionamento';

interface Props {
  id: string;
}

const AprovisionamentoVisualizaDetalhes = ({ id }: Props) => {
  const [tableRefetch, setTableRefetch] = useState(false);
  const { state } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState<string>('');
  const [aprovisionamentoId, setAprovisionamentoId] = useState<string>('');

  const dadosCancelar = {
    status: 'CANCELADO',
    usuarioUpdateId: state.usuario?.id,
    atualizadoEm: new Date(),
  };

  const dadosDevolver = {
    status: 'DEVOLVIDO',
    usuarioUpdateId: state.usuario?.id,
    atualizadoEm: new Date(),
  };

  const { mutate: cancelarAprov } = useMutation({
    mutationFn: (idAprovisionamento: string) =>
      servicePatch('aprovisionamento', { ...dadosCancelar }, idAprovisionamento),
    onSuccess: () => {
      ToastFun('Cancelado com Sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const { mutate: devolverAprov } = useMutation({
    mutationFn: (idAprovisionamento: string) =>
      servicePatch('aprovisionamentoDevolver', { ...dadosDevolver }, idAprovisionamento),
    onSuccess: () => {
      ToastFun('Devolvido com Sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const handleButtonClick = (typeAction: string, idAprovisionamento?: string) => {
    if (idAprovisionamento) setAprovisionamentoId(idAprovisionamento);
    setIsModalOpen(true);
    setType(typeAction);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setType('');
  };

  const columns: GridColDef[] = [
    {
      field: 'descricao',
      headerName: 'DESCRIÇÃO',
      flex: 3,
    },
    {
      field: 'valorAprovisionado',
      headerName: 'VALOR',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => `R$ ${convertToCentavosCentavos(params.row.valorAprovisionado)}`,
    },
    {
      field: 'criadoEm',
      headerName: 'CRIADO EM',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioCreated',
      headerName: 'CRIADO POR',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => params.row.usuarioCreated.nome,
    },
    {
      field: 'atualizadoEm',
      headerName: 'ATUALIZADO EM',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) =>
        params.row.usuarioUpdateId ? tratandoData(params.row.atualizadoEm) : 'NÃO ATUALIZADO',
    },
    {
      field: 'usuarioUpdate',
      headerName: 'ATUALIZADO POR',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) =>
        params.row.usuarioUpdateId ? params.row.usuarioUpdate.nome : 'NÃO ATUALIZADO',
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={getColorStatus(params.row.status)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick('Visualizar', params.row.id)}
          >
            <VisibilityIcon />
          </IconButton>

          <IconButton
            disabled={params.row.valorUtilizado === 0 || params.row.status === 'DEVOLVIDO'}
            color="warning"
            onClick={() => handleButtonClick('RetornarSaldo', params.row.id)}
          >
            <CurrencyExchangeIcon />
          </IconButton>

          <IconButton
            disabled={params.row.valorUtilizado !== 0}
            color="error"
            onClick={() => handleButtonClick('Cancelar', params.row.id)}
          >
            <DoDisturbAltIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button
            onClick={() => handleButtonClick('Cadastrar')}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Aprovisionamento
          </Button>
        </Grid>
        <Grid item md={12} xs={12} my={2}>
          <TableContainer
            columns={columns}
            endpoint="aprovisionamentoMeta"
            setRefetch={tableRefetch}
            idGet={id}
            params={{}}
            setTableRefetch={setTableRefetch}
          />
        </Grid>
      </Grid>

      {isModalOpen && type === 'Cadastrar' && (
        <CadastroAprovisionamento
          open={isModalOpen}
          id={id}
          handleClose={handleCloseModal}
          RefetchMetas={setTableRefetch}
        />
      )}

      {isModalOpen && type === 'Visualizar' && aprovisionamentoId && (
        <AprovisionamentoVisualizar
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={aprovisionamentoId}
        />
      )}

      {isModalOpen && type === 'RetornarSaldo' && aprovisionamentoId && (
        <ModalAcao
          isOpen={isModalOpen}
          nomeAcao="o aprovisionamento e devolver o saldo remanescente"
          acao="concluir"
          id={aprovisionamentoId}
          mutate={devolverAprov}
          handleClose={handleCloseModal}
        />
      )}

      {isModalOpen && type === 'Cancelar' && aprovisionamentoId && (
        <ModalAcao
          isOpen={isModalOpen}
          nomeAcao="Aprovisionamento"
          acao="Cancelar"
          id={aprovisionamentoId}
          mutate={cancelarAprov}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default AprovisionamentoVisualizaDetalhes;
