import { useState } from 'react';
import { Box, Grid, IconButton, Chip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData } from 'src/config/utils';
import { useNavigate } from 'react-router-dom';

import TableContainer from 'src/components/Table';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

export default function PlanoAplicacaoTabelaAutorizacao() {
  const navigate = useNavigate();

  const [id, setId] = useState<string | null>(null);
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('Pendente');
  const [checked, setChecked] = useState<boolean>(false);
  const include = ['eixoFinanciadoCount', 'user'];
  const dataParams = {
    filters: { search },
    include,
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      flex: 1,
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'countEixos',
      headerName: 'EIXOS FINANCIADOS',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
    },

    {
      field: 'usuarioCreate',
      headerName: 'CADASTRADO POR',
      flex: 2,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.usuarioCreate.nome,
    },
    {
      field: 'criadoEm',
      headerName: 'CADASTRADO EM',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioUpdate',
      headerName: 'ATUALIZADO POR',
      flex: 2,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        params.row.usuarioUpdateId ? params.row.usuarioUpdate.nome : 'Não Atualizado',
    },
    {
      field: 'atualizadoEm',
      headerName: 'ATUALIZADO EM',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        params.row.usuarioUpdate ? tratandoData(params.row.atualizadoEm) : 'Não Atualizado',
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="primary" onClick={() => navigate(`plano/${params.row.id}`)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  return (
    <Box sx={{ m: 'auto', mt: 1 }}>
      <TableContainer
        endpoint="planoAplicacao"
        columns={columns}
        params={dataParams}
        setRefetch={tableRefetch}
        setTableRefetch={setTableRefetch}
      />
    </Box>
  );
}
