import { Box, Button, Chip, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useContext, useState } from 'react';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import { tratandoData } from 'src/config/utils';
import { AuthContext } from 'src/Context/ContextUser';
import { serviceGet } from 'src/services';
import AutorizarEntrega from './entrega';

export default function Entregas() {
  const { state } = useContext(AuthContext);
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const handleButtonClick = (idEntrega: string) => {
    setId(idEntrega);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'descricao',
      headerName: 'DESCRIÇÃO DA DESPESA',
      flex: 4,
      minWidth: 350,

      renderCell: (cellParams) => cellParams.row.despesa.descricao,
    },
    {
      field: 'despesa',
      headerName: 'EMPENHO DA DESPESA',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => cellParams.row.despesa.numeroEmpenho,
    },
    {
      field: 'processoSei',
      headerName: 'PROCESSO SEI - PAGAMENTO',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => cellParams.row.despesa.processoSeiPagamento,
    },
    {
      field: 'usuarioCreated',
      headerName: 'CRIADO POR ',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => cellParams.row.usuarioCreated.nome,
    },
    {
      field: 'criadoEm',
      headerName: 'CRIADO EM ',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => <>{tratandoData(cellParams.row.criadoEm)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,

      renderCell: (cellParams) => (
        <Chip
          label={cellParams.row.status}
          sx={{ color: 'white', fontWeight: '600' }}
          color={cellParams.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            disabled={params.row.status === 'Entregue'}
            color="success"
            onClick={() => handleButtonClick(params.row.id)}
          >
            <TaskAltIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          heading="Entregas - Planejamento"
          links={[
            {
              name: 'Entregas',
              href: '/entregas/planejamento',
            },
            {
              name: 'Planejamento',
              href: '/entregas/planejamento',
            },
          ]}
        />
        <Box sx={{ mt: 3 }}>
          <CustomContainerList title="Entregas - Fundo Nacional de Segurança Pública">
            <TableContainer
              endpoint="entregaResponsavel"
              columns={columns}
              params={{}}
              idGet={state.usuario?.id}
              setRefetch={tableRefetch}
              setTableRefetch={setTableRefetch}
            />
          </CustomContainerList>
        </Box>
      </Stack>
      {id && isModalOpen && (
        <AutorizarEntrega
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={id}
          Refetch={setTableRefetch}
        />
      )}
    </Box>
  );
}
