import { Box, Grid, Chip, Alert, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  diferencaEmDias,
  getColor,
  tratandoData,
  convertToCentavosCentavos,
} from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import ModalCarregamento from 'src/components/Modal/Carregamento';

import { serviceGet } from 'src/services';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
}));

export default function PlanoAplicacaoVisualizar({ id }: { id: string }) {
  const include = ['eixoFinanciado', 'user'];

  const { data: rows, isFetching } = useQuery({
    queryKey: ['getPlanoAplicacao', id, include],
    queryFn: () => serviceGet('planoAplicacaoId', { include }, id),
  });
  const { data: redimentos } = useQuery({
    queryKey: ['getRendimento', id, include],
    queryFn: () => serviceGet('rendimento', {}, id),
  });
  if (isFetching) {
    return <ModalCarregamento isOpen={isFetching} />;
  }
  return (
    <Box>
      {rows && redimentos && (
        <Grid container>
          <Grid item md={12} xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead colSpan={rows?.novoPrazoExecucao ? 3 : 4}>
                      Exercício Orçamentário
                    </TableCellHead>
                    <TableCellHead align="center">Prazo Para Execução</TableCellHead>
                    {rows?.novoPrazoExecucao && (
                      <TableCellHead align="center">Data Original do Prazo</TableCellHead>
                    )}
                    <TableCellHead align="center">Data Final do Prazo</TableCellHead>
                    <TableCellHead align="center">Situação</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody colSpan={rows?.novoPrazoExecucao ? 3 : 4}>
                      {rows?.exercicioOrcamentario}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        sx={{ color: 'white', fontWeight: '700' }}
                        label={`${diferencaEmDias(
                          rows?.novoPrazoExecucao
                            ? rows?.novoPrazoExecucao.toString() || ''
                            : rows?.prazoExecucao.toString() || ''
                        )} Dias`}
                        color={getColor(
                          diferencaEmDias(
                            rows?.novoPrazoExecucao
                              ? rows?.novoPrazoExecucao.toString() || ''
                              : rows?.prazoExecucao.toString() || ''
                          )
                        )}
                      />
                    </TableCellBody>
                    {rows?.novoPrazoExecucao && (
                      <TableCellBody align="center">
                        {tratandoData(
                          (
                            (rows?.novoPrazoExecucao
                              ? rows?.prazoExecucao
                              : rows?.novoPrazoExecucao) || ''
                          ).toString() || ''
                        )}
                      </TableCellBody>
                    )}

                    <TableCellBody align="center">
                      {tratandoData(
                        (
                          (rows?.novoPrazoExecucao
                            ? rows?.novoPrazoExecucao
                            : rows?.prazoExecucao) || ''
                        ).toString()
                      )}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        label={rows?.status}
                        sx={{
                          color: 'white',
                          fontWeight: '700',
                          textTransform: 'uppercase',
                        }}
                        color={rows?.status === 'Pendente' ? 'warning' : 'success'}
                      />
                      {}
                    </TableCellBody>
                  </TableRow>
                  <TableRow sx={{ background: '#f5f5f5' }}>
                    <TableCellHead colSpan={3}>Eixos</TableCellHead>
                    <TableCellHead align="center">Valor Inicial de Investimento</TableCellHead>
                    <TableCellHead align="center">Conta Investimento</TableCellHead>

                    <TableCellHead align="center">Valor Inicial de Custeio</TableCellHead>
                    <TableCellHead align="center">Conta Custeio</TableCellHead>
                  </TableRow>
                  {rows?.eixosFinanciados?.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCellBody colSpan={3}>{item.eixoNome}</TableCellBody>
                      <TableCellBody align="center">
                        <>R$ {convertToCentavosCentavos(item.valorInicialInvestimento)} </>
                      </TableCellBody>

                      <TableCellBody align="center">{item.contaInvestimento}</TableCellBody>

                      <TableCellBody align="center">
                        <> R$ {convertToCentavosCentavos(item.valorInicialCusteio)} </>
                      </TableCellBody>

                      <TableCellBody align="center">{item.contaCusteio}</TableCellBody>
                    </TableRow>
                  ))}
                  <>
                    <TableRow sx={{ background: '#f5f5f5' }}>
                      <TableCellHead colSpan={8}>Rendimentos</TableCellHead>
                    </TableRow>
                    <TableRow>
                      <TableCellHead colSpan={2}>Conta</TableCellHead>
                      <TableCellHead align="center" colSpan={2}>
                        Valor Total
                      </TableCellHead>
                      <TableCellHead align="center" colSpan={2}>
                        Valor Retirado
                      </TableCellHead>
                      <TableCellHead align="center" colSpan={2}>
                        Valor Disponível
                      </TableCellHead>
                    </TableRow>
                    {redimentos?.results.map((item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCellBody colSpan={2}>{item.conta}</TableCellBody>
                        <TableCellBody align="center" colSpan={2}>
                          <>R$ {convertToCentavosCentavos(item.valorTotal)} </>
                        </TableCellBody>
                        <TableCellBody align="center" colSpan={2}>
                          <>R$ {convertToCentavosCentavos(item.valorRetirado)} </>
                        </TableCellBody>
                        <TableCellBody align="center" colSpan={2}>
                          <>R$ {convertToCentavosCentavos(item.valorDisponivel)} </>
                        </TableCellBody>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={9} xs={12}>
            <Box m={1}>
              <Typography sx={{ fontSize: '12px', m: 1, fontWeight: '700' }}>
                Prazo de Execução
              </Typography>
              <Chip
                icon={<CircleIcon color="error" sx={{ fontSize: '13px' }} />}
                label="Prazo excedido"
                variant="outlined"
                sx={{ border: 'none' }}
              />
              <Chip
                icon={<CircleIcon color="warning" sx={{ fontSize: '13px' }} />}
                label="Menos de 6 meses"
                variant="outlined"
                sx={{ border: 'none' }}
              />
              <Chip
                icon={<CircleIcon color="success" sx={{ fontSize: '13px' }} />}
                label="Mais de 6 meses"
                variant="outlined"
                sx={{ border: 'none' }}
              />
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            {rows?.status === 'Autorizado' && (
              <Box my={2}>
                <Alert
                  severity="info"
                  sx={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: '500' }}
                >
                  Autorizado por : {rows?.usuarioAuthorization?.nome}
                </Alert>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
