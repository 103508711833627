import { useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import CustomTabs from 'src/components/CustomTabs';
import RendimentosDestinacao from './Destinacao';
import Remanejamentos from './Remanejamento';

export default function Financeiro() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const listTabs = [
    {
      label: 'Destinação de Rendimentos',
      children: <RendimentosDestinacao />,
    },
    {
      label: 'Remanejamentos',
      children: <Remanejamentos />,
    },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack>
        <CustomBreadcrumbs
          heading={`Movimentação Financeira - ${listTabs[value].label}`}
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            {
              name: 'Movimentação Financeira',
              href: '/planejamento/fundonacional/movimentacao-financeira',
            },
          ]}
        />
        <CustomTabs listTabs={listTabs} value={value} handleChange={handleChange} />
      </Stack>
    </Box>
  );
}
