import { useState } from 'react';
import { Box, Grid, IconButton, Chip, Button } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { GridColDef } from '@mui/x-data-grid';
import { convertToCentavosCentavos } from 'src/config/utils';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import RemanejamentoVisualizar from '../Visualizar/Remanejamento';

export default function RemanejamentosAutorizacao() {
  const [idRemanejamento, setIdRemanejamento] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [search, setSearch] = useState<string>('Pendente');
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const handleButtonClick = (id: any, type: string) => {
    setIdRemanejamento(id);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIdRemanejamento(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'metaFisicaRetirada',
      headerName: 'META FÍSICA - RETIRADA',
      flex: 3,
      minWidth: 150,

      renderCell: (params) => <>{params.row.metaFisicaRetirada.nome}</>,
    },

    {
      field: 'metaFisicaRecebida',
      headerName: 'META FÍSICA - RECEBIDA',
      flex: 3,
      minWidth: 150,

      renderCell: (params) => <>{params.row.metaFisicaRecebida.nome}</>,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => <>{params.row.metaFisicaRetirada.natureza}</>,
    },

    {
      field: 'valorRemanejado',
      headerName: 'VALOR REMANEJADO',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valorRemanejado)}</>,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 1,

      renderCell: (params) => (
        <Box>
          <Button
            fullWidth
            variant="soft"
            color="success"
            startIcon={<TaskAltIcon />}
            sx={{ fontSize: '12px' }}
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            Autorizar
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto', mt: 1 }}>
      <TableContainer
        columns={columns}
        endpoint="remanejamento"
        setRefetch={tableRefetch}
        params={{ filters: { search } }}
        setTableRefetch={setTableRefetch}
      />

      {idRemanejamento && (
        <RemanejamentoVisualizar
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={idRemanejamento}
          RefetchRemanejamentos={setTableRefetch}
        />
      )}
    </Box>
  );
}
