import { Box, Modal, Grid, IconButton, Alert, MenuItem, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext } from 'react';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import { serviceGet, servicePatch, servicePost } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import CustomModal from 'src/components/Modal';
import TextFieldMask from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { defaultValueEntrega, IEntrega, schemaEntrega } from './type';

export default function AutorizarEntrega({
  open,
  handleClose,
  Refetch,
  id,
}: {
  open: boolean;
  handleClose: any;
  Refetch: (arg: boolean) => void;
  id: string;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaEntrega),
    defaultValues: defaultValueEntrega,
  });

  const { state } = useContext(AuthContext);
  const { mutate, isPending } = useMutation({
    mutationFn: (data: IEntrega) => servicePatch('realizarEntrega', { ...data }, id),
    onSuccess: (data) => {
      Refetch(true);
      handleClose();
      ToastFun('Entrega Realizada com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const onSubmit = async (data: IEntrega) => {
    data.usuarioUpdateId = state.usuario?.id;
    data.status = 'Entregue';

    mutate(data);
  };
  return (
    <CustomModal open={open} onClose={handleClose}>
      <Grid container>
        <Grid md={12} xs={12}>
          <Alert
            sx={{ background: '#019362 ', color: 'white', fontWeight: '700' }}
            icon={<DrawIcon sx={{ color: 'white' }} />}
          >
            Entrega
          </Alert>
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <TextFieldRG name="dataEntrega" label="Data da Entrega" type="date" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldMask
                  name="documentoSei"
                  label="Número do Documento SEI"
                  maskType="number"
                  mask="__________"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldRG name="descricao" label="Descrição da Entrega" multiline rows={4} />
              </Grid>

              <Grid item xs={12} md={12} sx={{ textAlign: 'right', my: 2 }}>
                <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
                  Realizar Entrega
                </Button>
              </Grid>
            </Grid>
          </FormsProvider>
        </Grid>
      </Grid>
    </CustomModal>
  );
}
