import { Box, Button, Card, CardContent, Grid, MenuItem, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { FieldValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import { convertToCentavosCentavos } from 'src/config/utils';
import { ToastFun } from 'src/config/functions';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import TextFieldMask from 'src/components/Forms/TextFieldRG/TextFieldMask';

export default function AprovisionamentoForms({
  unregister,
  setValue,
  watch,
}: {
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  watch: any;
}) {
  const [instituicoesAlocacao, setInstituicoesAlocacao] = useState([]);
  const [valorDisponivel, setValorDisponivel] = useState();

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });
  const natureza = useWatch({ name: 'natureza' });
  const metaInstituicaoId = useWatch({ name: 'metasInstituicoesId' });
  const metaFisicaId = useWatch({ name: 'metaFisicaId' });

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  const include = ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'];

  const buscarMetas = {
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    natureza,
  };

  // BUSCAR METAS FISICA
  const { data: metasFisicas } = useQuery<{ total: number; results: object[] }>({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () => serviceGet('metaFisica', buscarMetas),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });

  useEffect(() => {
    if (metaFisicaId) {
      const metaAlocacao: any = metasFisicas?.results.filter(
        (item: any) => item.id === metaFisicaId
      );
      if (metaAlocacao && metaAlocacao.length > 0) {
        setInstituicoesAlocacao(metaAlocacao[0].metasInstituicoes);
      } else {
        setInstituicoesAlocacao([]);
      }
    }
  }, [metaFisicaId, metasFisicas?.results]);

  useEffect(() => {
    if (instituicoesAlocacao && metaInstituicaoId) {
      const foundItem: any = instituicoesAlocacao.find(
        (item: any) => item.id === metaInstituicaoId
      );
      const saldoEstimativo = foundItem ? foundItem.saldoEstimativo : undefined;
      setValorDisponivel(saldoEstimativo);
    }
  }, [instituicoesAlocacao, metaInstituicaoId]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={3}>
        <CustomSelect
          label="Exercício Orçamentário"
          name="planoAplicacaoId"
          options={
            planoAplicacao?.results?.map((item: any, indexExercicio: number) => ({
              id: item.id,
              nome: item.exercicioOrcamentario,
              disabled: item.status === 'Pendente',
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box display="grid">
          <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
            {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>

      <Grid item xs={12} md={12} lg={9}>
        <Box display="grid">
          <CustomSelect
            label="Meta Física"
            name="metaFisicaId"
            options={
              metasFisicas?.results?.map((item: any, indexExercicio: number) => ({
                id: item.id,
                nome: item.nome,
              })) ?? []
            }
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="metasInstituicoesId" label="Instituição">
          {instituicoesAlocacao.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextFieldMoney
          disabled
          label="Valor Disponível"
          name="valorDisponivel"
          value={convertToCentavosCentavos(valorDisponivel ?? 0)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldMoney label="Valor" name="valorAprovisionado" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldMask
          name="documentoSei"
          label="Número do Documento SEI"
          maskType="number"
          mask="__________"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldRG name="descricao" label="Descrição" rows={3} multiline />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
