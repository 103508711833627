import {
  Grid,
  Button,
  Box,
  Typography,
  Stepper,
  StepButton,
  Step,
} from '@mui/material';

import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUsuariosCiopaer } from 'src/services/Operacional/Ciopaer/usuarioCiopaer';
import { AdministracaoForm, AsaFixaForm, AsaRotativaForm, ManutencaoForm } from './FormsEscalas';

const steps = ['ASAS ROTATIVAS', 'ASA FIXA', 'ADMINISTRAÇÃO', 'MANUTENÇÃO'];

export default function EscalaFormulario() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [search, setSearch] = useState<string>();

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;
  const { data: rows, refetch } = useQuery({
    queryKey: ['getUsuario', skip, take, search],
    queryFn: getUsuariosCiopaer,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const renderFormForStep = (step: number) => {
    switch (step) {
      case 0: // ASAS ROTATIVAS
        return <AsaRotativaForm rows={rows} />;
      case 1: // ASA FIXA
        return <AsaFixaForm rows={rows} />;
      case 2: // ADMINISTRAÇÃO
        return <AdministracaoForm rows={rows} />;
      case 3: // MANUTENÇÃO
        return <ManutencaoForm rows={rows} />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Data da Escala" name="dadosEscala.dataEscala" type="date" />
      </Grid>
      <Grid item md={12} xs={12} sx={{ px: 2 }}>
        <Box sx={{ width: '100%', m: 'auto', color: '#637381' }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps?.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{
                  '& .MuiStepIcon-root.Mui-active': {
                    color: 'green',
                  },
                }}
              >
                <StepButton disabled>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <Typography sx={{ mt: 2, mb: 1, py: 1, fontWeight: 700 }}>
              {steps[activeStep].toLowerCase().includes('asa') ? (
                <> TRIPULAÇÃO {steps[activeStep]}</>
              ) : (
                <> {steps[activeStep]}</>
              )}
            </Typography>
            {renderFormForStep(activeStep)}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="primary"
                variant="soft"
                disabled={activeStep === 0}
                onClick={handleBack}
                startIcon={<ArrowBackIosIcon />}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep < steps.length - 1 && (
                <Button
                  onClick={handleNext}
                  sx={{ mr: 1 }}
                  variant="soft"
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Próximo
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button variant="soft" color="success" type="submit" startIcon={<SaveIcon />}>
                  Cadastrar
                </Button>
              )}
            </Box>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
