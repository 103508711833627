import { useContext, useState } from 'react';
import { Box, Grid, Button, Chip } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useMutation } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import { convertToCentavosCentavos } from 'src/config/utils';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import TableContainer from 'src/components/Table';
import { servicePatch } from 'src/services';
import { AuthContext } from 'src/Context/ContextUser';
import { IRendimentoAlocacaoData } from '../../Financeiro/Destinacao/Formulario/type';

export default function RendimentosTabelaAutorizacao() {
  const { state } = useContext(AuthContext);

  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const [id, setId] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('Pendente');
  const include = ['eixoNome', 'exercicioOrcamentario', 'usuarioCreated', 'conta'];
  console.log(id);
  const handleButtonClick = (idRendimento: string) => {
    setId(idRendimento);
    setIsModalOpen(true);
  };
  const { mutate } = useMutation({
    mutationFn: (data: IRendimentoAlocacaoData) =>
      servicePatch('rendimentoAutorizacao', { ...data }, data.id),
    onSuccess: (data) => {
      ToastFun('Rendimento autorizado com sucesso', 'success');
      setTableRefetch(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(undefined);
  };
  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'eixoNome',
      headerName: 'EIXO FINANCIADO',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'conta',
      headerName: 'CONTA',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'valor',
      headerName: 'RENDIMENTO',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valor)}</>,
    },
    {
      field: 'usuarioCreated',
      headerName: 'CRIADO POR',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <> {params.row.usuarioCreated.nome}</>,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <Button
            fullWidth
            variant="soft"
            color="success"
            startIcon={<TaskAltIcon />}
            sx={{ fontSize: '12px' }}
            onClick={() => handleButtonClick(params.row.id)}
          >
            Autorizar
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <TableContainer
        endpoint="rendimentoConta"
        setRefetch={tableRefetch}
        params={{ filters: { search }, include }}
        columns={columns}
        setTableRefetch={setTableRefetch}
      />

      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Rendimento"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
