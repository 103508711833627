import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { convertToCentavosCentavos } from 'src/config/utils';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';

export default function DestinacaoRendimentoForms({
  setValue,
}: {
  setValue: UseFormSetValue<FieldValues>;
}) {
  const [instituicoesAlocacao, setInstituicoesAlocacao] = useState([]);

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });

  const natureza = useWatch({ name: 'natureza' });
  const conta = useWatch({ name: 'conta' });

  const { data: eixosFinanciados, refetch: RefetchEixos } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId, natureza],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  const { data: rendimentoConta } = useQuery({
    queryKey: ['getRendimentoConta', eixoFinanciadoId, conta, natureza],
    queryFn: () => serviceGet('rendimentoContaId', { eixoFinanciadoId, conta }),
    enabled: !!eixoFinanciadoId && !!conta,
  });

  useEffect(() => {
    if (rendimentoConta) {
      setValue('rendimentoContaId', rendimentoConta.id);
    }
  }, [rendimentoConta, setValue]);

  useEffect(() => {
    if (eixoFinanciadoId && natureza) {
      const palavraFormatada = natureza.charAt(0).toUpperCase() + natureza.slice(1).toLowerCase();
      const propertyName = `conta${palavraFormatada}`;
      const eixoSelecioando = eixosFinanciados?.results.find(
        (element: any) => eixoFinanciadoId === element.id
      );
      if (eixoSelecioando) {
        setValue('conta', (eixoSelecioando as any)[propertyName]);
      }
    }
  }, [eixoFinanciadoId, eixosFinanciados, natureza, setValue]);
  const include = ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'];

  const buscarMetas = {
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    natureza,
  };
  // BUSCAR METAS FISICA
  const { data: metasFisicas } = useQuery<{ total: number; results: object[] }>({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () => serviceGet('metaFisica', { ...buscarMetas }),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });

  const metaFisicaId = useWatch({ name: 'metaFisicaId' });
  useEffect(() => {
    if (metaFisicaId) {
      const metaAlocacao: any = metasFisicas?.results.filter(
        (item: any) => item.id === metaFisicaId
      );
      if (metaAlocacao && metaAlocacao.length > 0) {
        setInstituicoesAlocacao(metaAlocacao[0].metasInstituicoes);
      } else {
        setInstituicoesAlocacao([]);
      }
    }
  }, [metaFisicaId, metasFisicas?.results]);
  useEffect(() => {
    if (rendimentoConta) {
      setValue('rendimentoContaId', rendimentoConta.id);
    }
  }, [rendimentoConta, setValue]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={3}>
        <CustomSelect
          label="Exercício Orçamentário"
          name="planoAplicacaoId"
          options={
            planoAplicacao?.results?.map((item: any, indexExercicio: number) => ({
              id: item.id,
              nome: item.exercicioOrcamentario,
              disabled: item.status === 'Pendente',
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <CustomSelect
          label="Meta Física"
          name="metaFisicaId"
          options={
            metasFisicas?.results?.map((item: any, indexExercicio: number) => ({
              id: item.id,
              nome: item.nome,
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="metaInstituicaoId" label="Instituição">
          {instituicoesAlocacao.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle2">Conta</Typography>
        <TextFieldRG placeholder="Conta" label="" name="conta" disabled />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle2">Valor Disponível</Typography>

        <TextField
          fullWidth
          sx={{ my: 2 }}
          label=""
          value={`R$ ${convertToCentavosCentavos(
            rendimentoConta ? rendimentoConta.valorDisponivel : 0
          )}`}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle2">Valor Alocado</Typography>
        <TextFieldMoney label="" name="valor" />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRGMaks
          mask="____.______._____/____-__"
          label="Processo - SEI"
          name="processoSei"
          maskType="number"
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
