import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, useGridApiRef, gridClasses } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { serviceGet } from 'src/services';
import { EndpointsResponseGet } from 'src/services/endpointsResponse';
import { AxiosInstance } from 'axios';
import { Typography, Skeleton } from '@mui/material';

interface DataTable<K extends keyof EndpointsResponseGet> {
  endpoint: K;
  idGet?: string;
  setRefetch: boolean;
  params: object;
  columns: GridColDef[];
  setTableRefetch: any;
  client?: AxiosInstance;
}

export default function TableContainer<K extends keyof EndpointsResponseGet>({
  endpoint,
  idGet,
  setTableRefetch,
  params,
  columns,
  setRefetch,
  client,
}: DataTable<K>) {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const { data, refetch, error, isFetching } = useQuery({
    queryKey: ['getData', endpoint, params, skip, take, idGet],
    queryFn: () => serviceGet(endpoint, { ...params, skip, take }, idGet || undefined, client),
    staleTime: 5000,
  });

  useEffect(() => {
    if (setRefetch) {
      refetch();
      setTableRefetch(false);
    }
  }, [setRefetch, refetch, setTableRefetch]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        '& .MuiDataGrid-columnHeader': {
          background: '#f5f5f5',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '11px',
          fontWeight: 600,
          color: '#555555',
        },
        '& .MuiDataGrid-cell': {
          color: '#555555',
          textTransform: 'uppercase',
        },
      }}
    >
      <DataGrid
        style={{ border: 'none' }}
        apiRef={apiRef}
        rows={data && 'results' in data ? data.results : []}
        columns={columns.map((col) => ({
          ...col,
          renderCell: col.renderCell
            ? col.renderCell
            : (cellParams) => (isFetching ? <Skeleton animation="wave" /> : cellParams.value),
        }))}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rowCount={data && 'total' in data ? data.total : undefined}
        loading={isFetching}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ py: 2 }}>
                Nenhum registro encontrado.
              </Typography>
            </Box>
          ),
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 0.5,
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiDataGrid-overlayWrapper': {
            height: '80px',
          },
          '& .custom-row': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count === -1 ? `more than ${to}` : count}`,
            labelRowsPerPage: 'Linhas por Página',
          },
        }}
      />
    </Box>
  );
}
