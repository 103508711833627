import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactElement, ReactNode, SyntheticEvent, useEffect, useState } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabData {
  label: string;
  children: ReactNode;
  icon?: ReactElement;
  setValue?: (value: number) => void;
}

interface CustomTabsProps {
  listTabs: TabData[];
  value?: number;
  handleChange?: (event: SyntheticEvent, newValue: number) => void;
}

export default function CustomTabs({ listTabs, value, handleChange }: CustomTabsProps) {
  const [valueComp, setValue] = useState(0);

  const handleChangeComp = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value || valueComp} onChange={handleChange || handleChangeComp}>
          {listTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              iconPosition="start"
              icon={tab.icon}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {listTabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value || valueComp} index={index}>
          {tab.children}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
