import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Alert, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUsuario } from 'src/services/Admin/get';
import DrawIcon from '@mui/icons-material/Draw';
import { ToastFun } from 'src/config/functions';
import FormsProvider from 'src/components/Forms/Provider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErroResponse } from 'src/pages/types';
import { patchAtualizarUsuario } from 'src/services/Admin/patch';
import FormularioUsuario from '../formularioUsuario';
import { IUsuarioData, defaultValuesUsuario, schemaUsuario } from '../typeUsuario';

export default function AtualizarUsuario({
  id,
  open,
  handleClose,
  refetch,
}: {
  id: string;
  open: boolean;
  handleClose: any;
  refetch: any;
}) {
  // PEGAR DADOS DO USUÁRIO

  const methods = useForm({
    resolver: yupResolver(schemaUsuario),
    defaultValues: defaultValuesUsuario,
  });
  const { data: user, refetch: refetchUser } = useQuery<IUsuarioData>({
    queryKey: ['getUsuario', id],
    queryFn: () => getUsuario(id),
  });
  const { setValue, unregister, reset } = methods;

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [reset, user]);

  const { mutate, isPending } = useMutation({
    mutationFn: patchAtualizarUsuario,
    onSuccess: (data) => {
      ToastFun('Usuário Atualizado com sucesso', 'success');
      handleClose();
      refetchUser();
      refetch(true);
      reset();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: IUsuarioData) => {
    const permission: object[] = [];

    data.permissoes = data.permissoes.filter((funcao: any) => funcao !== undefined);
    data.permissoes.forEach((item: any) => {
      let permiAdmin = {};
      let permiOther = {};
      if (item.funcao === 'admin') {
        permiAdmin = { nome: 'admin' };
      } else {
        permiOther = { nome: `${item.funcao}_${item.modulo}` };
      }
      if (Object.keys(permiAdmin).length) {
        permission.push(permiAdmin);
      } else if (Object.keys(permiOther).length) {
        permission.push(permiOther);
      }
    });
    data.permissoesUsuario = permission;

    mutate(data);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            background: 'white',
            borderRadius: '15px',
            p: 5,
            width: {
              xl: '58%',
              md: '75%',
              sm: '70%',
            },
            maxHeight: '650px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            margin: 'auto',
            mt: 3,
          }}
        >
          <Grid container>
            <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
              <IconButton aria-label="delete" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
              <Alert
                sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                icon={<DrawIcon sx={{ color: 'white' }} />}
              >
                Usuário - Editar
              </Alert>
              <Box p={2}>
                {user && (
                  <FormsProvider methods={methods} handleSubmit={onSubmit}>
                    <FormularioUsuario setValue={setValue} unregister={unregister} />
                  </FormsProvider>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
