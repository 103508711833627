import { Box, Modal, Grid, IconButton, Button, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import CachedIcon from '@mui/icons-material/Cached';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { CustomContainerAtualizar } from 'src/components/CustomContainer/custom-container';
import { serviceGet, servicePatch } from 'src/services';
import CustomModal from 'src/components/Modal';
import { IMetaGeralData, schemaMetaGeral } from '../Cadastro/MetaGeral/type';

export default function AtualizarMetaGeral({
  open,
  handleClose,
  id,
  RefetchMetaGeral,
}: {
  open: boolean;
  handleClose: any;
  id: string;
  RefetchMetaGeral: (arg: boolean) => void;
}) {
  const { state } = useContext(AuthContext);

  const { data: rowsMeta } = useQuery({
    queryKey: ['getMetasGerais', id],
    queryFn: () => serviceGet('metaGeralId', {}, id),
  });

  const methods = useForm({
    resolver: yupResolver(schemaMetaGeral),
  });
  const {
    watch,
    reset,
    formState: { errors },
  } = methods;
  useEffect(() => {
    if (rowsMeta) {
      reset(rowsMeta);
    }
  }, [reset, rowsMeta, state]);
  const planoAplicacaoId = watch('planoAplicacaoId');

  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data: { nome: string; usuarioUpdateId?: string; atualizadoEm: Date }) =>
      servicePatch('metaGeral', { ...data }, id),
    onSuccess: (data) => {
      ToastFun('Meta Atualizada com sucesso', 'success');
      handleClose();
      RefetchMetaGeral(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: { nome: string; usuarioUpdateId: string; atualizadoEm: Date }) => {
    if (state.usuario?.id) {
      data.usuarioUpdateId = state.usuario?.id;
    }

    data.atualizadoEm = new Date();
    mutate(data);
  };
  return (
    <>
      {planoAplicacaoId && (
        <CustomModal
          open={open}
          onClose={handleClose}
          title="Meta Geral - Atualizar"
          size="sm"
          icon={<EditNoteIcon color="warning" sx={{ fontSize: 30 }} />}
        >
          <Grid container>
            <Grid md={12} xs={12}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={4}>
                    <TextFieldRG
                      select
                      disabled
                      name="planoAplicacaoId"
                      label="Exercício Orçamentário"
                    >
                      {rowPlanosAplicacao?.results.map((item: any, indexExercicio: number) => (
                        <MenuItem key={indexExercicio} value={item.id}>
                          {item.exercicioOrcamentario}
                        </MenuItem>
                      ))}
                    </TextFieldRG>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextFieldRG select disabled name="eixoFinanciadoId" label="Eixo/Área Temática">
                      {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
                        <MenuItem key={indexEixoFinanciado} value={item.id}>
                          {item.eixoNome}
                        </MenuItem>
                      ))}
                    </TextFieldRG>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextFieldRG type="text" name="nome" label="Meta geral" />
                  </Grid>

                  <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      startIcon={<CachedIcon />}
                    >
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </FormsProvider>
            </Grid>
          </Grid>
        </CustomModal>
      )}
    </>
  );
}
