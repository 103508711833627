import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from 'src/Context/ContextUser';
import AuthPermission from 'src/pages/Login/AuthPermission';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface RouteProtectProps {
  element: JSX.Element;
}

export const RouteProtect = ({ element }: RouteProtectProps) => {
  const { state } = useContext(AuthContext);

  if (state.isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!state.isAuth) {
    return <AuthPermission />;
  }

  return element;
};

interface RoutePrivateProps {
  element: JSX.Element;
  permission: string;
}
export const RoutePrivate = ({ element, permission }: RoutePrivateProps) => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const moduleAtual = pathSegments[0];
  const { state } = useContext(AuthContext);

  if (state.isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (!state.isAuth) {
    return <AuthPermission />;
  }

  if (
    state.usuario?.permissoes.includes('*') ||
    state.usuario?.permissoes.includes(`${moduleAtual.toUpperCase()}_*`)
  ) {
    return element;
  }

  const permissionRoute = state.usuario?.permissoes.some((item) => item.includes(permission));
  if (permissionRoute) {
    return element;
  }

  return <AuthPermission />;

  return null;
};
