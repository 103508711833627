import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { convertToCentavosCentavos, valorMonetario } from 'src/config/utils';
import CustomModal from 'src/components/Modal';
import TableContainer from 'src/components/Table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { serviceDelete, serviceGet, servicePatch, servicePost } from 'src/services';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import WarningIcon from '@mui/icons-material/Warning';
import ModalExcluir from 'src/components/Modal/Excluir';
import { ApiEmpenho } from 'src/services/Api';
import CadastrarEmpenho from '../../../Despesas/Empenhos/Cadastrar';
import DespesaVisualizar from '../../../Despesas/Empenhos/Visualizar';
import { IEmpenhoDataAPI } from '../../../Despesas/Empenhos/Formulario/type';

interface EmpenhosPagamentosVisualizaDetalhesProps {
  id: string;
}
interface verificarEmpenhos {
  numeroEmpenho: string;
  valorEmpenhado: number;
  valorAnulado: number;
  valorComplemento: number;
  valorPago: number;
}
export default function EmpenhosPagamentosVisualizaDetalhes({
  id,
}: EmpenhosPagamentosVisualizaDetalhesProps) {
  const [tableRefetch, setTableRefetch] = useState(false);
  const [empenho, setEmpenho] = useState<string | null>(null);
  const [empenhos, setEmpenhos] = useState<string | undefined>(undefined);
  const [buscarEmpenho, setBuscarEmpenho] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [despesaId, setDespesaId] = useState<string | undefined>();
  const [empenhosVerificados, setEmpenhosVerificados] = useState<{
    empenhosAtualizados: {
      numeroEmpenho: string;
      valorEmpenhado: number;
      valorAnulado: number;
      valorComplemento: number;
      valorPago: number;
    }[];
    valorAtualizados: {
      instituicao: string;
      saldoAnteriorReal: number;
      saldoAnteriorEstimativo: number;
      saldoAtualReal: number;
      saldoAtualEstimativo: number;
    }[];
  } | null>(null);
  const [type, setType] = useState('');

  const { mutate: verificarEmpenhos, isPending } = useMutation({
    mutationFn: (empenhosAtualizados: IEmpenhoDataAPI[]) =>
      servicePost('verificarEmpenhosMetaFisica', { empenhosAtualizados, metaFisicaId: id }),
    onSuccess: (data) => {
      setEmpenhosVerificados(data);
      setBuscarEmpenho(false);
    },
    onError: (error: ErroResponse) => {
      setBuscarEmpenho(false);
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const { mutate: salvarAlteracoes } = useMutation({
    mutationFn: (empenhosAtualizados: verificarEmpenhos[]) =>
      servicePatch('verificarEmpenhosMetaFisica', { empenhosAtualizados, metaFisicaId: id }, id),
    onSuccess: (data) => {
      setBuscarEmpenho(false);
      ToastFun('Empenhos Atualizados Com Sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      setBuscarEmpenho(false);
      ToastFun(error.response.data?.message, 'error');
    },
  });
  useEffect(() => {
    if (empenhosVerificados) {
      handleButtonClick('EmpenhosVerificados');
    }
  }, [empenhosVerificados]);

  const { data, refetch: refechEmp } = useQuery({
    queryKey: ['getEmpenhosMeta', id],
    queryFn: () => serviceGet('despesaMetaFisicaId', {}, id),
  });
  const { data: rows, refetch: refetchVerificar } = useQuery({
    queryKey: ['getEmpenhosApi', empenhos],
    queryFn: () => serviceGet('empenhosAtualizados', { empenhos }, undefined, ApiEmpenho),
    enabled: !!empenhos && !!data,
  });

  useEffect(() => {
    if (data && buscarEmpenho) {
      const arrayEmpenhos = data.results.map((item) => item.numeroEmpenho);
      setEmpenhos(arrayEmpenhos.join(','));
      refetchVerificar();
    } else {
      setEmpenhos(undefined);
    }
  }, [buscarEmpenho, data, refetchVerificar]);

  useEffect(() => {
    if (rows && Object.keys(rows).length > 0 && buscarEmpenho) {
      verificarEmpenhos(rows);
      setBuscarEmpenho(false);
    }
  }, [buscarEmpenho, rows, verificarEmpenhos]);

  useEffect(() => {
    if (empenhosVerificados && empenhosVerificados.empenhosAtualizados.length === 0) {
      ToastFun('Empenhos Atualizados', 'success');
    }
  }, [empenhosVerificados]);
  const SincronizarEmpenhos = () => {
    setBuscarEmpenho(true);
    refechEmp();
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmpenho(null);
  };
  // AÇÃO PARA UM UNICO EMPENHO
  const handleButtonClick = (typeAction: string, numeroEmpenho?: string, idDespesa?: string) => {
    setEmpenho(numeroEmpenho ?? null);
    setDespesaId(idDespesa);
    setType(typeAction);
    setIsModalOpen(true);
  };
  // EXCLUIR DESPESA
  const { mutate } = useMutation({
    mutationFn: (idDespesa: string) => serviceDelete('despesas', {}, idDespesa),
    onSuccess: () => {
      ToastFun('Excluído com sucesso', 'success');
      setTableRefetch(true);
      handleCloseModal();
      refechEmp();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  // ATUALIZAR OS EMPENHOS
  const attEmpenhos = () => {
    if (empenhosVerificados) {
      salvarAlteracoes(empenhosVerificados.empenhosAtualizados);
    }
  };
  const columns: GridColDef[] = [
    {
      field: 'instituicao',
      headerName: 'INSTITUIÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'anoEmpenho',
      headerName: 'ANO DO EMPENHO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'numeroEmpenho',
      headerName: 'Nº EMPENHO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'valorEmpenhado',
      headerName: 'VALOR EMPENHADO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => `R$ ${convertToCentavosCentavos(row.valorEmpenhado)}`,
    },
    {
      field: 'saldoEmpenho',
      headerName: 'SALDO DO EMPENHO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) =>
        `R$ ${convertToCentavosCentavos(
          row.valorEmpenhado + row.valorComplemento - row.valorAnulado - row.valorPago
        )}`,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => (
        <>
          <IconButton onClick={() => handleButtonClick('Visualizar', undefined, row.id)}>
            <VisibilityIcon color="info" />
          </IconButton>
          <IconButton onClick={() => handleButtonClick('Pagamentos', row.numeroEmpenho)}>
            <CurrencyExchangeIcon color="success" />
          </IconButton>
          <IconButton
            onClick={() => handleButtonClick('Excluir', undefined, row.id)}
            sx={{ color: 'gray' }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <Box sx={{ mt: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<ReplayIcon />}
            sx={{ mx: 2 }}
            disabled={data && data.results.length === 0}
            onClick={() => SincronizarEmpenhos()}
          >
            Sincronizar Empenhos
          </Button>
          <Button
            onClick={() => handleButtonClick('Cadastrar')}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Nova Despesa
          </Button>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <TableContainer
            columns={columns}
            endpoint="despesaMetaFisicaId"
            setRefetch={tableRefetch}
            idGet={id}
            params={{}}
            setTableRefetch={setTableRefetch}
          />
        </Grid>
      </Grid>

      {isModalOpen && type === 'Pagamentos' && empenho && (
        <PagamentosEmpenho open={isModalOpen} empenho={empenho} handleClose={handleCloseModal} />
      )}
      {isModalOpen && type === 'Cadastrar' && (
        <CadastrarEmpenho
          open={isModalOpen}
          id={id}
          handleClose={handleCloseModal}
          setTableRefetch={setTableRefetch}
          refechEmp={refechEmp}
        />
      )}
      {despesaId && isModalOpen && type === 'Visualizar' && (
        <DespesaVisualizar open={isModalOpen} handleClose={handleCloseModal} id={despesaId} />
      )}
      {isModalOpen && type === 'Excluir' && despesaId && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Despesa"
          id={despesaId}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
      {empenhosVerificados &&
        empenhosVerificados.empenhosAtualizados.length > 0 &&
        type === 'EmpenhosVerificados' && (
          <CustomModal
            open={isModalOpen}
            onClose={handleCloseModal}
            title="Empenhos - Atualizados"
            icon={<WarningIcon color="warning" sx={{ fontSize: 25 }} />}
          >
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Número Empenho</TableCell>
                    <TableCell align="center">Valor Empenhado</TableCell>
                    <TableCell align="center">Valor Pago</TableCell>
                    <TableCell align="center">Valor Anulado</TableCell>
                    <TableCell align="center">Valor Complementar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empenhosVerificados?.empenhosAtualizados.map((item) => (
                    <TableRow key={item.numeroEmpenho}>
                      <TableCell align="center">{item.numeroEmpenho}</TableCell>
                      <TableCell align="center">{valorMonetario(item.valorEmpenhado)}</TableCell>
                      <TableCell align="center">{valorMonetario(item.valorPago)}</TableCell>
                      <TableCell align="center">{valorMonetario(item.valorAnulado)}</TableCell>
                      <TableCell align="center">{valorMonetario(item.valorComplemento)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Table>
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      VALORES ATUALIZADOS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">INSTITUIÇÃO</TableCell>
                    <TableCell align="center">SALDO ESTIMADO</TableCell>
                    <TableCell align="center">SALDO REAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empenhosVerificados?.valorAtualizados.map((item) => (
                    <TableRow key={item.instituicao}>
                      <TableCell align="center">{item.instituicao}</TableCell>
                      <TableCell align="center">
                        {valorMonetario(item.saldoAtualEstimativo / 100)}
                      </TableCell>
                      <TableCell align="center">
                        {valorMonetario(item.saldoAtualReal / 100)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveIcon />}
                onClick={() => attEmpenhos()}
              >
                Atualizar
              </Button>
            </Box>
          </CustomModal>
        )}
    </Box>
  );
}

function PagamentosEmpenho({
  open,
  empenho,
  handleClose,
}: {
  open: boolean;
  empenho: string;
  handleClose: () => void;
}) {
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const columns: GridColDef[] = [
    {
      field: 'numeroPagamento',
      headerName: 'Nº PAGAMENTO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'anoPagamento',
      headerName: 'ANO DO EMPENHO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'valorPagamento',
      headerName: 'VALOR PAGO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <>R$ {valorMonetario(params.row.valorPagamento)}</>,
    },
    {
      field: 'descricaoNaturezaPagamento',
      headerName: 'DESCRIÇÃO',
      align: 'center',
      minWidth: 150,
      flex: 2,
      headerAlign: 'center',
    },
    {
      field: 'razaoSocial',
      headerName: 'BENEFICÁRIO',
      align: 'center',
      minWidth: 150,
      flex: 2,
      headerAlign: 'center',
    },
  ];
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Pagamentos do Empenho Nº ${empenho}`}
      icon={<CurrencyExchangeIcon color="success" />}
    >
      <Box mt={3}>
        <TableContainer
          columns={columns}
          endpoint="pagamentosEmpenho"
          idGet={empenho}
          params={{}}
          client={ApiEmpenho}
          setRefetch={tableRefetch}
          setTableRefetch={setTableRefetch}
        />
      </Box>
      <Divider />
    </CustomModal>
  );
}
