import * as yup from 'yup';

export interface IEntrega {
  dataEntrega: string;
  descricao: string;
  status?: string;
  documentoSei: string;
  usuarioUpdateId?: string;
}

export const schemaEntrega: yup.ObjectSchema<IEntrega> = yup.object({
  dataEntrega: yup.string().required('Data é obrigatório'),
  descricao: yup.string().required('Descrição é obrigatório'),
  documentoSei: yup
    .string()
    .test(
      'is-greater',
      'Informe um número sei válido',
      (value) => !!value && value.length >= 5 && value.length <= 10
    )
    .required('Documento SEI é obrigatório'),
  usuarioUpdateId: yup.string().optional(),
  status: yup.string().optional(),
});
export const defaultValueEntrega: IEntrega = {
  dataEntrega: '',
  descricao: '',
  status: '',
  documentoSei: '',
  usuarioUpdateId: '',
};
