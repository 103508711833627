// TYPES / DEFAULT
import { convertToCentavos } from 'src/config/utils';
import * as yup from 'yup';
import { IUsuarioData } from 'src/pages/Admin/Usuarios/typeUsuario';
import { ITipoDespesaData } from 'src/pages/Admin/Despesas/Formulario/type';
import {
  IEixosFinanciadosData,
  IPlanoAplicacaoData,
} from '../../../PlanoAplicacao/Formulario/type';
import { IMetaGeralData } from '../../../Metas/Cadastro/MetaGeral/type';
import { IMetaFisicaData } from '../../../Metas/Formulario/types';

export interface DadosVinculadosEmpenho {
  planoAplicacaoId: string;
  natureza: string;
  eixoFinanciadoId: string;
  processoSeiMatriz: string;
  metaFisicaId: string;
  metaInstituicaoId: string;
  usuarioCreatedId?: string;
  numeroEmpenho: string;
  valorEmpenhado: number | null;
  valorAnulado: number | null;
  valorPago: number | null;
  valorComplemento: number | null;
  processoSeiPagamento: string;
  descricao: string;
  instituicaoBeneficiadaId?: string | null;
  unidadeBeneficiadaId?: string | null;
  aprovisionamentoId?: string;
  responsavelId?: string;
  dataEmpenho?: string;
  valorEmpenhoTipo: string;
  anoEmpenho?: number | null;
}

export interface Empenho {
  numeroEmpenho: number;
  valorEmpenhado: number;
  processoSeiPagamento: string;
  descricao: string;
}

export const schemaEmpenhos: yup.ObjectSchema<DadosVinculadosEmpenho> = yup.object({
  planoAplicacaoId: yup.string().required('Ano é obrigatório'),
  natureza: yup.string().required('Natureza é obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é obrigatório'),
  valorEmpenhoTipo: yup.string().required('Tipo do valor do Empenho é obrigatório'),
  dataEmpenho: yup.string(),
  anoEmpenho: yup.number(),
  processoSeiMatriz: yup
    .string()
    .required('Processo SEI é Obrigatório')
    .length(25, 'Número SEI inválido')
    .test('validYear', 'Número SEI inválido', (value) => {
      const year = parseInt(value.split('/')[1]?.split('-')[0], 10);
      const currentYear = new Date().getFullYear();
      return year > 2015 && year <= currentYear;
    }),
  metaFisicaId: yup.string().required('Meta Física é obrigatório'),
  metaInstituicaoId: yup.string().required('Instituição é obrigatório'),
  usuarioCreatedId: yup.string().optional(),
  instituicaoBeneficiadaId: yup.string().required('Instituição é obrigatório'),
  responsavelId: yup.string().optional(),
  aprovisionamentoId: yup.string().optional(),
  unidadeBeneficiadaId: yup.string().optional().nullable(),
  processoSeiPagamento: yup
    .string()
    .required('Processo Sei é Obrigatório')
    .length(25, 'Número SEI inválido')
    .test('validYear', 'Número SEI inválido', (value) => {
      const year = parseInt(value.split('/')[1]?.split('-')[0], 10);
      const currentYear = new Date().getFullYear();
      return year > 2015 && year <= currentYear;
    }),
  descricao: yup.string().required('Descrição é obrigatório'),
  numeroEmpenho: yup.string().required('Número do Empenho é obrigatório'),
  valorEmpenhado: yup
    .number()
    .min(0, 'Valor não pode ser negativo')
    .required('Empenho não Encontrado')
    .transform((value) => (typeof value === 'number' ? convertToCentavos(value) : 0)),
  valorAnulado: yup
    .number()
    .min(0, 'Valor não pode ser negativo')
    .required('Empenho não Encontrado')
    .transform((value) => (typeof value === 'number' ? convertToCentavos(value) : 0)),
  valorComplemento: yup
    .number()
    .min(0, 'Valor não pode ser negativo')
    .required('Empenho não Encontrado')
    .transform((value) => (typeof value === 'number' ? convertToCentavos(value) : 0)),
  valorPago: yup
    .number()
    .min(0, 'Valor não pode ser negativo')
    .required('Empenho não Encontrado')
    .transform((value) => (typeof value === 'number' ? convertToCentavos(value) : 0)),
});

export interface IEmpenhoData {
  id: string;
  processoSeiMatriz: string;
  processoSeiPagamento: string;
  valorEmpenhado: number;
  valorPago: number;
  valorAnulado: number;
  valorComplemento: number;
  numeroEmpenho: string;
  anoEmpenho?: number | null;
  dataEmpenho?: Date | null;
  descricao: string;
  metasInstituicoesId: string;
  instituicaoBeneficiadaId?: string | null;
  unidadeBeneficiadaId?: string | null;
  usuarioCreatedId: string;
  criadoEm: Date;
}
export interface IDespesaResponseData extends IEmpenhoData {
  planoAplicacao?: IPlanoAplicacaoData;
  eixoFinanciado?: IEixosFinanciadosData & { eixoNome?: string };
  metaGeral?: IMetaGeralData;
  metaFisica?: IMetaFisicaData;
  tipoDespesaData?: ITipoDespesaData;
  instituicao?: string;
  usuarioCreate?: IUsuarioData;
  entrega?: {
    nomeResponsavel: string;
    status: string;
    id: string;
  };
}

export const defaultValuesEmpenhos: DadosVinculadosEmpenho = {
  planoAplicacaoId: '',
  natureza: '',
  eixoFinanciadoId: '',
  processoSeiMatriz: '',
  metaFisicaId: '',
  metaInstituicaoId: '',
  usuarioCreatedId: '',
  valorEmpenhado: null,
  processoSeiPagamento: '',
  descricao: '',
  instituicaoBeneficiadaId: null,
  responsavelId: '',
  unidadeBeneficiadaId: null,
  numeroEmpenho: '',
  valorAnulado: null,
  valorPago: null,
  valorComplemento: null,
  dataEmpenho: '',
  anoEmpenho: null,
  valorEmpenhoTipo: '',
};
export interface Pagamento {
  id: number;
  numeroPagamento: bigint;
  anoPagamento: bigint;
  entidade: string;
  anoLiquidacao: bigint;
  numeroLiquidacao: bigint;
  dataEmissao: Date;
  dataBordero: string;
  dataPagamento: string;
  dataBaixa: string;
  status: string;
  codigoCredor: bigint;
  razaoSocial: string;
  codigoBanco: bigint;
  codigoAgencia: string;
  contaBancaria: string;
  contaFinanceira: bigint;
  descricaoPagamento: string;
  codigoNaturezaPagamento: bigint;
  descricaoNaturezaPagamento: string;
  numeroEmissoes: bigint;
  valorPagamento: number;
  totalAnulado: number;
  totalConsignacoes: number;
  totalDesconto: bigint;
  formaPagamento: string;
  tipoOperacaoBancaria: bigint;
  documentoPagamento: string;
  eventoContabil: bigint;
}
export interface IEmpenhoDataAPI {
  id: number;
  numeroEmpenho: number;
  anoEmpenho: number;
  entidade: string;
  dataEmpenho: string;
  statusEmpenho: string;
  tipoEmpenho: string;
  dataProgramacao: Date;
  numeroEmissoes: number;
  status: string;
  motivoEmpenho: string;
  numeroContrato: string;
  pedidoEmpenho: string;
  dataPedido: Date;
  modalidadeLicitacao: string;
  numeroLicitacao: string;
  historico: string;
  codigoCredor: number;
  razaoSocial: string;
  cpfCnpjCredor: string;
  classeCredor: string;
  banco: string;
  agencia: string;
  conta: string;
  orgao: string;
  unidade: string;
  reduzidoFuncional: number;
  funcionalProgramatica: string;
  despesaOrcamentaria: string;
  fonteRecurso: number;
  detalhamentoFonte: string;
  funcao: string;
  subFuncao: string;
  programa: string;
  acao: string;
  valorEmpenhado: number;
  totalAnulado: number;
  totalComplemento: number;
  totalLiquidado: number;
  totalPago: number;
  naturezaRecurso: string;
  numeroConvenio: number;
  anoConvenio: number;
  numeroProcesso: string;
  anoProcesso: number;
  eventoContabil: string;
}
