import { Button, Grid, MenuItem } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { convertToCentavosCentavos, valorMonetario } from 'src/config/utils';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { serviceGet } from 'src/services';
import CustomSelect from 'src/components/Forms/TextFieldRG/SelectInput';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';

export default function FormularioRemanejamento({ setValue }: { setValue: any }) {
  const [valorDisponivel, setValorDisponivel] = useState<number>(0);

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });

  const natureza = useWatch({ name: 'natureza' });

  const metaFisicaRetirada = useWatch({ name: 'metaFisicaRetiradaId' });
  const metaFisicaRecebida = useWatch({ name: 'metaFisicaRecebidaId' });
  const instituicaoRetirada = useWatch({ name: 'instituicaoRetiradaId' });

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao', {}),
  });

  const { data: eixosFinanciados, refetch: RefetchEixos } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });

  const { data: metasFisicas } = useQuery({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () =>
      serviceGet('metaFisica', {
        include: ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'],
        planoAplicacaoId,
        eixoFinanciadoId,
        natureza,
      }),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });
  useEffect(() => {
    const meta = metasFisicas?.results.filter((item) => item.id === metaFisicaRetirada)[0];
    const value = meta?.metasInstituicoes.filter((item) => item.id === instituicaoRetirada)[0];
    setValorDisponivel(value?.saldoEstimativo);
  }, [instituicaoRetirada, metaFisicaRetirada, metasFisicas?.results, setValue]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={6}>
        <CustomSelect
          label="Exercício Orçamentário"
          name="planoAplicacaoId"
          options={
            planoAplicacao?.results?.map((item: any, indexExercicio: number) => ({
              id: item.id,
              nome: item.exercicioOrcamentario,
              disabled: item.status === 'Pendente',
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <CustomSelect
          label="Meta Física - Retirada"
          name="metaFisicaRetiradaId"
          options={
            metasFisicas?.results
              ?.filter((item) => item.id !== undefined)
              .map((item) => ({
                id: item.id as string,
                nome: item.nome,
              })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRetiradaId" label="Instituição - Retirada">
          {metasFisicas?.results
            .filter((item) => item.id === metaFisicaRetirada)
            .map((item) =>
              item.metasInstituicoes.map((int) => (
                <MenuItem key={int.id} value={int.id}>
                  {int.sigla}
                </MenuItem>
              ))
            )}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <CustomSelect
          label="Meta Física - Recebida"
          name="metaFisicaRecebidaId"
          options={
            metasFisicas?.results?.map((item: any, index: number) => ({
              id: item.id,
              nome: item.nome,
              disabled: item.id === metaFisicaRetirada,
            })) ?? []
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRecebidaId" label="Instituição - Recebida">
          {metasFisicas?.results
            .filter((item) => item.id === metaFisicaRecebida)
            .map((item) =>
              item.metasInstituicoes.map((int) => (
                <MenuItem key={int.id} value={int.id}>
                  {int.sigla}
                </MenuItem>
              ))
            )}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldMoney
          label="Valor Disponível"
          disabled
          name="valorDisponivel"
          value={convertToCentavosCentavos(valorDisponivel)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldMoney label="Valor Remanejado" name="valorRemanejado" />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRGMaks
          mask="____.______._____/____-__"
          label="Processo - SEI"
          name="processoSei"
          maskType="number"
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
