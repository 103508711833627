import { Grid, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { serviceGet, servicePost } from 'src/services';
import CustomModal from 'src/components/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErroResponse } from 'src/pages/types';
import { ToastFun } from 'src/config/functions';
import AprovisionamentoForms from '../Formulario';
import { IAprovisionamentoData, schemaAprovisionamento } from '../Formulario/type';

export default function CadastroAprovisionamento({
  open,
  handleClose,
  RefetchMetas,
  id,
}: {
  open: boolean;
  handleClose: any;
  RefetchMetas: (arg: boolean) => void;
  id?: string;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaAprovisionamento),
  });

  const {
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = methods;
  const { state } = useContext(AuthContext);
  const include = ['metaFisicaCompleto', 'metasInstituicoes'];
  const params = {
    include,
  };
  const { data: rowsMeta } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => serviceGet('metaFisicalId', { ...params }, id),
    enabled: !!id,
  });

  useEffect(() => {
    if (rowsMeta) {
      methods.reset({
        planoAplicacaoId: rowsMeta.planoAplicacao?.id,
        eixoFinanciadoId: rowsMeta.eixosFinanciados?.id,
        metaFisicaId: rowsMeta.id,
        natureza: rowsMeta.natureza,
      });
    }
  }, [methods, rowsMeta, id]);
  const { mutate, isPending } = useMutation({
    mutationFn: (data: IAprovisionamentoData) => servicePost('aprovisionamento', { ...data }),
    onSuccess: () => {
      ToastFun('Aprovisionamento cadastrado com sucesso', 'success');
      handleClose();
      RefetchMetas(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: IAprovisionamentoData) => {
    if (state.usuario?.id) {
      data.usuarioCreatedId = state.usuario.id;
    }
    mutate(data);
  };
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Aprovisionamento - Cadastrar"
      icon={<EditNoteIcon color="success" sx={{ fontSize: 30 }} />}
    >
      <Grid container>
        <Grid md={12} xs={12} pb={3}>
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <AprovisionamentoForms unregister={unregister} setValue={setValue} watch={watch} />
          </FormsProvider>
        </Grid>
      </Grid>
    </CustomModal>
  );
}
