import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import EntregaVisualizar from './visualizar';

export default function Entregas() {
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const include = ['eixoFinanciadoCount', 'user'];
  const params = { filters: { search }, include };

  const handleButtonClick = (idEntrega: string) => {
    setId(idEntrega);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };
  const columns: GridColDef[] = [
    {
      field: 'responsavelId',
      headerName: 'RESPONSÁVEL PELA ENTREGA',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => cellParams.row.usuarioResponsavel.nome,
    },
    {
      field: 'despesa',
      headerName: 'EMPENHO DA DESPESA',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellParams) => cellParams.row.despesa.numeroEmpenho,
    },
    {
      field: 'descricao',
      headerName: 'DESCRIÇÃO DA DESPESA',
      flex: 4,
      minWidth: 250,

      renderCell: (cellParams) => cellParams.row.despesa.descricao,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,

      renderCell: (cellParams) => (
        <Chip
          label={cellParams.row.status}
          sx={{ color: 'white', fontWeight: '600' }}
          color={cellParams.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (cellParams) => (
        <Box>
          <IconButton color="primary" onClick={() => handleButtonClick(cellParams.row.id)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <Stack spacing={1}>
        <CustomBreadcrumbs
          heading="Entregas"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Entregas', href: '#' },
          ]}
        />
        <Grid container>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }}>
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <TableContainer
              endpoint="entregasFundoNacional"
              columns={columns}
              params={params}
              setRefetch={tableRefetch}
              setTableRefetch={setTableRefetch}
            />
          </Grid>
        </Grid>
        {id && isModalOpen && (
          <EntregaVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
        )}
      </Stack>
    </Box>
  );
}
