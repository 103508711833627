import { ReactNode } from 'react';
import { Box, Divider, Grid, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CustomModalProps {
  open: boolean;
  title?: string;
  icon?: ReactNode;
  onClose: () => void;
  children: ReactNode;
  size?: 'xl' | 'md' | 'sm';
  [key: string]: any;
}

export default function CustomModal({
  open,
  onClose,
  children,
  title,
  icon,
  size,
  ...rest
}: CustomModalProps) {
  return (
    <Modal open={open} onClose={onClose} {...rest}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          width: (() => {
            if (size === 'sm') return { xl: '45%', md: '35%', sm: '70%' };
            if (size === 'md') return '60%';
            if (size === 'xl') return '80%';
            return { xl: '65%', md: '85%', sm: '80%' };
          })(),

          minHeight: '150px',
          maxHeight: '650px',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          my: 5,
          position: 'relative',
        }}
      >
        <Grid container>
          <Grid item md={10} xs={10} sm={10}>
            <Box display="flex" alignItems="center" sx={{ px: 2, pt: 2 }}>
              <Typography variant="h5" sx={{ color: '#6D6C6C', mt: 1 }}>
                {icon}
              </Typography>
              <Typography variant="h5" sx={{ color: '#6D6C6C', ml: 1 }}>
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2} xs={2} sm={2} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider sx={{ m: 1 }} />

        <Box
          sx={{
            px: 2,
            maxHeight: '100%',
            overflowY: 'auto',
            paddingBottom: 2,
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
}
