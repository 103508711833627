import { useState } from 'react';
import { Box, Grid, Button, IconButton, TextField, Chip, Stack, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { convertToCentavosCentavos } from 'src/config/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { serviceDelete, serviceGet } from 'src/services';
import CustomTableContainer from 'src/components/Table';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import ModalExcluir from 'src/components/Modal/Excluir';
import CadastrarDestinacaoRendimento from './Cadastro';

export default function RendimentosDestinacao() {
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [instituicaoId, setInstituicaoId] = useState<string>('');
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const apagarCampos = () => {
    setEixoFinanciadoId('');
    setPlanoAplicacaoId('');
    setInstituicaoId('');
    setSearch('');
  };
  const include = ['eixoNome', 'exercicioOrcamentario', 'usuarioCreated', 'conta'];
  const dataParams = {
    filters: { search },
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    instituicaoId,
  };
  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: () => serviceGet('planoAplicacao'),
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: () => serviceGet('eixosFinanciadosPlanoAplicacaoId', {}, planoAplicacaoId),
    enabled: !!planoAplicacaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: () => serviceGet('instituicao'),
  });
  const handleButtonClick = (idRendimento: string, type: string) => {
    setId(idRendimento);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: (idRendimento: string) => serviceDelete('rendimentoSolicitacao', {}, idRendimento),
    onSuccess: (data) => {
      setTableRefetch(true);
      ToastFun('Excluido com Sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'PLANO DE APLICAÇÃO',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 160,
    },

    {
      field: 'nomeMetaFisica',
      headerName: 'META FÍSICA',
      minWidth: 400,
      flex: 6,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      flex: 1,
    },

    {
      field: 'processoSei',
      headerName: 'PROCESSO SEI',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      flex: 2,
    },
    {
      field: 'nomeInstituicao',
      headerName: 'INSTITUIÇÃO',
      align: 'center',
      minWidth: 150,

      headerAlign: 'center',
    },

    {
      field: 'valor',
      headerName: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valor)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
            disabled={params.row.status === 'Autorizado'}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container>
        <Grid item xs={12} md={1.5} xl={1.5} sx={{ my: 2, pr: 1 }}>
          <TextField
            size="small"
            select
            name="exercicioOrcamentario"
            label="Ano"
            fullWidth
            value={planoAplicacaoId}
            onChange={(event) => setPlanoAplicacaoId(event.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              },
            }}
          >
            {rowPlanosAplicacao &&
              rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
                <MenuItem
                  key={indexExercicio}
                  value={item.id}
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {item.exercicioOrcamentario}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} xl={3} sx={{ my: 2, px: 1 }}>
          <TextField
            size="small"
            select
            name="eixoFinanciado"
            label="Eixo Financiado"
            fullWidth
            onChange={(event) => setEixoFinanciadoId(event.target.value)}
          >
            {eixosFinanciados?.results.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={11} md={2} xl={2} sx={{ my: 2, px: 1 }}>
          <TextField
            size="small"
            select
            name="instituicaoId"
            label="Instituição"
            fullWidth
            value={instituicaoId}
            onChange={(event) => setInstituicaoId(event.target.value)}
          >
            {rowInstituicoes?.results.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.sigla}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} sx={{ my: 2, pl: 1 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              value={search}
              size="small"
              fullWidth
              onChange={(event) => setSearch(event.target.value)}
            />
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
            <IconButton
              color="info"
              sx={{ background: '#D4EAF7   ', mx: 1 }}
              onClick={() => {
                apagarCampos();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Stack>
        </Grid>

        <Grid item xs={12} md={2.5} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick('', 'Cadastro')}
            startIcon={<AddIcon />}
          >
            Destinar Rendimento
          </Button>
        </Grid>
        <Grid xs={12}>
          <CustomTableContainer
            columns={columns}
            endpoint="rendimentoSolicitacao"
            setRefetch={tableRefetch}
            params={dataParams}
            setTableRefetch={setTableRefetch}
          />
        </Grid>
      </Grid>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastrarDestinacaoRendimento
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefetchRendimentos={setTableRefetch}
        />
      )}
      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Destinação de Rendimento"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
