import { useContext, useState } from 'react';
import { Box, Grid, Button, Chip } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { convertToCentavosCentavos } from 'src/config/utils';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import { servicePatch } from 'src/services';
import { AuthContext } from 'src/Context/ContextUser';
import { IRendimentoSolicitacaoData } from '../../PlanoAplicacao/Rendimentos/Formulario/type';

export default function RendimentosDestinacao() {
  const [id, setId] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('Pendente');
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);
  const { state } = useContext(AuthContext);
  const include = ['eixoNome', 'exercicioOrcamentario', 'usuarioCreated', 'conta'];

  const { mutate } = useMutation({
    mutationFn: (data: IRendimentoSolicitacaoData) =>
      servicePatch('autorizarAlocacaoRendimentos', { ...data }, id),
    onSuccess: (data) => {
      ToastFun('Alocação autorizada com sucesso', 'success');
      setTableRefetch(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleButtonClick = (idRendimento: string) => {
    setId(idRendimento);
    setIsModalOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'PLANO DE APLICAÇÃO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'eixoNome',
      headerName: 'EIXO',

      minWidth: 150,
      flex: 2,
    },

    {
      field: 'nomeMetaFisica',
      headerName: 'META FÍSICA',
      minWidth: 150,
      flex: 3,
    },
    {
      field: 'nomeInstituicao',
      headerAlign: 'center',
      headerName: 'INSTITUIÇÃO',
      align: 'center',
      flex: 1,
    },
    {
      field: 'natureza',
      headerAlign: 'center',
      headerName: 'NATUREZA',
      align: 'center',
      flex: 1,
    },

    {
      field: 'valor',
      headerName: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valor)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },

    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            fullWidth
            variant="soft"
            color="success"
            startIcon={<TaskAltIcon />}
            sx={{ fontSize: '12px' }}
            onClick={() => handleButtonClick(params.row.id)}
          >
            Autorizar
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto', mt: 1 }}>
      <TableContainer
        columns={columns}
        endpoint="rendimentoSolicitacao"
        setRefetch={tableRefetch}
        params={{ filters: { search }, include }}
        setTableRefetch={setTableRefetch}
      />

      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Alocação"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
