import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Box, Button, Chip, Grid, IconButton, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { tratandoData, convertToCentavosCentavos } from 'src/config/utils';
import ModalExcluir from 'src/components/Modal/Excluir';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import TableContainer from 'src/components/Table';
import { serviceDelete, serviceGet } from 'src/services';
import CadastrarRendimento from '../../Rendimentos/Cadastro/Rendimento';

export default function PlanoAplicacaoRendimentoVisualizar({ id }: { id: string }) {
  const include: string[] = ['conta', 'usuarioCreated'];

  const [conta, setConta] = useState<string>('');
  const [idRendimento, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [tableRefetch, setTableRefetch] = useState<boolean>(false);

  const { mutate } = useMutation({
    mutationFn: (idrendimento: string) => serviceDelete('rendimentoConta', {}, idrendimento),
    onSuccess: (response) => {
      ToastFun('Excluido com Sucesso', 'success');
      setTableRefetch(true);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const { data: getRendimentosPlanoID, isPending } = useQuery({
    queryKey: ['getRendimentosPlano', id],
    queryFn: () => serviceGet('rendimentoConta', {}, id),
  });

  const { data: plano } = useQuery({
    queryKey: ['getPlanoAplicacao', id],
    queryFn: () => serviceGet('planoAplicacaoId', {}, id),
  });

  const dataParams = {
    include,
    conta,
    planoAplicacaoId: id,
  };
  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'conta',
      headerName: 'CONTA',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'valor',
      headerName: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>R$ {convertToCentavosCentavos(params.row.valor)}</>,
    },
    {
      field: 'dataReferencia',
      headerName: 'DATA DE REFERÊNCIA',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <> {tratandoData(params.row.dataReferencia)}</>,
    },
    {
      field: 'observacao',
      headerName: 'OBSERVAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 2,
      renderCell: (params) => (
        <> {params.row.observacao ? params.row.observacao : 'NÃO SE APLICA'}</>
      ),
    },
    {
      field: 'criadoEm',
      headerName: 'CRIADO EM ',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <> {tratandoData(params.row.criadoEm)}</>,
    },
    {
      field: 'criadoPor',
      headerName: 'CRIADO POR ',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 2,
      renderCell: (params) => <> {params.row.usuarioCreated.nome}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,

      renderCell: (params) => (
        <Box>
          <IconButton
            color="error"
            aria-label="delete"
            disabled={params.row.status === 'Autorizado'}
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <>
      {getRendimentosPlanoID && plano && (
        <Grid container>
          <Grid item md={3} xs={12}>
            <Box sx={{ my: 2 }}>
              <TextField
                fullWidth
                select
                label="Conta"
                name="conta"
                size="small"
                defaultValue="todos"
                onChange={(e) =>
                  e.target.value !== 'todos' ? setConta(e.target.value) : setConta('')
                }
              >
                {getRendimentosPlanoID?.results.map((item: any) => (
                  <MenuItem key={item.id} value={item.conta}>
                    {item.conta}
                  </MenuItem>
                ))}
                <MenuItem value="todos" selected>
                  Todas as contas
                </MenuItem>
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={plano?.status === 'Pendente'}
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Rendimento
            </Button>
          </Grid>
          <Grid md={12}>
            <TableContainer
              endpoint="rendimentoConta"
              setRefetch={tableRefetch}
              params={dataParams}
              columns={columns}
              setTableRefetch={setTableRefetch}
            />
          </Grid>
        </Grid>
      )}

      {isModalOpen && modalType === 'Cadastro' && (
        <CadastrarRendimento
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefetchRendimentos={setTableRefetch}
          planoAplicacao={id}
        />
      )}
      {idRendimento && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Rendimento"
          id={idRendimento}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
}
